/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./user-info/user-info.component.ngfactory";
import * as i3 from "./user-info/user-info.component";
import * as i4 from "./user-actions/user-actions.component.ngfactory";
import * as i5 from "./user-actions/user-actions.component";
import * as i6 from "../../../../../shared/services/action.service";
import * as i7 from "../../shared/admin-navigation.service";
import * as i8 from "@angular/common";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./user-detail.component";
import * as i11 from "../../../../../shared/services/user.service";
var styles_UserDetailComponent = [i0.styles];
var RenderType_UserDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserDetailComponent, data: {} });
export { RenderType_UserDetailComponent as RenderType_UserDetailComponent };
function View_UserDetailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-user-info", [["class", "padding-none-xs"]], null, null, null, i2.View_UserInfoComponent_0, i2.RenderType_UserInfoComponent)), i1.ɵdid(1, 49152, null, 0, i3.UserInfoComponent, [], { user: [0, "user"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; _ck(_v, 1, 0, currVal_0); }, null); }
function View_UserDetailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-user-actions", [["class", "padding-none-xs"]], null, null, null, i4.View_UserActionsComponent_0, i4.RenderType_UserActionsComponent)), i1.ɵdid(1, 114688, null, 0, i5.UserActionsComponent, [i6.ActionService, i7.AdminNavigationService], { user: [0, "user"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UserDetailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "col-xs-12 padding-none-xs margin-top-md-xs margin-bottom-md-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "span", [["class", "col-xs-12 padding-none-xs background-white-xs border-xs-grey6-xs min-height-50-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "a", [["class", "col-xs-6 padding-none-xs min-height-50-xs line-height-50 border-right-xs-grey3-xs text-black-xs text-center text-18-xs text-bold cursor-hand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchTab(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i8.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "tab-selected": 0 }), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 3, "a", [["class", "col-xs-6 padding-none-xs min-height-50-xs text-black-xs line-height-50 text-18-xs text-center text-bold cursor-hand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchTab(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 278528, null, 0, i8.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "tab-selected": 0 }), (_l()(), i1.ɵted(-1, null, [" Actions "])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "col-xs-12 background-white-xs border-xs-grey6-xs margin-top-md-xs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserDetailComponent_1)), i1.ɵdid(13, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserDetailComponent_2)), i1.ɵdid(15, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "col-xs-6 padding-none-xs min-height-50-xs line-height-50 border-right-xs-grey3-xs text-black-xs text-center text-18-xs text-bold cursor-hand"; var currVal_1 = _ck(_v, 4, 0, (_co.selectedTab === 1)); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = "col-xs-6 padding-none-xs min-height-50-xs text-black-xs line-height-50 text-18-xs text-center text-bold cursor-hand"; var currVal_4 = _ck(_v, 9, 0, (_co.selectedTab === 2)); _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_5 = (_co.selectedTab === 1); _ck(_v, 13, 0, currVal_5); var currVal_6 = (_co.selectedTab === 2); _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("admin.users.detail.title")); _ck(_v, 5, 0, currVal_2); }); }
export function View_UserDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-user-detail", [], null, null, null, View_UserDetailComponent_0, RenderType_UserDetailComponent)), i1.ɵdid(1, 49152, null, 0, i10.UserDetailComponent, [i7.AdminNavigationService, i11.UserService], null, null)], null, null); }
var UserDetailComponentNgFactory = i1.ɵccf("n9-user-detail", i10.UserDetailComponent, View_UserDetailComponent_Host_0, {}, {}, []);
export { UserDetailComponentNgFactory as UserDetailComponentNgFactory };
