import { Injectable } from '@angular/core';

export class Logger {
  constructor(private name: string, private logService: LogService) {}

  debug(...logs: any[]): void {
    this.logService.debug(this.name, ...logs);
  }

  log(...logs: any[]): void {
    this.logService.log(this.name, ...logs);
  }

  info(...logs: any[]): void {
    this.logService.info(this.name, ...logs);
  }

  warn(...logs: any[]): void {
    this.logService.warn(this.name, ...logs);
  }

  error(...logs: any[]): void {
    this.logService.error(this.name, ...logs);
  }
}

export enum LogLevel {
  DEBUG,
  LOG,
  INFO,
  WARN,
  ERROR
}

export interface LogSink {
  log(level: LogLevel, name: string, ...logs: any[]): void;
}

class ConsoleLogSink implements LogSink {
  log(level: LogLevel, name: string, ...logs: any[]): void {
    if (console) {
      /* tslint:disable:no-console */
      switch (level) {
        case LogLevel.DEBUG:
          console.debug(name, ...logs);
          break;
        case LogLevel.LOG:
          console.log(name, ...logs);
          break;
        case LogLevel.INFO:
          console.info(name, ...logs);
          break;
        case LogLevel.WARN:
          console.warn(name, ...logs);
          break;
        case LogLevel.ERROR:
          console.error(name, ...logs);
          break;
      }
      /* tslint:enable:no-console */
    }
  }
}

export class NullSink implements LogSink {
  log(level: LogLevel, name: string, ...logs: any[]): void {}
}

/*
 * todo list
 * disable all logs (done)
 * disable specific log level (todo)
 * sub loggers (todo)
 * attach custom loggers (todo) (optional)
 * get custom logger instance (todo) (optional)
 * */
@Injectable()
export class LogService {
  protected sinks: LogSink[] = [];
  protected enabled: boolean = true;

  constructor() {
    this.addSink(new ConsoleLogSink());
  }

  isEnabled(): boolean {
    return this.enabled;
  }

  enable(): void {
    this.enabled = true;
  }

  disable(): void {
    this.enabled = false;
  }

  addSink(sink: LogSink): void {
    this.sinks.push(sink);
  }

  clearSinks(): void {
    this.sinks = [];
  }

  create(key: string): Logger {
    return new Logger(key, this);
  }

  debug(name: string, ...logs: any[]): void {
    this.doLog(LogLevel.DEBUG, name, ...logs);
  }

  log(name: string, ...logs: any[]): void {
    this.doLog(LogLevel.LOG, name, ...logs);
  }

  info(name: string, ...logs: any[]): void {
    this.doLog(LogLevel.INFO, name, ...logs);
  }

  warn(name: string, ...logs: any[]): void {
    this.doLog(LogLevel.WARN, name, ...logs);
  }

  error(name: string, ...logs: any[]): void {
    this.doLog(LogLevel.ERROR, name, ...logs);
  }

  doLog(level: LogLevel, name: string, ...logs: any[]): void {
    if (!this.enabled) return;
    this.sinks.forEach((sink) => sink.log(level, name, ...logs));
  }
}
