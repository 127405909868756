import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataStatus } from '@core/models/data-status';
import { Contract, User } from '@core/models';
import { RequestService } from '@shared/services/request.service';
import { SessionService } from '@core/session/session.service';
import { HomeNavigationService } from '@core/services/navigation.service';
import { USER_REQUEST_TYPE } from '@core/models/request.model';

@Component({
  selector: 'n9-contribution-form',
  templateUrl: 'contribution-form.component.html'
})
export class ContributionFormComponent implements OnInit {
  public user: User;
  public contract: Contract;

  public contributionRateForm: FormGroup;
  public dataSent: DataStatus;
  public ds: typeof DataStatus = DataStatus;

  constructor(
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private navigation: HomeNavigationService,
    private requestService: RequestService
  ) {
    this.contributionRateForm = this.formBuilder.group({
      contributionRate: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.user = this.sessionService.getUser();
    const route: string[] = this.navigation.getRoute().split('/');
    const contractNumber: number = parseInt(route[route.length - 3], 10);
    this.contract = this.user.contracts.find((contract) => contract.number === contractNumber);

    this.contributionRateForm.setValue({
      contributionRate: this.contract.contributionRate || '8 %'
    });
    this.dataSent = DataStatus.WAITING;
  }

  public onSubmit(event: any): void {
    if (this.contributionRateForm.valid) {
      this.dataSent = DataStatus.PENDING;

      this.requestService
        .contractContributionRateRequest(
          this.contract.number,
          this.contributionRateForm.controls.contributionRate.value
        )
        .take(1)
        .subscribe(
          () => {
            this.dataSent = DataStatus.SUCCESS;
            this.navigation.navigateToRoute(
              `/home/contracts/${this.contract.number}/signature/${USER_REQUEST_TYPE.CONTRACT_CONTRIBUTION_RATE}`,
              null
            );
          },
          (err) => {
            if (err.status === 409) {
              this.dataSent = DataStatus.FORBIDDEN;
            } else {
              this.dataSent = DataStatus.ERROR;
            }
          }
        );
    }
  }
}
