import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[n9AfterNow][ngModel],[n9AfterNow][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateAfterNowValidatorDirective),
      multi: true
    }
  ]
})
export class DateAfterNowValidatorDirective implements Validator {
  static validDate(control: FormControl): any {
    const minDate: Date = new Date(Date.now());

    if (!control.value || control.value === null) return null;
    else {
      const date: Date = new Date(control.value);
      return minDate <= date ? null : { n9AfterNow: { valid: false } };
    }
  }

  validate(c: FormControl): { [p: string]: any } {
    return DateAfterNowValidatorDirective.validDate(c);
  }
}
