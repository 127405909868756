<div style="flex: 1">
  <!-- Title -->
  <p
    class="col-xs-12 padding-left-xs-xs padding-left-md-sm margin-top-md-xs margin-bottom-md-xs dosis-bold text-left text-bold text-18-xs text-24-sm text-blue3-xs"
  >
    Fil de discussion
  </p>

  <!-- Messaging system -->
  <ng-container *ngIf="to">
    <n9-threads [from]="from" [to]="to" [threadId]="threadId"></n9-threads>
  </ng-container>
</div>
