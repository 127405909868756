/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-sidebar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sidebar-element/sidebar-element.component.ngfactory";
import * as i3 from "./sidebar-element/sidebar-element.component";
import * as i4 from "@angular/common";
import * as i5 from "./admin-sidebar.component";
import * as i6 from "@angular/router";
var styles_AdminSidebarComponent = [i0.styles];
var RenderType_AdminSidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminSidebarComponent, data: {} });
export { RenderType_AdminSidebarComponent as RenderType_AdminSidebarComponent };
function View_AdminSidebarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-sidebar-element", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.onSelection(_co.features, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SidebarElementComponent_0, i2.RenderType_SidebarElementComponent)), i1.ɵdid(1, 114688, null, 0, i3.SidebarElementComponent, [], { element: [0, "element"] }, { selected: "selected" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AdminSidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminSidebarComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.condition !== false) ? "" : "hide"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.$implicit.condition !== false); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AdminSidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container-fluid padding-none-xs dosis-semibold text-16-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "col-xs-12 padding-none-xs margin-none-xs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminSidebarComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.features; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AdminSidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-sidebar", [], null, null, null, View_AdminSidebarComponent_0, RenderType_AdminSidebarComponent)), i1.ɵdid(1, 114688, null, 0, i5.AdminSidebarComponent, [i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminSidebarComponentNgFactory = i1.ɵccf("n9-sidebar", i5.AdminSidebarComponent, View_AdminSidebarComponent_Host_0, { features: "features" }, {}, []);
export { AdminSidebarComponentNgFactory as AdminSidebarComponentNgFactory };
