export enum MembershipStatus {
  ALL = 'ALL',
  CREATED = 'CREATED',
  // SUBSCRIBER_FILLED_IN = "SUBSCRIBER_FILLED_IN",
  // MANDATE_FILLED_IN = "MANDATE_FILLED_IN",
  SIGNATURE_READY = 'SIGNATURE_READY',
  SIGNATURE_COMPLETE = 'SIGNATURE_COMPLETE',
  SIGNATURE_REFUSED = 'SIGNATURE_REFUSED',
  SIGNATURE_EXPIRED = 'SIGNATURE_EXPIRED',
  MEMBERSHIP_COMPLETE = 'MEMBERSHIP_COMPLETE',
  MEMBERSHIP_TREATED = 'MEMBERSHIP_TREATED'
}
