<form
  [formGroup]="userEditForm"
  (ngSubmit)="onSubmit($event)"
  class="form form-horizontal col-xs-12 padding-none-xs margin-none-xs"
>
  <div class="col-xs-12 padding-none-xs margin-top-sm-xs">
    <label
      class="col-xs-6 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-7 text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs text-bold"
    >
      {{ 'home.personalData.display.electedNumber' | translate }}
    </label>
    <div class="col-xs-6 padding-left-sm-xs padding-left-sm-xs">
      <p class="form-control-static text-left text-14-xs text-12-sm">
        {{ user.eluNumber }}
      </p>
    </div>
  </div>

  <div class="col-xs-12 padding-none-xs margin-top-sm-xs">
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs openSans-bold"
    >
      {{ 'home.personalData.display.title' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs background-white-xs border-radius-sm-xs">
      <span
        class="col-xs-6 button-content padding-sm-xs border-xs-blue3-xs text-center openSans-bold left-only-border-radius"
        [ngClass]="{ 'tab-selected': user.title === 'Monsieur' }"
      >
        Monsieur
      </span>
      <span
        class="col-xs-6 button-content padding-sm-xs border-xs-blue3-xs text-center openSans-bold right-only-border-radius"
        [ngClass]="{ 'tab-selected': user.title === 'Madame' }"
      >
        Madame
      </span>
    </div>
  </div>

  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['lastname'].value && userControls['lastname'].errors,
      'has-success': userControls['lastname'].value && !userControls['lastname'].errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs openSans-bold"
      for="common-name"
    >
      {{ 'home.personalData.display.lastname' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <input class="form-control" type="text" id="common-name" formControlName="lastname" />
      <i
        *ngIf="!userControls['lastname'].errors"
        class="fa fa-check text-green2-xs form-control-feedback"
        aria-hidden="true"
      ></i>
      <i
        *ngIf="userControls['lastname'].errors"
        class="fa fa-times text-red1-xs form-control-feedback"
        aria-hidden="true"
      ></i>
    </div>
    <div *ngIf="userEditForm.controls.lastname.errors" class="col-xs-12 col-xs-offset-0 col-sm-4 col-sm-offset-5">
      <div
        [hidden]="!userEditForm.controls.lastname.errors.required"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.lastName.required' | translate }}
      </div>
      <div
        [hidden]="!userEditForm.controls.lastname.errors.n9Alphabetic"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.lastName.alphabetic' | translate }}
      </div>
    </div>
  </div>

  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['marriedName'].value && userControls['marriedName'].errors,
      'has-success': userControls['marriedName'].value && !userControls['marriedName'].errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs openSans-bold"
      for="marriage-name"
    >
      {{ 'home.personalData.display.marriedName' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <input class="form-control" type="text" id="marriage-name" formControlName="marriedName" />
      <i
        *ngIf="
          !userControls['marriedName'].errors && !(user.title === 'Monsieur' && !userControls['marriedName'].value)
        "
        class="fa fa-check text-green2-xs form-control-feedback"
        aria-hidden="true"
      ></i>
      <i
        *ngIf="userControls['marriedName'].errors"
        class="fa fa-times text-red1-xs form-control-feedback"
        aria-hidden="true"
      ></i>
    </div>

    <div *ngIf="userEditForm.controls.marriedName.errors" class="col-xs-12 col-xs-offset-0 col-sm-4 col-sm-offset-5">
      <div
        [hidden]="!userEditForm.controls.marriedName.errors.required"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.marriedName.required' | translate }}
      </div>
      <div
        [hidden]="!userEditForm.controls.marriedName.errors.n9Alphabetic"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.marriedName.alphabetic' | translate }}
      </div>
    </div>
  </div>

  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['firstname'].value && userControls['firstname'].errors,
      'has-success': userControls['firstname'].value && !userControls['firstname'].errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs openSans-bold"
      for="prenom"
    >
      {{ 'home.personalData.display.firstname' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <input class="form-control" type="text" id="prenom" formControlName="firstname" />
      <i
        *ngIf="!userControls['firstname'].errors"
        class="fa fa-check text-green2-xs form-control-feedback"
        aria-hidden="true"
      ></i>
      <i
        *ngIf="userControls['firstname'].errors"
        class="fa fa-times text-red1-xs form-control-feedback"
        aria-hidden="true"
      ></i>
    </div>

    <div *ngIf="userEditForm.controls.firstname.errors" class="col-xs-12 col-xs-offset-0 col-sm-4 col-sm-offset-5">
      <div
        [hidden]="!userEditForm.controls.firstname.errors.required"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.firstName.required' | translate }}
      </div>
      <div
        [hidden]="!userEditForm.controls.firstname.errors.n9Alphabetic"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.firstName.alphabetic' | translate }}
      </div>
    </div>
  </div>

  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['birthDate'].value && userControls['birthDate'].errors,
      'has-success': userControls['birthDate'].value && !userControls['birthDate'].errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs openSans-bold"
      for="birth-date"
    >
      {{ 'home.personalData.display.birthDate' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <input class="form-control" type="date" id="birth-date" formControlName="birthDate" />
      <i
        *ngIf="!userControls['birthDate'].errors"
        class="fa fa-check text-green2-xs form-control-feedback"
        aria-hidden="true"
      ></i>
      <i
        *ngIf="userControls['birthDate'].errors"
        class="fa fa-times text-red1-xs form-control-feedback"
        aria-hidden="true"
      ></i>
    </div>

    <div *ngIf="userEditForm.controls.birthDate.errors" class="col-xs-12 col-sm-4 col-sm-offset-5">
      <div
        [hidden]="!userEditForm.controls.birthDate.errors.required"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.birthDate.required' | translate }}
      </div>
      <div
        [hidden]="!userEditForm.controls.birthDate.errors.n9Birth"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.birthDate.minDate' | translate }}
      </div>
    </div>
  </div>

  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['mobilePhoneNumber'].value && userControls['mobilePhoneNumber'].get('number').errors,
      'has-success': userControls['mobilePhoneNumber'].value && !userControls['mobilePhoneNumber'].get('number').errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs openSans-bold"
      for="mobilephone-number"
    >
      {{ 'home.personalData.display.mobilePhoneNumber' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <n9-input-phone id="mobilephone-number" formGroupName="mobilePhoneNumber"></n9-input-phone>
      <i
        *ngIf="!userControls['mobilePhoneNumber'].get('number').errors"
        class="fa fa-check text-green2-xs form-control-feedback"
        aria-hidden="true"
      ></i>
      <i
        *ngIf="userControls['mobilePhoneNumber'].get('number').errors"
        class="fa fa-times text-red1-xs form-control-feedback"
        aria-hidden="true"
      ></i>
    </div>

    <div
      *ngIf="userEditForm.controls.mobilePhoneNumber['controls'].number.errors"
      class="col-xs-12 col-sm-4 col-sm-offset-5"
    >
      <div
        [hidden]="!userEditForm.controls.mobilePhoneNumber['controls'].number.errors.required"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-14-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.mobilePhoneNumber.required' | translate }}
      </div>
      <div
        [hidden]="!userEditForm.controls.mobilePhoneNumber['controls'].number.errors['n9MobilePhone']"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-14-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.mobilePhoneNumber.pattern' | translate }}
      </div>
    </div>
  </div>

  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['phoneNumber'].value && userControls['phoneNumber'].get('number').errors,
      'has-success': userControls['phoneNumber'].value && !userControls['phoneNumber'].get('number').errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs openSans-bold"
      for="phone-number"
    >
      {{ 'home.personalData.display.phoneNumber' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <n9-input-phone id="phone-number" formGroupName="phoneNumber"></n9-input-phone>
      <i
        *ngIf="!userControls['phoneNumber'].get('number').errors"
        class="fa fa-check text-green2-xs form-control-feedback"
        aria-hidden="true"
      ></i>
      <i
        *ngIf="userControls['phoneNumber'].get('number').errors"
        class="fa fa-times text-red1-xs form-control-feedback"
        aria-hidden="true"
      ></i>
    </div>

    <div *ngIf="userEditForm.controls.phoneNumber['controls'].number.errors" class="col-xs-12 col-sm-4 col-sm-offset-5">
      <div
        [hidden]="!userEditForm.controls.phoneNumber['controls'].number.errors['n9Phone']"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-14-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.phoneNumber.pattern' | translate }}
      </div>
    </div>
  </div>

  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['email'].value && userControls['email'].errors,
      'has-success': userControls['email'].value && !userControls['email'].errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-xs text-blue6-xs openSans-bold"
      for="word-email"
    >
      {{ 'home.personalData.display.personalEmail' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <input class="form-control" type="email" id="word-email" formControlName="email" />
      <i
        *ngIf="!userEditForm.controls.email.errors; else emailError"
        class="fa fa-check text-green2-xs form-control-feedback"
        aria-hidden="true"
      ></i>
      <ng-template #emailError>
        <i class="fa fa-times text-red1-xs form-control-feedback" aria-hidden="true"></i>
      </ng-template>
    </div>

    <div *ngIf="userEditForm.controls.email.errors" class="col-xs-12 col-xs-offset-0 col-sm-4 col-sm-offset-5">
      <div
        [hidden]="!userEditForm.controls.email.errors.n9Email"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.userEmail.email' | translate }}
      </div>
    </div>
  </div>

  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['address'].value && userControls['address'].errors,
      'has-success': userControls['address'].value && !userControls['address'].errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs openSans-bold"
      for="address"
    >
      {{ 'home.personalData.display.address' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <input class="form-control" type="text" id="address" formControlName="address" />
      <i
        *ngIf="!userControls['address'].errors"
        class="fa fa-check text-green2-xs form-control-feedback"
        aria-hidden="true"
      ></i>
      <i
        *ngIf="userControls['address'].errors"
        class="fa fa-times text-red1-xs form-control-feedback"
        aria-hidden="true"
      ></i>
    </div>

    <div *ngIf="userEditForm.controls.address.errors" class="col-xs-12 col-xs-offset-0 col-sm-4 col-sm-offset-5">
      <div
        [hidden]="!userEditForm.controls.address.errors.required"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.address.required' | translate }}
      </div>
      <div
        [hidden]="!userEditForm.controls.address.errors.n9AlphaNum"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.address.alphaNum' | translate }}
      </div>
    </div>
  </div>

  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['complementaryAddress'].value && userControls['complementaryAddress'].errors,
      'has-success': userControls['complementaryAddress'].value && !userControls['complementaryAddress'].errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs openSans-bold"
      for="address-addition"
    >
      {{ 'home.personalData.display.complementaryAddress' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <input class="form-control" type="text" id="address-addition" formControlName="complementaryAddress" />
      <i
        *ngIf="userControls['complementaryAddress'].value && !userControls['complementaryAddress'].errors"
        class="fa fa-check text-green2-xs form-control-feedback"
        aria-hidden="true"
      ></i>
      <i
        *ngIf="userControls['complementaryAddress'].value && userControls['complementaryAddress'].errors"
        class="fa fa-times text-red1-xs form-control-feedback"
        aria-hidden="true"
      ></i>
    </div>

    <div
      *ngIf="userEditForm.controls.complementaryAddress.errors"
      class="col-xs-12 col-xs-offset-0 col-sm-4 col-sm-offset-5"
    >
      <div
        [hidden]="!userEditForm.controls.complementaryAddress.errors.n9AlphaNum"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.addressAddition.alphaNum' | translate }}
      </div>
    </div>
  </div>

  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['postalCode'].value && userControls['postalCode'].errors,
      'has-success': userControls['postalCode'].value && !userControls['postalCode'].errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs openSans-bold"
      for="zip-code"
    >
      {{ 'home.personalData.display.postalCode' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <input class="form-control" type="text" id="zip-code" formControlName="postalCode" />
      <i
        *ngIf="!userControls['postalCode'].errors"
        class="fa fa-check text-green2-xs form-control-feedback"
        aria-hidden="true"
      ></i>
      <i
        *ngIf="userControls['postalCode'].errors"
        class="fa fa-times text-red1-xs form-control-feedback"
        aria-hidden="true"
      ></i>
    </div>

    <div *ngIf="userEditForm.controls.postalCode.errors" class="col-xs-12 col-xs-offset-0 col-sm-4 col-sm-offset-5">
      <div
        [hidden]="!userEditForm.controls.postalCode.errors.required"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.postalCode.required' | translate }}
      </div>
      <div
        [hidden]="!userEditForm.controls.postalCode.errors.n9AlphaNum"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.postalCode.alphaNum' | translate }}
      </div>
    </div>
  </div>

  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['city'].value && userControls['city'].errors,
      'has-success': userControls['city'].value && !userControls['city'].errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs openSans-bold"
      for="city"
    >
      {{ 'home.personalData.display.city' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <input class="form-control" type="text" id="city" formControlName="city" />
      <i
        *ngIf="!userControls['city'].errors"
        class="fa fa-check text-green2-xs form-control-feedback"
        aria-hidden="true"
      ></i>
      <i
        *ngIf="userControls['city'].errors"
        class="fa fa-times text-red1-xs form-control-feedback"
        aria-hidden="true"
      ></i>
    </div>

    <div *ngIf="userEditForm.controls.city.errors" class="col-xs-12 col-xs-offset-0 col-sm-4 col-sm-offset-5">
      <div
        [hidden]="!userEditForm.controls.city.errors.required"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.city.required' | translate }}
      </div>
      <div
        [hidden]="!userEditForm.controls.city.errors.n9Alphabetic"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.city.alphabetic' | translate }}
      </div>
    </div>
  </div>

  <div
    class="has-feedback col-xs-12 padding-none-xs margin-top-sm-xs"
    [ngClass]="{
      'has-error': userControls['country'].value && userControls['country'].errors,
      'has-success': userControls['country'].value && !userControls['country'].errors
    }"
  >
    <label
      class="col-xs-12 col-sm-5 padding-left-sm-xs padding-right-sm-xs margin-top-sm-xs text-left-xs text-right-sm text-14-xs text-12-sm text-blue6-xs openSans-bold"
      for="country"
    >
      {{ 'home.personalData.display.country' | translate }}
    </label>
    <div class="col-xs-12 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <input class="form-control" type="text" id="country" formControlName="country" />
      <i
        *ngIf="!userControls['country'].errors"
        class="fa fa-check text-green2-xs form-control-feedback"
        aria-hidden="true"
      ></i>
      <i
        *ngIf="userControls['country'].errors"
        class="fa fa-times text-red1-xs form-control-feedback"
        aria-hidden="true"
      ></i>
    </div>

    <div *ngIf="userEditForm.controls.country.errors" class="col-xs-12 col-xs-offset-0 col-sm-4 col-sm-offset-5">
      <div
        [hidden]="!userEditForm.controls.country.errors.required"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.country.required' | translate }}
      </div>
      <div
        [hidden]="!userEditForm.controls.country.errors.n9Alphabetic"
        class="col-xs-12 padding-none-xs margin-top-xs-xs text-left text-10-xs text-red1-xs"
      >
        {{ 'home.personalData.form.errors.country.alphabetic' | translate }}
      </div>
    </div>
  </div>

  <div class="col-xs-12 padding-none-xs margin-bottom-md-xs margin-top-sm-xs margin-top-md-xs">
    <div class="col-xs-12 col-xs-offset-0 col-sm-offset-5 col-sm-4 padding-left-sm-xs padding-right-sm-xs">
      <button
        type="submit"
        class="col-xs-12 btn btn-default dosis-bold padding-sm-xs text-uppercase text-white-xs background-red1-xs border-radius-none-xs"
        [disabled]="!userEditForm.valid || dataSent !== ds.WAITING || userEditForm.pristine"
      >
        {{ 'home.personalData.form.submit.button' | translate }}
      </button>
    </div>
  </div>
</form>
