/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../user-info/user-info.component.ngfactory";
import * as i2 from "../user-info/user-info.component";
import * as i3 from "../../../core/session/session.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./contact-form/contact-form.component.ngfactory";
import * as i6 from "./contact-form/contact-form.component";
import * as i7 from "@angular/forms";
import * as i8 from "../../../shared/services/thread.service";
import * as i9 from "@angular/router";
import * as i10 from "./contact.component";
var styles_ContactComponent = ["div[_ngcontent-%COMP%] {\n        display: flex;\n        flex-direction: column;\n      }"];
var RenderType_ContactComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ContactComponent, data: {} });
export { RenderType_ContactComponent as RenderType_ContactComponent };
export function View_ContactComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "col-xs-12 padding-left-md-xs padding-right-md-xs padding-left-lg-sm padding-right-lg-sm padding-top-sm-xs padding-bottom-md-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "n9-user-info", [["class", "col-xs-12 padding-none-xs hidden-sm hidden-md hidden-lg"]], null, null, null, i1.View_UserInfoComponent_0, i1.RenderType_UserInfoComponent)), i0.ɵdid(2, 114688, null, 0, i2.UserInfoComponent, [i3.SessionService], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "p", [["class", "col-xs-12 padding-none-xs margin-bottom-sm-xs margin-top-sm-xs dosis-bold text-14-xs text-18-sm text-left text-blue3-xs"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "])), i0.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, null, 1, "n9-contact-form", [["class", "col-xs-12 padding-none-xs"]], null, null, null, i5.View_ContactFormComponent_0, i5.RenderType_ContactFormComponent)), i0.ɵdid(7, 114688, null, 0, i6.ContactFormComponent, [i7.FormBuilder, i8.ThreadService, i9.Router, i3.SessionService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 7, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("home.contact.title")); _ck(_v, 4, 0, currVal_0); }); }
export function View_ContactComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "n9-contact", [], null, null, null, View_ContactComponent_0, RenderType_ContactComponent)), i0.ɵdid(1, 49152, null, 0, i10.ContactComponent, [], null, null)], null, null); }
var ContactComponentNgFactory = i0.ɵccf("n9-contact", i10.ContactComponent, View_ContactComponent_Host_0, {}, {}, []);
export { ContactComponentNgFactory as ContactComponentNgFactory };
