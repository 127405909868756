import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'n9-message-detail',
  templateUrl: 'message-detail.component.html'
})
export class MessageDetailComponent implements OnInit {
  from: string;
  to: string;
  threadId: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.from = 'CAREL';
    this.route.params.subscribe((data) => {
      this.threadId = this.to = data['id'];
    });
  }
}
