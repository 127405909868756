/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./confirmation.component";
import * as i4 from "../../../core/session/session.service";
import * as i5 from "../../../core/services/navigation.service";
import * as i6 from "../signature/signature.service";
import * as i7 from "../subscription.service";
var styles_ConfirmationComponent = [i0.styles];
var RenderType_ConfirmationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationComponent, data: {} });
export { RenderType_ConfirmationComponent as RenderType_ConfirmationComponent };
export function View_ConfirmationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "section", [["class", "col-xs-12 text-center margin-bottom-lg-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "p", [["class", "glyphicon glyphicon-ok-circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "p", [["class", "text-18-xs margin-top-md-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3 col-xs-12 margin-top-md-xs padding-top-md-xs padding-bottom-md-xs padding-left-md-xs padding-right-md-xs background-blue3-xs dosis-bold text-16-xs text-white-xs text-uppercase border-none-xs"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadDocument() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3 col-xs-12 margin-top-md-xs padding-top-md-xs padding-bottom-md-xs padding-left-md-xs padding-right-md-xs background-blue3-xs dosis-bold text-16-xs text-white-xs text-uppercase border-none-xs"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.subscriptionService.selectedStep = 0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("subscription.confirmation.title1")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("subscription.confirmation.title2")); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("subscription.confirmation.download")); _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("subscription.confirmation.return")); _ck(_v, 12, 0, currVal_3); }); }
export function View_ConfirmationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-confirmation", [], null, null, null, View_ConfirmationComponent_0, RenderType_ConfirmationComponent)), i1.ɵdid(1, 49152, null, 0, i3.ConfirmationComponent, [i4.SessionService, i5.HomeNavigationService, i6.SignatureService, i7.SubscriptionService], null, null)], null, null); }
var ConfirmationComponentNgFactory = i1.ɵccf("n9-confirmation", i3.ConfirmationComponent, View_ConfirmationComponent_Host_0, { isMembership: "isMembership" }, {}, []);
export { ConfirmationComponentNgFactory as ConfirmationComponentNgFactory };
