<div class="container-fluid padding-none-xs">
  <div class="col-xs-12 padding-left-md-sm padding-right-md-sm">
    <div id="messaging-container" class="col-xs-12 background-white-xs border-grey3-xs-xs padding-none-xs">
      <div #threadMessages id="thread-messages" class="border-bottom-grey3-xs-xs padding-bottom-md-xs">
        <div
          *ngFor="let message of messages$ | async as Message"
          class="col-xs-6 text-blue3-xs opensans-regular"
          [ngClass]="authorIsConnected(message) ? 'col-xs-offset-6' : 'margin-right-50'"
        >
          <div *ngIf="!message.sameAuthor || message.dateDiff; else concatenate" class="padding-top-md-xs">
            <p class="dosis-bold align-left">
              {{ message.isCarelSender ? 'Carel Mutuelle' : 'Elu N° ' + message.idSender }}
            </p>
            <p class="openSans-regular text-italic text-12-xs align-right">
              {{ message.createdDateTime | date: 'short' }}
            </p>
            <br />

            <p
              id="message-content"
              class="padding-md-xs margin-top-xs-xs margin-bottom-none-xs text-left"
              [ngClass]="authorIsConnected(message) ? 'background-blue2-xs' : 'background-grey1-xs'"
            >
              {{ message.content }}
            </p>
            <div class="col-xs-12" *ngIf="message.attachedFileNames && message.attachedFileNames.length > 0">
              <p
                *ngFor="let id of message.attachedFileIds; let i = index"
                class="cursor-hand col-xs-4"
                (click)="downloadFile(message.attachedFileIds[i])"
              >
                <span class="glyphicon glyphicon-paperclip"></span>
                {{ message.attachedFileNames[i] }}
              </p>
            </div>
          </div>

          <ng-template #concatenate>
            <div>
              <p
                id="message-content"
                class="padding-left-md-xs padding-right-md-xs padding-bottom-md-xs padding-top-none-xs margin-bottom-none-xs text-left"
                [ngClass]="authorIsConnected(message) ? 'background-blue2-xs' : 'background-grey1-xs'"
              >
                {{ message.content }}
              </p>
              <p
                *ngIf="message.attachedFileNames && message.attachedFileNames.length == 1"
                class="cursor-hand"
                (click)="downloadFile(message.attachedFileIds[0])"
              >
                <span class="glyphicon glyphicon-paperclip"></span>
                {{ message.attachedFileNames[0] }}
              </p>
              <p *ngIf="message.attachedFileIds && message.attachedFileIds.length > 1" class="cursor-hand">
                <span class="glyphicon glyphicon-paperclip"></span>
                {{ message.attachedFileIds.length }} fichiers joints
              </p>
            </div>
          </ng-template>
        </div>
      </div>
      <div *ngIf="!isThreadClosed; else threadClosed">
        <form
          id="thread-footer"
          class="col-xs-12 padding-sm-xs margin-none-xs"
          [formGroup]="messageForm"
          (ngSubmit)="postMessage()"
        >
          <textarea
            #threadTextarea
            formControlName="content"
            id="textarea-content"
            class="opensans-regular padding-top-sm-xs padding-left-sm-xs"
            rows="1"
            minlength="1"
            (keydown)="handleTextareaEvents($event)"
            placeholder="{{ 'home.messages.footer.placeholder' | translate }}"
          ></textarea>
          <label
            *ngIf="!this.uploadedFile; else uploaded"
            for="browse"
            class="background-grey3-xs border-none-xs text-14-xs opensans-regular text-center margin-none-xs"
          >
            <span class="glyphicon glyphicon-paperclip"></span>
            {{ 'home.messages.footer.buttons.attachments' | translate }}
          </label>
          <ng-template #uploaded>
            <label
              for="browse"
              class="background-green1-xs border-none-xs text-14-xs opensans-regular text-center margin-none-xs"
            >
              <span class="glyphicon glyphicon-paperclip"></span>
              {{ this.uploadedFile.name }}
            </label>
          </ng-template>
          <input
            #document
            id="browse"
            type="file"
            accept="image/png,image/jpeg,.pdf"
            class="hide"
            formControlName="attachments"
            (ngModelChange)="getFile(document.files)"
          />

          <input
            type="submit"
            [disabled]="!this.messageForm.valid"
            [ngClass]="!this.messageForm.valid ? 'disabled' : ''"
            class="background-red1-xs border-none-xs dosis-bold text-white-xs text-uppercase"
            value="{{ 'home.messages.footer.buttons.send' | translate }}"
          />
        </form>
      </div>
      <ng-template #threadClosed>
        <div class="col-xs-12 background-grey3-xs text-center padding-sm-xs margin-none-xs">
          <span class="text-italic text-18-xs openSans-regular">Ce fil de discussion est clos!</span>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="col-xs-2 padding-left-md-sm padding-right-md-sm padding-top-md-sm">
    <button
      class="btn btn-default dosis-bold padding-left-md-xs padding-right-md-xs padding-top-sm-xs padding-bottom-sm-xs background-red1-xs text-14-xs text-uppercase text-white-xs border-radius-none-xs"
      [routerLink]="['../']"
    >
      Retour
    </button>
  </div>
</div>
