import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[n9-email][ngModel],[n9-email][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailValidatorDirective),
      multi: true
    }
  ]
})
export class EmailValidatorDirective implements Validator {
  static validEmail(c: FormControl): object {
    const emailRegexp = /^[\w!#$%&‘*+\/=?^_.{|}~-]{1,64}@([\w!#$%&‘*+\/=?^_.{|}~-]{1,255}\.)+\w{2,10}$/;

    if (!c.value || c.value === '') return null;
    else return emailRegexp.test(c.value) ? null : { n9Email: { valid: false } };
  }

  constructor() {}

  validate(c: FormControl): object {
    return EmailValidatorDirective.validEmail(c);
  }
}
