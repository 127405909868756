/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./preamble.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./preamble.component";
import * as i4 from "../subscription.service";
var styles_PreambleComponent = [i0.styles];
var RenderType_PreambleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreambleComponent, data: {} });
export { RenderType_PreambleComponent as RenderType_PreambleComponent };
export function View_PreambleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "section", [["class", "col-xs-12 text-center padding-lg-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "preamble-block text-center padding-top-md-xs padding-bottom-md-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["class", "text-35-sm text-22-xs openSans-semibold margin-bottom-lg-xs"], ["style", "max-width: 700px; margin: auto"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [["class", "text-16-xs openSans-semibold margin-bottom-md-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [["class", "text-16-xs openSans-regular margin-bottom-md-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 2, "p", [["class", "text-16-xs openSans-regular margin-bottom-lg-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "btn btn-default dosis-bold text-16-xs margin-top-md-xs padding-left-lg-xs padding-right-lg-xs padding-top-sm-xs padding-bottom-sm-xs background-red1-xs text-uppercase text-white-xs border-radius-none-xs"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.start() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(16, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("subscription.common.title")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("subscription.common.subtitle")); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("subscription.common.firstParagraph")); _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("subscription.common.secondParagraph")); _ck(_v, 12, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("subscription.common.start")); _ck(_v, 16, 0, currVal_4); }); }
export function View_PreambleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-preamble", [], null, null, null, View_PreambleComponent_0, RenderType_PreambleComponent)), i1.ɵdid(1, 49152, null, 0, i3.PreambleComponent, [i4.SubscriptionService], null, null)], null, null); }
var PreambleComponentNgFactory = i1.ɵccf("n9-preamble", i3.PreambleComponent, View_PreambleComponent_Host_0, { isMembership: "isMembership" }, {}, []);
export { PreambleComponentNgFactory as PreambleComponentNgFactory };
