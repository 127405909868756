import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceGuard } from '@core/session/maintenance.guard';
import { SubscriptionComponent } from './subscription.component';
import { Routes, RouterModule } from '@angular/router';

const subscriptionRoutes: Routes = [
  {
    path: 'souscription',
    component: SubscriptionComponent
    // canActivate: [MaintenanceGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(subscriptionRoutes), CommonModule],
  exports: [RouterModule]
})
export class SubscriptionRoutingModule {}
