import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'n9-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() controlName: string;
  @Input() options: any[];

  constructor() {}

  public ngOnInit(): void {}

  public onSelect(event: any): void {
    const value: string = event.target.value;

    event.target.checked ? this.addToFormArray(value) : this.removeFromFormArray(value);
  }

  public getFormArray(): FormArray {
    return this.parentForm.get(this.controlName) as FormArray;
  }

  public addToFormArray(element: any): void {
    this.getFormArray().push(new FormControl(element));
  }

  public removeFromFormArray(element: any): void {
    const array: FormArray = this.getFormArray();

    array.removeAt(array.getRawValue().findIndex((elem) => elem === element));
  }
}
