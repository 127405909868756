import { isPlatformServer } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ConfigurationService } from '../services/conf/configuration.service';

@Injectable()
export class BaseHrefApiPreInterceptor implements HttpInterceptor {
  constructor(private conf: ConfigurationService, @Inject(PLATFORM_ID) private platformId: object) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isIgnoredRoute =
      this.conf.get('api.ignoredRoutes').filter((ignoreRoute) => {
        return new RegExp(ignoreRoute, 'g').test(req.url);
      }).length > 0;

    if (isIgnoredRoute) {
      return next.handle(req);
    } else {
      let baseHref = this.conf.getAsString('api.baseHref');
      if (isPlatformServer(this.platformId)) {
        baseHref = this.conf.getAsString('api.serverHost') + baseHref;
      }
      const duplicate = req.clone({
        url: baseHref + req.url
      });
      return next.handle(duplicate);
    }
  }
}
