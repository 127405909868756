<div *ngIf="user" class="col-xs-12">
  <div class="user-info col-xs-12 padding-left-xs-xs padding-left-sm-md">
    <div class="padding-none-xs padding-left-xs-xs col-xs-6 col-md-4 margin-top-md-xs">
      <span class="col-xs-12 padding-left-xs-xs padding-left-sm-md">
        <strong class="text-blue3-xs">
          {{ 'admin.users.detail.info.title' | translate }}
        </strong>
        : {{ user.title }}
      </span>
      <span class="col-xs-12 padding-left-xs-xs padding-left-sm-md">
        <strong class="text-blue3-xs">
          {{ 'admin.users.detail.info.lastname' | translate }}
        </strong>
        : {{ user.lastname }}
      </span>
      <span class="col-xs-12 padding-left-xs-xs padding-left-sm-md">
        <strong class="text-blue3-xs">
          {{ 'admin.users.detail.info.firstname' | translate }}
        </strong>
        : {{ user.firstname }}
      </span>
      <span class="col-xs-12 padding-left-xs-xs padding-left-sm-md">
        <strong class="text-blue3-xs">
          {{ 'admin.users.detail.info.email' | translate }}
        </strong>
        : {{ user.email }}
      </span>
      <span class="col-xs-12 padding-left-xs-xs padding-left-sm-md">
        <strong class="text-blue3-xs">
          {{ 'admin.users.detail.info.phoneNumber' | translate }}
        </strong>
        : {{ user.phoneNumber }}
      </span>
    </div>
    <div class="padding-none-xs padding-left-xs-xs col-xs-6 col-md-4 margin-top-md-xs">
      <span class="col-xs-12 padding-left-none-xs padding-left-sm-md padding-right-xs-xs padding-right-sm-md">
        <strong class="text-blue3-xs">
          {{ 'admin.users.detail.info.marriedName' | translate }}
        </strong>
        : {{ user.marriedName }}
      </span>
      <span class="col-xs-12 padding-left-none-xs padding-left-sm-md padding-right-none-xs padding-right-sm-md">
        <strong class="text-blue3-xs">
          {{ 'admin.users.detail.info.address' | translate }}
        </strong>
        : {{ user.address }}
      </span>
      <span class="col-xs-12 padding-left-none-xs padding-left-sm-md padding-right-none-xs padding-right-sm-md">
        <strong class="text-blue3-xs">
          {{ 'admin.users.detail.info.zipCode' | translate }}
        </strong>
        : {{ user.postalCode }}
      </span>
      <span class="col-xs-12 padding-left-none-xs padding-left-sm-md padding-right-none-xs padding-right-sm-md">
        <strong class="text-blue3-xs">
          {{ 'admin.users.detail.info.city' | translate }}
        </strong>
        : {{ user.city }}
      </span>
      <span class="col-xs-12 padding-left-none-xs padding-left-sm-md padding-right-none-xs padding-right-sm-md">
        <strong class="text-blue3-xs">
          {{ 'admin.users.detail.info.country' | translate }}
        </strong>
        : {{ user.country }}
      </span>
    </div>
  </div>

  <div class="user-contracts col-xs-12">
    <h4 class="col-xs-10 margin-top-md-xs text-bold text-blue3-xs" (click)="toggleContract()">
      <i *ngIf="hideContracts" class="fa fa-plus-circle margin-right-xs-xs cursor-hand" aria-hidden="true"></i>
      <i *ngIf="!hideContracts" class="fa fa-minus-circle margin-right-xs-xs cursor-hand" aria-hidden="true"></i>
      {{ 'admin.users.detail.contract.listTitle' | translate }}
    </h4>
    <div *ngIf="!hideContracts" class="col-xs-12 padding-none-xs">
      <div
        *ngFor="let contract of user.contracts"
        class="col-xs-12 col-md-6 col-lg-4 padding-none-xs padding-left-xs-xs margin-top-xs-xs min-height-300-xs"
      >
        <n9-contract-detail class="col-xs-12 padding-none-xs" [contract]="contract"></n9-contract-detail>
      </div>
    </div>

    <div *ngIf="!hasContracts()" class="col-xs-12 padding-none-xs padding-left-xs-xs">
      {{ 'admin.users.detail.contract.noContracts' | translate }}
    </div>
  </div>

  <div class="user-mandates col-xs-12 margin-bottom-sm-xs">
    <h4 class="col-xs-12 margin-top-md-xs text-bold text-blue3-xs" (click)="toggleMandates()">
      <i *ngIf="hideMandates" class="fa fa-plus-circle margin-right-xs-xs cursor-hand" aria-hidden="true"></i>
      <i *ngIf="!hideMandates" class="fa fa-minus-circle margin-right-xs-xs cursor-hand" aria-hidden="true"></i>
      {{ 'admin.users.detail.mandate.listTitle' | translate }}
    </h4>

    <div *ngIf="!hideMandates" class="col-xs-12 padding-none-xs">
      <div
        *ngFor="let mandate of user.mandates"
        class="col-xs-6 col-lg-4 padding-none-xs padding-left-xs-xs margin-top-xs-xs min-height-150-xs"
      >
        <div class="col-xs-12 padding-left-sm-xs border-xs-grey6-xs background-blue2-xs min-height-100p-xs">
          <h5 class="margin-top-sm-xs text-bold">
            <strong>{{ 'admin.users.detail.mandate.type' | translate }}</strong>
            : {{ mandate.type }}
          </h5>

          <div>
            <strong>{{ 'admin.users.detail.mandate.collectivity' | translate }}</strong>
            : {{ mandate.collectivityName }}
          </div>
          <div>
            <strong>{{ 'admin.users.detail.mandate.mandateStart' | translate }}</strong>
            : {{ mandate.electionDate | date: 'dd/MM/yyyy' }}
          </div>
          <div>
            <strong>{{ 'admin.users.detail.mandate.mandateEnd' | translate }}</strong>
            : {{ mandate.mandateEndDate | date: 'dd/MM/yyyy' }}
          </div>
          <div class="margin-bottom-sm-xs">
            <strong>{{ 'admin.users.detail.mandate.salary' | translate }}</strong>
            : {{ mandate.salary }} euros
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!hasMandates()">
      {{ 'admin.users.detail.mandate.noMandates' | translate }}
    </div>
  </div>
</div>
