<div class="col-xs-12 padding-left-md-xs padding-right-md-xs border-grey3-xs-xs background-white-xs">
  <h5
    class="col-xs-12 padding-none-xs openSans-bold text-bold text-16-xs text-center text-blue6-xs padding-top-xs-xs padding-bottom-xs-xs"
  >
    {{ contract.name || ('home.contracts.list.card.defaultName' | translate) }}
  </h5>

  <div
    class="col-xs-12 padding-top-xs-xs padding-bottom-xs-xs padding-right-md-xs padding-left-md-xs margin-bottom-sm-xs background-blue2-xs"
  >
    <div class="media">
      <div class="media-left padding-xs-xs">
        <img src="../../../../../assets/img/avatar.png" width="30px" />
      </div>
      <div class="media-body padding-left-md-xs padding-top-xs-xs">
        <span class="col-xs-12 padding-none-xs openSans-bold text-12-xs text-14-sm text-left text-blue3-xs">
          {{ 'home.contracts.list.card.contract' | translate }} N°{{ contract.number }}
          :
        </span>
        <span class="col-xs-12 padding-none-xs openSans-semibold text-12-xs text-14-sm text-left text-blue3-xs">
          {{ contract.mandate }}
        </span>
      </div>
    </div>
  </div>

  <div class="col-xs-12 padding-none-xs margin-bottom-xs-xs" *ngIf="!isXs || displayContent">
    <span
      class="col-xs-12 padding-none-xs margin-top-xs-xs openSans-semibold text-left text-12-xs text-14-sm text-blue6-xs"
    >
      <strong class="openSans-bold">{{ 'home.contracts.list.card.subscriptionDate' | translate }}</strong>
      {{ formatDate(contract.subscriptionDate) }}
    </span>
    <span
      *ngIf="contract.terminationDate && contract.status === 'TERMINATED'"
      class="col-xs-12 padding-none-xs margin-top-xs-xs openSans-semibold text-left text-12-xs text-14-sm text-blue6-xs"
    >
      <strong class="openSans-bold">{{ 'home.contracts.list.card.terminationDate' | translate }}</strong>
      {{ formatDate(contract.terminationDate) }}
    </span>
    <span
      *ngIf="contract.terminationDate && contract.status === 'LIQUIDATED'"
      class="col-xs-12 padding-none-xs margin-top-xs-xs openSans-semibold text-left text-12-xs text-14-sm text-blue6-xs"
    >
      <strong class="openSans-bold">{{ 'home.contracts.list.card.liquidationDate' | translate }}</strong>
      {{ formatDate(contract.terminationDate) }}
    </span>
    <span
      *ngIf="contract.retroactivityDate"
      class="col-xs-12 padding-none-xs margin-top-xs-xs openSans-semibold text-left text-12-xs text-14-sm text-blue6-xs"
    >
      <strong class="openSans-bold">{{ 'home.contracts.list.card.retroactivityDate' | translate }}</strong>
      {{ formatDate(contract.retroactivityDate) }}
    </span>

    <ul class="padding-none-xs col-xs-12 list-style-none margin-top-sm-xs margin-bottom-sm-xs">
      <li
        class="col-xs-12 padding-none-xs openSans-semibold text-14-xs text-left-xs text-blue3-xs cursor-hand"
        *ngFor="let action of actions"
        (click)="doAction(action)"
      >
        <span
          *ngIf="action.isDownload; else link"
          class="glyphicon glyphicon-download-alt padding-xs-xs text-blue3-xs"
        ></span>
        <ng-template #link>></ng-template> {{ action.actionName }}
      </li>
    </ul>
  </div>

  <div class="col-xs-12 padding-none-xs margin-top-sm-xs margin-bottom-sm-xs" *ngIf="isXs">
    <a class="col-xs-12 text-red2-xs text-10-xs" *ngIf="!displayContent" (click)="toggleContent()">
      <i class="fa fa-chevron-down margin-right-xs-xs" aria-hidden="true"></i>
      {{ 'home.contracts.list.card.toggleOn' | translate }}
      <i class="fa fa-chevron-down margin-left-xs-xs" aria-hidden="true"></i>
    </a>
    <a class="col-xs-12 text-red2-xs text-10-xs" *ngIf="displayContent" (click)="toggleContent()">
      <i class="fa fa-chevron-up margin-right-xs-xs" aria-hidden="true"></i>
      {{ 'home.contracts.list.card.toggleOff' | translate }}
      <i class="fa fa-chevron-up margin-left-xs-xs" aria-hidden="true"></i>
    </a>
  </div>
</div>
