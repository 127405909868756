<div class="col-xs-12 padding-left-md-sm padding-right-md-sm margin-top-none-xs" xmlns="http://www.w3.org/1999/html">
  <div class="row">
    <p class="col-xs-9 dosis-bold text-18-sm text-16-xs text-uppercase text-blue3-xs margin-top-50-xs">
      {{ 'subscription.mandates.title' | translate }}
    </p>

    <p class="col-xs-3 openSans-semibold text-14-sm text-11-xs text-right">
      <span class="text-red3-xs">*</span>
      {{ 'subscription.common.mandatory' | translate }}
    </p>
  </div>

  <form
    novalidate
    [formGroup]="mandateForm"
    #formDirective="ngForm"
    class="form form-horizontal col-xs-12 padding-bottom-lg-xs margin-top-md-xs"
  >
    <div class="form-group">
      <div *ngIf="this.mandates$ | async" class="col-xs-12 margin-none-xs">
        <n9-mandate-infos
          [mandates$]="this.mandates$"
          isAlterable="true"
          (alter)="this.onAlterEvent($event)"
        ></n9-mandate-infos>
      </div>

      <p
        *ngIf="this.mandates$.value.length > 0"
        class="dosis-bold text-18-sm text-16-xs text-uppercase text-blue3-xs margin-left-sm-xs"
      >
        {{ 'subscription.mandates.form.newMandate.label' | translate }}
      </p>
      <n9-new-mandate
        [parentForm]="mandateForm"
        [validatorsType]="validatorsType"
        [showErrors]="showErrors"
        [eventNewBeneficiary]="this.eventNewBeneficiary.asObservable()"
        [beneficiariesOnStart]="beneficiariesOnStart"
        [priorityOnStart]="priorityOnStart"
      ></n9-new-mandate>

      <div class="row margin-top-none-xs">
        <div class="col-md-6 col-xs-12">
          <label
            for="addmandate"
            class="button-mandate text-uppercase text-center openSans-semibold cursor-hand"
            style="width: 300px"
            [ngClass]="{ disabled: !isMandateFormValid() }"
          >
            <span
              class="glyphicon glyphicon-plus borders-20-percent background-white-xs text-blue3-xs padding-xs-xs margin-right-xs-xs"
            ></span>
            {{
              'subscription.mandates.form.' +
                (this.alteredMandate['index'] === undefined ? 'addMandate' : 'alterMandate') | translate
            }}
            <input
              type="button"
              id="addmandate"
              class="hidden-xs hidden-sm hidden-md hidden-lg"
              style="width: 100%"
              (click)="this.mandateForm.valid ? this.addMandate(this.mandateForm.getRawValue(), formDirective) : null"
            />
          </label>
        </div>
        <div class="col-md-6 col-xs-12 text-right">
          <label
            for="erasemandate"
            class="button-mandate button-erase text-uppercase text-center openSans-semibold cursor-hand"
            style="width: 300px"
            [ngClass]="{ disabled: !isMandateFormDirty() }"
          >
            <span
              class="glyphicon glyphicon-erase borders-20-percent background-white-xs text-red3-xs padding-xs-xs margin-right-xs-xs"
            ></span>
            Réinitialiser la saisie
            <input
              type="button"
              id="erasemandate"
              class="hidden-xs hidden-sm hidden-md hidden-lg"
              style="width: 100%"
              (click)="resetNewMandateForm(formDirective)"
            />
          </label>
        </div>
      </div>

      <form novalidate [formGroup]="undersignedForm" (ngSubmit)="onSubmit()" class="margin-none-xs">
        <div class="row margin-top-xs-xs">
          <div class="col-xs-12">
            <label class="text-left openSans-bold text-bold text-black-xs">
              {{ 'subscription.mandates.form.undersigned.label' | translate }}
              <span class="text-red3-xs">*</span> :
            </label>
          </div>

          <div
            [ngClass]="{
              'text-red3-xs openSans-bold': undersignedForm.controls.optinHonorCertification.errors && showErrors
            }"
          >
            <div class="col-xs-12 checkbox margin-none-xs">
              <label class="padding-none-xs">
                <input
                  type="checkbox"
                  class="col-xs-12 checkbox-input"
                  formControlName="optinHonorCertification"
                  required
                />
                <span class="padding-left-md-xs">{{
                  'subscription.mandates.form.undersigned.checks.0' | translate
                }}</span>
              </label>
            </div>
          </div>
          <div
            [ngClass]="{
              'text-red3-xs openSans-bold': undersignedForm.controls.optinGeneralConditions.errors && showErrors
            }"
          >
            <div class="col-xs-12 checkbox margin-none-xs">
              <label class="padding-none-xs">
                <input
                  type="checkbox"
                  class="col-xs-12 checkbox-input"
                  formControlName="optinGeneralConditions"
                  required
                />
                <span class="padding-left-md-xs">
                  {{ 'subscription.mandates.form.undersigned.checks.1' | translate }}
                  <a href='../../../../assets/files/CAREL_REGLEMENT MUTUALISTE_2024.pdf' target="_blank">
                    {{ 'subscription.mandates.form.undersigned.checks.2' | translate }}
                  </a>
                  {{ 'subscription.mandates.form.undersigned.checks.3' | translate }}
                </span>
              </label>
            </div>
          </div>
          <div
            [ngClass]="{
              'text-red3-xs openSans-bold': undersignedForm.controls.optinCNIL.errors && showErrors
            }"
          >
            <div class="col-xs-12 checkbox margin-none-xs">
              <label class="padding-none-xs">
                <input type="checkbox" class="col-xs-12 checkbox-input" formControlName="optinCNIL" required />
                <span class="padding-left-md-xs">
                  {{ 'subscription.mandates.form.undersigned.checks.7' | translate }}
                </span>
              </label>
            </div>
          </div>
          <p
            *ngIf="
              (undersignedForm.controls.optinHonorCertification.errors ||
                undersignedForm.controls.optinGeneralConditions.errors ||
                undersignedForm.controls.optinCNIL.errors) &&
              showErrors
            "
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs col-xs-12"
          >
            {{ 'subscription.mandates.form.undersigned.errors.checked' | translate }}
          </p>

          <div class="col-xs-12 margin-top-md-xs">
            {{ 'subscription.mandates.form.undersigned.checks.8' | translate }}
            <span
              class="glyphicon glyphicon-info-sign text-blue3-xs"
              [tooltip]="tooltipFormats"
              placement="right"
            ></span>
            <br /><span>(la taille des fichiers ne doit pas excéder 25Mo)</span>
            <ng-template #tooltipFormats>
              <div class="text-left openSans-regular text-10-xs margin-top-sm-xs margin-bottom-sm-xs">
                <p class="openSans-bold margin-none-xs">Formats acceptés :</p>
                <p>JPG, PNG ou PDF</p>
              </div>
            </ng-template>
          </div>
          <div class="col-xs-12">
            <label
              for="idcard"
              class="uploader-label background-grey2-xs text-uppercase text-center openSans-semibold padding-top-sm-xs padding-bottom-sm-xs padding-left-md-xs padding-right-md-xs"
              [ngClass]="{
                'invalid-files': undersignedForm.controls.idcard.errors && showErrors
              }"
            >
              <span class="glyphicon glyphicon-camera color-black hidden-lg hidden-xl"></span>
              {{ 'subscription.common.form.browse' | translate }}
            </label>
            <input
              #documents
              id="idcard"
              type="file"
              class="hide"
              formControlName="idcard"
              accept="image/png,image/jpeg,.pdf"
              (change)="getFilesAsArray(documents.files)"
              multiple
            />
          </div>
          <p
            *ngIf="fileError && fileError.length > 0"
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs col-xs-12"
          >
            {{ fileError }}
          </p>
          <p
            *ngIf="
              undersignedForm.controls.idcard.errors &&
              !undersignedForm.controls.idcard.errors.fileUploadError &&
              showErrors
            "
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs col-xs-12"
          >
            {{ 'subscription.mandates.form.undersigned.errors.file' | translate }}
          </p>
          <p
            *ngIf="
              undersignedForm.controls.idcard.errors &&
              undersignedForm.controls.idcard.errors.fileUploadError &&
              showErrors
            "
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs col-xs-12"
          >
            {{ 'subscription.mandates.form.undersigned.errors.fileUploadError' | translate }}
          </p>
          <div class="col-xs-12">
            <div
              *ngFor="let file of this.uploadedFiles; let i = index"
              class="col-sm-6 col-xs-12 margin-top-sm-xs margin-bottom-sm-xs"
            >
              <div class="row">
                <button type="button" class="btn btn-danger col-sm-4 col-md-2 col-xs-6" (click)="deleteFile(i)">
                  <i class="fa fa-trash fa-2x" aria-hidden="true"></i>
                </button>
                <p class="col-sm-3 col-xs-6">{{ file.name }}</p>
              </div>
              <div class="row pol">
                <img
                  *ngIf="file.type.match('image/.+')"
                  src="{{ this.images[i] }}"
                  class="rounded"
                  alt="{{ file.name }}"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <p
            class="col-sm-4 col-xs-12 text-red1-xs dosis-semibold text-18-xs cursor-hand"
            (click)="this.subscriptionService.goBack()"
          >
            < {{ 'subscription.common.previous' | translate }}
          </p>
          <button
            type="submit"
            class="btn btn-default dosis-bold col-sm-3 col-sm-offset-5 col-xs-12 text-16-xs padding-left-md-xs padding-right-md-xs padding-top-sm-xs padding-bottom-sm-xs background-red1-xs text-uppercase text-white-xs border-radius-none-xs"
          >
            {{ 'subscription.subscriber.form.validate' | translate }}
          </button>
          <p
            *ngIf="
              ((!mandateForm.valid && this.mandates$.value.length < 1) || !this.undersignedForm.valid) && showErrors
            "
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs pull-right"
          >
            {{ 'subscription.subscriber.form.errors.global' | translate }}
          </p>
        </div>
      </form>
    </div>
  </form>
</div>
