import { Component, Input } from '@angular/core';
import { SubscriptionService } from '../subscription.service';

@Component({
  selector: 'n9-preamble',
  templateUrl: './preamble.component.html',
  styleUrls: ['./preamble.component.scss']
})
export class PreambleComponent {
  @Input() isMembership: boolean;
  stepNumber: number = 0;

  constructor(private subscriptionService: SubscriptionService) {}

  public start(): void {
    this.subscriptionService.goForward(this.stepNumber);
  }
}
