<div>
  <ng-container [formGroup]="control">
    <select
      #select
      class="form-control input"
      formControlName="prefix"
      (change)="select.blur()"
      (focus)="this.currentData = this.prefixData"
      (blur)="this.currentData = this.shortPrefixData"
    >
      <option *ngFor="let data of currentData" [value]="data.value">
        {{ data.label }}
      </option>
    </select>
    <input type="text" class="form-control input text-14-xs" formControlName="number" maxlength="10" />
  </ng-container>
</div>
