import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class MandatesService {
    constructor(http) {
        this.http = http;
    }
    getReferential(type) {
        this.http.get('/referentials/' + type + '?page=0&size=200').subscribe((response) => {
            this.referentials = response['content'];
            switch (type) {
                case 'departements':
                    this.referentials.sort(this.codeAscOrder);
                    const index = this.referentials.findIndex((d) => d.code === '2A');
                    for (let i = index; i <= index + 1; i++) {
                        this.referentials.splice(this.referentials.findIndex((d) => d.code === '21'), 0, this.referentials[i]);
                        this.referentials.splice(i + 1, 1);
                    }
                    break;
                case 'regions':
                    this.referentials.sort(this.alphabeticAscOrder);
                    break;
                case 'epci':
                    this.referentials.sort(this.codeAscOrder);
                    break;
            }
        }, (error) => Observable.throwError(error));
    }
    uploadImageFile(file) {
        return this.http
            .post(`/membership/document/create/as-byte-array/BASE?name=${file.name}&label=${file.name}&content-type=${file.type}`, file)
            .map((res) => res)
            .catch((err) => Observable.throwError(err));
    }
    downloadImageFile(id, type = 'BASE') {
        return this.http.get(`/membership/document/download/${id}/type/${type}`, {
            responseType: 'blob',
            observe: 'response'
        });
    }
    deleteImageFile(membershipId, documentId) {
        return this.http.delete(`/membership/${membershipId}/document/delete/${documentId}`);
    }
    codeAscOrder(a, b) {
        return a.code < b.code ? -1 : a.code > b.code ? 1 : 0;
    }
    alphabeticAscOrder(a, b) {
        return a.name.localeCompare(b.name);
    }
}
MandatesService.ngInjectableDef = i0.defineInjectable({ factory: function MandatesService_Factory() { return new MandatesService(i0.inject(i1.HttpClient)); }, token: MandatesService, providedIn: "root" });
