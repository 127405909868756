/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./users.component";
var styles_UsersComponent = [];
var RenderType_UsersComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UsersComponent, data: {} });
export { RenderType_UsersComponent as RenderType_UsersComponent };
export function View_UsersComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_UsersComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "n9-users", [], null, null, null, View_UsersComponent_0, RenderType_UsersComponent)), i0.ɵdid(1, 49152, null, 0, i2.UsersComponent, [], null, null)], null, null); }
var UsersComponentNgFactory = i0.ɵccf("n9-users", i2.UsersComponent, View_UsersComponent_Host_0, {}, {}, []);
export { UsersComponentNgFactory as UsersComponentNgFactory };
