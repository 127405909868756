<div *ngIf="user" class="col-xs-12">
  <span class="col-xs-12 margin-top-md-xs" *ngIf="connexionDuration">
    <span class="col-xs-6 padding-none-xs text-left text-blue3-xs">
      {{ 'admin.users.detail.action.connexionDate' | translate }}
      <strong>{{ connexionDate }}</strong>
    </span>
    <span class="col-xs-6 padding-none-xs text-left text-blue3-xs">
      {{ 'admin.users.detail.action.connexionDuration' | translate }}
      <strong *ngIf="connexionDuration.hours > 0">{{ connexionDuration.hours }} heure(s), </strong>
      <strong *ngIf="connexionDuration.minutes > 0">{{ connexionDuration.minutes }} minutes</strong>
    </span>
  </span>
  <h4 class="col-xs-12 text-bold text-blue3-xs text-left">
    {{ 'admin.users.detail.action.profileEdit' | translate }}
  </h4>
  <div *ngIf="userEdit" class="col-xs-12">
    <div class="col-xs-12 border-xs-grey6-xs background-blue2-xs">
      <span *ngFor="let change of userChanges" class="col-xs-12 padding-none-xs margin-top-sm-xs">
        <span class="col-xs-4 col-md-3 padding-none-xs text-bold text-right text-blue4-xs"> {{ change.label }} : </span>
        <span class="col-xs-6 col-md-4 col-lg-3 padding-left-xs-xs padding-right-xs-xs text-red2-xs text-left">
          {{ change.oldValue }}
        </span>
        <span
          class="col-xs-6 col-xs-offset-4 col-md-4 col-md-offset-0 col-lg-3 padding-left-xs-xs padding-right-xs-xs text-left"
        >
          devient
          <a class="text-green2-xs margin-left-xs-xs">
            {{ change.newValue }}
          </a>
        </span>
      </span>
      <span class="col-xs-12 text-blue4-xs" *ngIf="userChanges.length === 0">
        L'utilisateur a emis une demande sans modifier ses donnees
      </span>
      <div class="col-xs-12 margin-top-md-xs margin-bottom-sm-xs">
        <a
          class="col-xs-3 col-xs-offset-3 col-md-2 col-md-offset-4 btn btn-default background-blue3-xs text-white-xs"
          (click)="validateAction(userEdit.id)"
        >
          <i class="fa fa-check margin-right-sm-xs" aria-hidden="true"></i>
          Valider
        </a>
        <a
          class="col-xs-3 col-md-2 btn btn-default background-blue3-xs text-white-xs"
          (click)="refuseAction(userEdit.id)"
        >
          <i class="fa fa-times margin-right-sm-xs" aria-hidden="true"></i>
          Refuser
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="!userEdit" class="col-xs-12 text-left">
    {{ 'admin.users.detail.action.noProfileEdit' | translate }}
  </div>

  <h4 class="col-xs-12 margin-top-md-xs text-bold text-left text-blue3-xs">Actions sur les contrats</h4>
  <div *ngIf="contractChanges" class="col-xs-12 margin-top-sm-xs">
    <div
      *ngFor="let contractChange of contractChanges"
      class="col-xs-6 col-md-4 padding-left-xs-xs padding-right-xs-xs"
    >
      <div class="col-xs-12 background-blue2-xs border-xs-grey6-xs">
        <span class="col-xs-12 padding-none-xs margin-top-sm-xs text-left text-bold text-blue4-xs">
          {{ 'admin.users.detail.action.contracts.number' | translate }}
          {{ contractChange.contractNumber }}
        </span>
        <span class="col-xs-12 padding-none-xs margin-top-md-xs text-left text-blue4-xs">
          {{ 'admin.users.detail.action.contracts.oldContribution' | translate }}
          {{ contractChange.oldContributionRate * 100 }}%
        </span>
        <span class="col-xs-12 padding-none-xs margin-top-md-xs text-left text-blue4-xs">
          {{ 'admin.users.detail.action.contracts.newContribution' | translate }}
          {{ contractChange.newContributionRate * 100 }}%
        </span>
        <div class="col-xs-4 col-xs-offset-4 margin-top-md-xs padding-none-xs text-center">
          <a class="margin-right-xs-xs" (click)="validateAction(contractChange.id)">
            <i class="fa fa-check text-20-xs text-green2-xs" aria-hidden="true"></i>
          </a>
          <a (click)="refuseAction(contractChange.id)">
            <i class="fa fa-times text-20-xs text-red1-xs" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!contractChanges">Pas de modifications de contrats</div>

  <h4 class="col-xs-12 margin-top-md-xs text-bold text-left text-blue3-xs">
    {{ 'admin.users.detail.action.declaredMandates' | translate }}
  </h4>
  <div *ngIf="mandateDeclarations && mandateDeclarations.length" class="col-xs-12 margin-top-sm-xs margin-bottom-md-xs">
    <div
      *ngFor="let declaration of mandateDeclarations"
      class="col-xs-6 col-md-4 padding-left-xs-xs padding-right-xs-xs"
    >
      <div class="col-xs-12 background-blue2-xs border-xs-grey6-xs">
        <span class="col-xs-12 padding-none-xs margin-top-sm-xs">
          <strong class="text-blue4-xs">
            {{ 'admin.users.detail.action.mandates.type' | translate }}
          </strong>
          {{ declaration.mandateDeclaration.mandateType }}
        </span>
        <span class="col-xs-12 padding-none-xs margin-top-sm-xs">
          <strong class="text-blue4-xs">
            {{ 'admin.users.detail.action.mandates.electionDate' | translate }}
          </strong>
          {{ formatDate(declaration.mandateDeclaration.electionDate) }}
        </span>
        <span class="col-xs-12 padding-none-xs margin-top-sm-xs">
          <strong class="text-blue4-xs">
            {{ 'admin.users.detail.action.mandates.subscribeDate' | translate }}
          </strong>
          {{ formatDate(declaration.mandateDeclaration.subscribeDate) }}
        </span>
        <span class="col-xs-12 padding-none-xs margin-top-sm-xs">
          <strong class="text-blue4-xs">
            {{ 'admin.users.detail.action.mandates.salary' | translate }}
          </strong>
          {{ declaration.mandateDeclaration.salary }}
        </span>
        <div class="col-xs-4 col-xs-offset-4 margin-top-md-xs padding-none-xs text-center">
          <a class="margin-right-xs-xs" (click)="validateAction(declaration.id)">
            <i class="fa fa-check text-20-xs text-green2-xs" aria-hidden="true"></i>
          </a>
          <a (click)="refuseAction(declaration.id)">
            <i class="fa fa-times text-20-xs text-red1-xs" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!mandateDeclarations && !mandateDeclarations.length">
    {{ 'admin.users.detail.action.noMandatesDeclaration' | translate }}
  </div>
</div>
