/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sidebar/sidebar.component.ngfactory";
import * as i3 from "./sidebar/sidebar.component";
import * as i4 from "../../core/services/navigation.service";
import * as i5 from "../../core/session/session.service";
import * as i6 from "../../shared/components/login-notifications/login-notifications.component.ngfactory";
import * as i7 from "../../shared/components/login-notifications/login-notifications.component";
import * as i8 from "../../shared/services/notification.service";
import * as i9 from "@angular/router";
import * as i10 from "./home.component";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "background-grey1-xs"], ["id", "home"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "n9-home-sidebar", [["class", "hidden-xs"]], null, null, null, i2.View_SideBarComponent_0, i2.RenderType_SideBarComponent)), i1.ɵdid(2, 49152, null, 0, i3.SideBarComponent, [i4.HomeNavigationService, i5.SessionService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "n9-login-notifications", [], null, null, null, i6.View_LoginNotificationsComponent_0, i6.RenderType_LoginNotificationsComponent)), i1.ɵdid(4, 114688, null, 0, i7.LoginNotificationsComponent, [i8.NotificationService], null, null), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 4, 0); _ck(_v, 6, 0); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i10.HomeComponent, [i5.SessionService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("n9-home", i10.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
