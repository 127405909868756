import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Observable } from 'rxjs';
import { User } from '@core/models';
import { MembershipSubscriber } from '@core/models/membership-subscriber.model';
import { SessionService } from '@core/session/session.service';
import { EmailValidatorDirective } from '@core/validators/email-validator.directive';
import { FirstnamesValidatorDirective } from '@core/validators/firstnames-validator.directive';
import { LatinAlphabeticValidatorDirective } from '@core/validators/latin-alphabetic-validator.directive';
import { LatinAlphanumericValidatorDirective } from '@core/validators/latin-alphanumeric-validator.directive';
import { MobilePhoneValidatorDirective } from '@core/validators/mobile-phone-validator.directive';
import { PhoneValidatorDirective } from '@core/validators/phone-validator.directive';
import { ZipCodeValidatorDirective } from '@core/validators/zip-code-validator.directive';
import { SubscriptionService } from '../subscription.service';

@Component({
  selector: 'n9-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriberComponent implements OnInit {
  @Input() isMembership: boolean;

  public subscriberForm: FormGroup;
  public stepNumber: number = 1;
  public bsConfig: Partial<BsDatepickerConfig>;
  public minDate: Date = new Date(1900, 0, 1);
  public maxDate: Date = new Date();
  public showErrors: boolean = false;

  constructor(
    private subscriptionService: SubscriptionService,
    private fb: FormBuilder,
    private sessionService: SessionService
  ) {
    this.subscriberForm = this.fb.group({
      civility: ['', [Validators.required]],
      lastname: ['', [Validators.required, LatinAlphabeticValidatorDirective.validInput]],
      maidenname: ['', [Validators.required, LatinAlphabeticValidatorDirective.validInput]],
      firstnames: ['', [Validators.required, FirstnamesValidatorDirective.validInput]],
      birthDate: ['', [Validators.required]],
      cityOfBirth: ['', [Validators.required, LatinAlphabeticValidatorDirective.validInput]],
      address: this.fb.group({
        address: ['', [Validators.required, LatinAlphanumericValidatorDirective.validInput]],
        complement: ['', LatinAlphanumericValidatorDirective.validInput],
        postalCode: ['', [Validators.required, ZipCodeValidatorDirective.validInput]],
        city: ['', [Validators.required, LatinAlphanumericValidatorDirective.validInput]]
      }),
      email: ['', [Validators.required, EmailValidatorDirective.validEmail]],
      mobilePhone: this.fb.group({
        prefix: ['+33', [Validators.required]],
        number: ['', [Validators.required, MobilePhoneValidatorDirective.validInput]]
      }),
      phone: this.fb.group({
        prefix: ['+33', [Validators.required]],
        number: ['', [PhoneValidatorDirective.validInput]]
      }),
      job: ['', [LatinAlphabeticValidatorDirective.validInput]],
      maritalStatus: ['']
    });

    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
  }

  ngOnInit(): void {
    if (this.isMembership) {
      const user: User = this.sessionService.getUser();
      const patch = {
        civility: user.title === 'Monsieur' ? 'MR' : 'MRS',
        lastname: user.lastname,
        maidenname: user.marriedName,
        firstnames: user.firstname,
        birthDate: new Date(user.birthDate),
        cityOfBirth: user.city,
        address: {
          address: user.address,
          complement: user.complementaryAddress,
          postalCode: user.postalCode,
          city: user.city
        },
        email: user.email,
        phone: user.phoneNumber,
        mobilePhone: user.mobilePhoneNumber
      };

      this.subscriberForm.patchValue(patch);
    } else if (localStorage.getItem('membershipMarketingId')) {
      // Retrieves data from server
      this.subscriptionService.getSubscriberValues().subscribe(
        (values) => {
          const subscriber: MembershipSubscriber = values['subscriber'];

          if (subscriber) {
            subscriber.birthDate = new Date(subscriber['birthDate']);

            this.subscriberForm.patchValue(subscriber);
          }
        },
        (error) => Observable.throwError(error)
      );
    }
  }

  onSubmit(): void {
    if (!this.subscriberForm.valid) {
      this.showErrors = true;
      return;
    }

    const values = this.subscriberForm.getRawValue();

    if (typeof values['firstnames'] === 'string')
      values['firstnames'] = values['firstnames'].split(/, ?/).filter((v) => v !== '');

    if (!values.maritalStatus) delete values.maritalStatus;

    this.subscriptionService.getSubscriberValues().subscribe((response) => {
      this.subscriptionService.validateSubscriber(Object.assign(response, { subscriber: values })).subscribe(
        () => this.subscriptionService.goForward(this.stepNumber),
        (error) => Observable.throwError(error)
      );
    });
  }

  formatControlValue(str: string): void {
    const control: AbstractControl = this.subscriberForm.get(str);

    if (control instanceof FormControl) control.setValue(control.value.toString().toUpperCase());
  }
}
