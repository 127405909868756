<div class="col-xs-5 padding-sm-xs background-blue2-xs margin-bottom-md-xs">
  <div class="media">
    <div class="media-left padding-sm-xs">
      <img src="../../../../../../assets/img/avatar.png" width="40px" />
    </div>
    <div class="media-body padding-left-sm-xs">
      <p class="margin-bottom-xs-xs">
        <span class="openSans-bold text-blue4-xs">{{ 'home.contracts.contribution.mandate' | translate }} : </span>
        <span class="openSans-semibold text-blue4-xs">
          {{ contract?.mandate }}
        </span>
      </p>
      <p class="margin-bottom-xs-xs">
        <span class="openSans-bold text-blue4-xs"
          >{{ 'home.contracts.contribution.subscribeDate' | translate }}
          :
        </span>
        <span class="openSans-semibold text-blue4-xs">
          {{ contract?.subscriptionDate | date: 'dd/MM/yyyy' }}
        </span>
      </p>
      <p class="margin-none-xs">
        <span class="openSans-bold text-blue4-xs"
          >{{ 'home.contracts.contribution.contributionRate' | translate }}
          :
        </span>
        <span class="openSans-semibold text-blue4-xs">
          {{ contract?.contributionRate }}
        </span>
      </p>
    </div>
  </div>
</div>
<div class="col-xs-12 padding-none-xs">
  <span class="openSans-bold">{{ 'home.contracts.retroactivity.current' | translate }} : </span>
  <span class="openSans-regular" *ngIf="contract.retroactivityDate">{{
    contract.retroactivityDate | date: 'dd/MM/yyyy'
  }}</span>
  <span class="openSans-regular text-italic" *ngIf="!contract.retroactivityDate">{{
    'home.contracts.retroactivity.empty' | translate
  }}</span>
</div>

<div class="margin-lg-xs col-xs-12 text-center">
  <input
    type="submit"
    (click)="onSubmit()"
    class="background-red1-xs border-none-xs padding-sm-xs padding-left-md-xs padding-right-md-xs dosis-bold text-white-xs text-uppercase"
    value="{{ 'home.contracts.retroactivity.modify' | translate }}"
  />
</div>
