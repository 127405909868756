import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import "rxjs-compat/add/operator/map";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const MAX_STEP = 4;
export class SubscriptionService {
    constructor(http) {
        this.http = http;
        if (!localStorage.getItem('step')) {
            localStorage.setItem('step', '0');
        }
        this.selectedStep = parseInt(localStorage.getItem('step'), 10);
    }
    validateSubscriber(subscriberValues) {
        // UPDATE member by marketingID
        if (localStorage.getItem('membershipMarketingId')) {
            return this.updateSubscriber(subscriberValues);
        }
        // Create member otherwise
        return this.http
            .post('/membership', subscriberValues)
            .map((response) => localStorage.setItem('membershipMarketingId', response['marketingId']))
            .catch((error) => Observable.throwError(error));
    }
    validateMandates(mandatesValues) {
        if (localStorage.getItem('membershipMarketingId')) {
            return this.updateSubscriber(mandatesValues);
        }
    }
    sendMembershipSummary() {
        if (localStorage.getItem('membershipMarketingId')) {
            return this.http
                .get('/membership/summary/' + localStorage.getItem('membershipMarketingId'))
                .catch((error) => Observable.throwError(error));
        }
    }
    getSubscriberValues() {
        // Retrieve member data by marketingID
        if (localStorage.getItem('membershipMarketingId')) {
            return this.http
                .get('/membership/' + localStorage.getItem('membershipMarketingId'))
                .map((response) => response)
                .catch((error) => Observable.throwError(error));
        }
        return of({});
    }
    updateSubscriber(values) {
        if (localStorage.getItem('membershipMarketingId')) {
            return this.http
                .put('/membership/' + localStorage.getItem('membershipMarketingId'), values)
                .catch((error) => Observable.throwError(error));
        }
    }
    deleteMembership(membershipMarketingId) {
        this.http
            .delete(`/membership/${membershipMarketingId}`)
            .take(1)
            .subscribe(() => { }, (err) => Observable.throwError(err));
    }
    getSelectedStep() {
        return this.selectedStep;
    }
    goBack() {
        if (this.selectedStep > 0) {
            this.selectedStep--;
            localStorage.setItem('step', this.selectedStep.toString());
        }
        window.scroll(0, 0);
    }
    goForward(step) {
        if (this.selectedStep < MAX_STEP) {
            this.selectedStep = step + 1;
            if (this.selectedStep.toString() >= localStorage.getItem('step'))
                localStorage.setItem('step', this.selectedStep.toString());
        }
        else {
            this.goToStep(0);
        }
        window.scroll(0, 0);
    }
    goToStep(step) {
        if (step >= 0 && step <= MAX_STEP) {
            localStorage.setItem('step', step.toString());
            this.selectedStep = step;
        }
    }
}
SubscriptionService.ngInjectableDef = i0.defineInjectable({ factory: function SubscriptionService_Factory() { return new SubscriptionService(i0.inject(i1.HttpClient)); }, token: SubscriptionService, providedIn: "root" });
