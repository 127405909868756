import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionService } from '../subscription.service';
import { SignatureService } from './signature.service';

@Component({
  selector: 'n9-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit {
  @Input() isMembership: boolean;

  constructor(public signatureService: SignatureService, public subscriptionService: SubscriptionService) {}

  ngOnInit(): void {
    this.signatureService
      .startSignature()
      .take(1)
      .subscribe(
        () => this.signatureService.checkSignatureComplete(this.isMembership),
        (error) => Observable.throwError(error)
      );
  }
}
