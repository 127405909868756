/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signature.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/loader/loader.component.ngfactory";
import * as i3 from "../../../shared/components/loader/loader.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./signature.component";
import * as i7 from "./signature.service";
import * as i8 from "../subscription.service";
var styles_SignatureComponent = [i0.styles];
var RenderType_SignatureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignatureComponent, data: {} });
export { RenderType_SignatureComponent as RenderType_SignatureComponent };
function View_SignatureComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "n9-loader", [["class", "margin-top-md-xs margin-bottom-md-xs"], ["role", "status"]], null, null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoaderComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading..."]))], null, null); }
function View_SignatureComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["class", "border-none-xs"], ["height", "1315px"], ["name", "signature"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.signatureService.signUrl; _ck(_v, 0, 0, currVal_0); }); }
export function View_SignatureComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "row padding-left-lg-md padding-right-lg-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "dosis-bold text-uppercase text-blue3-xs text-18-xs padding-left-sm-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 5, "section", [["class", "col-xs-12 background-grey2-xs border-blue5-xs-xs margin-top-md-md margin-top-sm-xs margin-bottom-lg-md margin-bottom-md-xs padding-none-xs padding-sm-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["id", "yousign-box"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignatureComponent_1)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignatureComponent_2)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "p", [["class", "col-sm-2 col-xs-6 text-red1-xs dosis-semibold text-16-xs margin-bottom-md cursor-hand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.subscriptionService.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(11, null, [" < ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.signatureService.signUrl; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.signatureService.signUrl; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("subscription.signature.title")); _ck(_v, 2, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("subscription.common.previous")); _ck(_v, 11, 0, currVal_3); }); }
export function View_SignatureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-signature", [], null, null, null, View_SignatureComponent_0, RenderType_SignatureComponent)), i1.ɵdid(1, 114688, null, 0, i6.SignatureComponent, [i7.SignatureService, i8.SubscriptionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignatureComponentNgFactory = i1.ɵccf("n9-signature", i6.SignatureComponent, View_SignatureComponent_Host_0, { isMembership: "isMembership" }, {}, []);
export { SignatureComponentNgFactory as SignatureComponentNgFactory };
