import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { User } from '@core/models';
import { ROLE } from '@core/models/role.model';
import { AccountsService } from '@shared/services/accounts.service';
import { SessionService } from '@core/session/session.service';

@Component({
  selector: 'n9-accounts-list',
  templateUrl: './accounts-list.component.html',
  styleUrls: ['./accounts-list.component.scss']
})
export class AccountsListComponent implements OnInit {
  currentUser: User;
  users: User[];
  keys: any = Object.keys;
  availableRoles: object = {};
  modalRef: BsModalRef;
  page: number = 0;
  size: number = 10;
  nbElements: number;

  constructor(
    private route: ActivatedRoute,
    private accountsService: AccountsService,
    private readonly sessionService: SessionService,
    private modalService: BsModalService
  ) {
    this.currentUser = this.sessionService.getUser();

    // tslint:disable-next-line:forin
    for (const role in ROLE) this.availableRoles[role] = role.substr(5);
  }

  public ngOnInit(): void {
    this.getUsersBO();
  }

  public isUserAdmin(): boolean {
    return this.currentUser.roles.includes(ROLE.ROLE_ADMIN);
  }

  public isFormSubmitted(userCreated: boolean): void {
    if (userCreated !== null) this.getUsersBO();
  }

  public getUsersBO(event?: any): void {
    this.page = event ? event.page - 1 : 0;

    this.accountsService
      .getUsersBO(this.page, this.size)
      .take(1)
      .subscribe((users: object) => {
        this.nbElements = users['nbElements'];
        this.users = users['content'] as User[];
      });
  }

  public deleteUser(login: string): void {
    this.accountsService
      .deleteUserByLogin(login, true)
      .take(1)
      .subscribe(() => this.getUsersBO());
  }

  public openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }
  public isAdmin(user: User): boolean {
    return user.roles.includes(ROLE.ROLE_ADMIN);
  }
}
