import { NgModule } from '@angular/core';
import { NumericOnlyDirective } from './directives/numeric-only.directive';
import { ConfigurationModule } from './services/conf/configuration.module';
import { LogService } from './services/log/log.service';
import { LoggedInGuard } from './session/logged-in.guard';
import { MaintenanceGuard } from './session/maintenance.guard';
import { RoleGuard } from './session/role.guard';
import { SessionService } from './session/session.service';
import { StoreModule } from './store/store.module';
import { SwalService } from './utils/swal.service';

/**
 * This is the "core" module. It aggregates all custom made services.
 * This module should only be imported in the root module.
 */
@NgModule({
  imports: [StoreModule, ConfigurationModule.forRoot()],
  providers: [LogService, SessionService, SwalService, LoggedInGuard, MaintenanceGuard, RoleGuard],
  declarations: [NumericOnlyDirective],
  exports: [NumericOnlyDirective]
})
export class CoreModule {}
