import { Component, Input } from '@angular/core';
import { MembershipMandate } from '@core/models/membership-mandate.model';
import { Membership } from '@core/models/membership.model';
import { SessionService } from '@core/session/session.service';
import { HomeNavigationService } from '@core/services/navigation.service';
import { SignatureService } from '../signature/signature.service';
import { SubscriptionService } from '../subscription.service';

@Component({
  selector: 'n9-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {
  @Input() isMembership: boolean;

  constructor(
    private sessionService: SessionService,
    public homeNavigationService: HomeNavigationService,
    public signatureService: SignatureService,
    public subscriptionService: SubscriptionService
  ) {
    localStorage.removeItem('membershipMarketingId');
    localStorage.setItem('step', '0');
  }

  public downloadDocument(): void {
    window.open('/api/signature/documents/' + this.signatureService.damId + '/download', '_blank');
  }
}
