import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataStatus } from '@core/models/data-status';
import { Contract, User } from '@core/models';
import { RequestService } from '@shared/services/request.service';
import { SessionService } from '@core/session/session.service';
import { LatinAlphabeticValidatorDirective } from '@core/validators/latin-alphabetic-validator.directive';
import { LatinAlphanumericValidatorDirective } from '@core/validators/latin-alphanumeric-validator.directive';
import { PhoneValidatorDirective } from '@core/validators/phone-validator.directive';
import { ZipCodeValidatorDirective } from '@core/validators/zip-code-validator.directive';
import { NewBeneficiaryComponent } from '@modules/subscription/mandates/new-beneficiary/new-beneficiary.component';
import { BeneficiaryDivisionValidatorDirective } from '@core/validators/beneficiary-division-validator';
import { MembershipMandate } from '@core/models/membership-mandate.model';
import { USER_REQUEST_TYPE } from '@core/models/request.model';
import { HomeNavigationService } from '@core/services/navigation.service';

@Component({
  selector: 'n9-beneficiary-form',
  templateUrl: 'beneficiary-form.component.html',
  styleUrls: ['./beneficiary-form.component.scss']
})
export class BeneficiaryFormComponent implements OnInit {
  @ViewChild('beneficiaries', { read: ViewContainerRef })
  beneficiaryContainer: ViewContainerRef;

  public user: User;
  public contract: Contract;

  public beneficiaryClauseForm: FormGroup;
  public dataSent: DataStatus;
  public ds: typeof DataStatus = DataStatus;
  public tmpBeneficiaries: object[] = [];
  public beneficiariesMap: object = {};

  private validatorsType: object = {
    inCaseOfDeath: [Validators.required],
    divisionType: [Validators.required],
    notaryLastname: [Validators.required, LatinAlphabeticValidatorDirective.validInput],
    notaryFirstname: [Validators.required, LatinAlphabeticValidatorDirective.validInput],
    notaryPhone: [PhoneValidatorDirective.validInput],
    address: [Validators.required, LatinAlphanumericValidatorDirective.validInput],
    postalCode: [Validators.required, ZipCodeValidatorDirective.validInput],
    city: [Validators.required, LatinAlphanumericValidatorDirective.validInput]
  };

  constructor(
    private fb: FormBuilder,
    private sessionService: SessionService,
    private navigation: HomeNavigationService,
    private requestService: RequestService,
    private factoryResolver: ComponentFactoryResolver
  ) {
    this.beneficiaryClauseForm = this.fb.group({
      inCaseOfDeath: ['', [Validators.required]],
      beneficiaries: this.fb.array([]),
      beneficiariesDivision: this.fb.group({
        divisionType: [''],
        beneficiaryPriorityOrder: this.fb.array([]),
        notaryFirstname: [''],
        notaryLastname: [''],
        notaryPhone: [''],
        notaryAddress: this.fb.group({
          address: [''],
          complement: [''],
          postalCode: [''],
          city: ['']
        })
      })
    });
  }

  ngOnInit(): void {
    this.user = this.sessionService.getUser();
    const route: string[] = this.navigation.getRoute().split('/');
    const contractNumber: number = parseInt(route[route.length - 3], 10);
    this.contract = this.user.contracts.find((contract) => contract.number === contractNumber);

    this.dataSent = DataStatus.WAITING;

    this.beneficiaryClauseForm.get('inCaseOfDeath').valueChanges.subscribe((value: string) => {
      const order: FormArray = this.beneficiaryClauseForm.get(
        'beneficiariesDivision.beneficiaryPriorityOrder'
      ) as FormArray;

      if (value === 'Désignation expresse') {
        this.retrieveBeneficiariesData();

        if (this.beneficiaryContainer.length >= 2)
          order.setValidators([Validators.required, BeneficiaryDivisionValidatorDirective.validInput(value)]);
        else order.clearValidators();
      } else {
        this.storeBeneficiariesData();
        order.clearValidators();
      }

      if (value === 'Désignation notariale') this.addValidatorsNotary();
      else this.removeValidatorsNotary();
    });

    this.beneficiaryClauseForm.get('beneficiariesDivision.divisionType').valueChanges.subscribe((value: string) => {
      const order: FormArray = this.beneficiaryClauseForm.get(
        'beneficiariesDivision.beneficiaryPriorityOrder'
      ) as FormArray;

      if (value === 'equalShares') {
        this.removePriority();
        order.setValidators([Validators.required]);
      } else {
        order.setValidators([Validators.required, BeneficiaryDivisionValidatorDirective.validInput(value)]);

        for (let i = order.controls.length; i < this.beneficiaryContainer.length; i++)
          this.addPriority([Validators.required]);

        order.updateValueAndValidity();
      }
    });
  }

  public onSubmit(event: any): void {
    if (this.beneficiaryClauseForm.valid) {
      this.dataSent = DataStatus.PENDING;

      const beneficiaryClauseData = this.beneficiaryClauseForm.value;
      this.mapBeneficiaryClauseData(beneficiaryClauseData);

      this.requestService
        .contractBeneficiaryClauseRequest(this.contract.number, beneficiaryClauseData)
        .take(1)
        .subscribe(
          () => {
            this.navigation.navigateToRoute(
              `/home/contracts/${this.contract.number}/signature/${USER_REQUEST_TYPE.CONTRACT_BENEFICIARY_CLAUSE}`,
              null
            );
            this.dataSent = DataStatus.SUCCESS;
          },
          (err) => {
            if (err.status === 409) {
              this.dataSent = DataStatus.FORBIDDEN;
            } else {
              this.dataSent = DataStatus.ERROR;
            }
          }
        );
    }
  }

  public storeBeneficiariesData(): void {
    this.removeValidators('beneficiaries');
    this.removeValidators('beneficiariesDivision');
    this.tmpBeneficiaries = this.beneficiaryClauseForm.getRawValue()['beneficiaries'];

    if (this.beneficiaryContainer) {
      for (let i: number = this.beneficiaryContainer.length; i > 0; i--) {
        this.removeBeneficiary(i);
      }
    }

    this.removePriority();
  }

  public retrieveBeneficiariesData(): void {
    // tslint:disable-next-line:prefer-for-of
    for (let i: number = 0; i < this.tmpBeneficiaries.length; i++) this.createNewBeneficiary();

    if (this.beneficiaryContainer.length >= 2) {
      this.addValidators('beneficiariesDivision');
    }

    this.beneficiaryClauseForm.get('beneficiaries').patchValue(this.tmpBeneficiaries);
    this.tmpBeneficiaries = [];
  }

  public trackByFn(index: number, item: string): number {
    if (!item) return null;
    return index;
  }

  public formatControlValue(str: string): void {
    const control: AbstractControl = this.beneficiaryClauseForm.get(str);

    if (control instanceof FormControl) control.setValue(control.value.toString().toUpperCase());
  }

  public addValidators(fb: string): void {
    const c: AbstractControl = this.beneficiaryClauseForm.get(fb);

    if (c instanceof FormControl) {
      const t: string[] = fb.split('.');

      c.setValidators(this.validatorsType[t[t.length - 1]]);
      c.updateValueAndValidity();
      return;
    }
    // tslint:disable-next-line:forin
    for (const key in (this.beneficiaryClauseForm.get(fb) as FormGroup).controls) {
      const control = this.beneficiaryClauseForm.get(fb).get(key);

      control.setValidators(this.validatorsType[key]);
      control.updateValueAndValidity();
    }
  }

  public removeValidators(fb: string): void {
    const control = this.beneficiaryClauseForm.get(fb);

    if (control instanceof FormControl) {
      control.clearValidators();
      control.updateValueAndValidity();
    } else if (control instanceof FormGroup) this.removeFormGroupValidators(control as FormGroup);
    else if (this.beneficiaryClauseForm.get(fb) instanceof FormArray)
      this.removeFormArrayValidators(control as FormArray);
  }

  public createNewBeneficiary(): void {
    const factory = this.factoryResolver.resolveComponentFactory(NewBeneficiaryComponent);
    const component = factory.create(this.beneficiaryContainer.injector);

    const timestamps = new Date().getTime();

    this.beneficiariesMap[timestamps] = {
      id: timestamps,
      index: (this.beneficiaryClauseForm.get('beneficiaries') as FormArray).length + 1
    };

    component.instance.infos = this.beneficiariesMap[timestamps];
    component.instance.delete.subscribe(($event) => this.removeBeneficiary($event));

    this.beneficiaryContainer.insert(component.hostView);
    (this.beneficiaryClauseForm.get('beneficiaries') as FormArray).push(component.instance.newBeneficiaryForm);

    if (this.beneficiaryContainer.length >= 2) {
      this.addValidators('beneficiariesDivision');
    } else {
      this.removeValidators('beneficiariesDivision');
    }

    if (this.beneficiaryClauseForm.get('inCaseOfDeath').value !== 'Désignation notariale') {
      this.removeValidatorsNotary();
    }
  }

  public removeBeneficiaryFromId(id: number): void {
    if (!this.beneficiariesMap[id]) return;

    this.removeBeneficiary(this.beneficiariesMap[id].index);

    delete this.beneficiariesMap[id];

    this.updateBeneficiariesIndexes();
  }

  public updateBeneficiariesIndexes(): void {
    Object.keys(this.beneficiariesMap).forEach((k, i) => {
      this.beneficiariesMap[k].index = i + 1;
    });
  }

  public removeBeneficiary(index: number): void {
    if (this.beneficiaryContainer.length > 2) this.removePriority(index - 1);

    this.beneficiaryContainer.remove(index - 1);
    (this.beneficiaryClauseForm.get('beneficiaries') as FormArray).removeAt(index - 1);

    if (this.beneficiaryContainer.length < 2) {
      this.removeValidators('beneficiariesDivision');
    }
  }

  public addPriority(validators: any): void {
    const po: FormArray = this.beneficiaryClauseForm.get('beneficiariesDivision.beneficiaryPriorityOrder') as FormArray;

    po.push(
      this.fb.group({
        priority: ['', validators]
      })
    );
  }

  public removePriority(index: number = -1): void {
    const po: FormArray = this.beneficiaryClauseForm.get('beneficiariesDivision.beneficiaryPriorityOrder') as FormArray;

    if (index >= 0) po.removeAt(index);
    else {
      po.controls = [];
      po.setValidators([]);
      po.reset([], { emitEvent: false });
    }

    this.beneficiaryClauseForm.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });
  }

  public resetPriorityValues(): void {
    this.beneficiaryClauseForm.get('beneficiariesDivision.beneficiaryPriorityOrder').reset();
  }

  private removeFormArrayValidators(formArray: FormArray): void {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < formArray.controls.length; i++) {
      if (formArray.controls[i] instanceof FormControl) {
        formArray.controls[i].clearValidators();
        formArray.controls[i].updateValueAndValidity();
      } else if (formArray.controls[i] instanceof FormGroup) {
        this.removeFormGroupValidators(formArray.controls[i] as FormGroup);
      } else if (formArray.controls[i] instanceof FormArray) {
        this.removeFormArrayValidators(formArray.controls[i] as FormArray);
      }
    }
  }

  private removeFormGroupValidators(fg: FormGroup): void {
    // tslint:disable-next-line:forin
    for (const key in fg.controls) {
      const control = fg.get(key);

      if (control instanceof FormControl) {
        control.clearValidators();
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        this.removeFormGroupValidators(control as FormGroup);
      } else if (control instanceof FormArray) {
        this.removeFormArrayValidators(control as FormArray);
      }
    }
  }

  private addValidatorsNotary(): void {
    this.addValidators('beneficiariesDivision.notaryLastname');
    this.addValidators('beneficiariesDivision.notaryFirstname');
    this.addValidators('beneficiariesDivision.notaryAddress');
    this.addValidators('beneficiariesDivision.notaryPhone');
  }

  private removeValidatorsNotary(): void {
    this.removeValidators('beneficiariesDivision.notaryLastname');
    this.removeValidators('beneficiariesDivision.notaryFirstname');
    this.removeValidators('beneficiariesDivision.notaryAddress');
    this.removeValidators('beneficiariesDivision.notaryPhone');
  }

  private mapBeneficiaryClauseData(formValue: Partial<MembershipMandate>): void {
    // Add all three division types to response and set boolean according to form value
    for (const divisionType of ['equalShares', 'priorityOrder', 'perPercent']) {
      formValue.beneficiariesDivision[divisionType] = formValue.beneficiariesDivision['divisionType'] === divisionType;
    }

    if (formValue.beneficiaries) {
      if (formValue.beneficiariesDivision.equalShares) {
        delete formValue.beneficiariesDivision.beneficiaryPriorityOrder;
      }
      if (formValue.beneficiaries.length === 1) {
        delete formValue.beneficiariesDivision.divisionType;
        delete formValue.beneficiariesDivision.beneficiaryPriorityOrder;
      }
      if (formValue.beneficiaries.length < 1) {
        delete formValue.beneficiariesDivision.divisionType;
        delete formValue.beneficiaries;
      }
    }

    if (
      formValue.beneficiariesDivision.beneficiaryPriorityOrder &&
      formValue.beneficiariesDivision.beneficiaryPriorityOrder.length > 0
    ) {
      formValue.beneficiariesDivision.beneficiaryPriorityOrder =
        formValue.beneficiariesDivision.beneficiaryPriorityOrder.map((v) => v['priority'] || v);
    }

    if (formValue.beneficiariesDivision) {
      formValue.beneficiariesDivision.notary = formValue.inCaseOfDeath === 'Désignation notariale';
    }
  }
}
