/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./accounts.component";
import * as i3 from "../../../../core/session/session.service";
var styles_AccountsComponent = [];
var RenderType_AccountsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AccountsComponent, data: {} });
export { RenderType_AccountsComponent as RenderType_AccountsComponent };
export function View_AccountsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AccountsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "n9-accounts", [], null, null, null, View_AccountsComponent_0, RenderType_AccountsComponent)), i0.ɵdid(1, 114688, null, 0, i2.AccountsComponent, [i3.SessionService, i1.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountsComponentNgFactory = i0.ɵccf("n9-accounts", i2.AccountsComponent, View_AccountsComponent_Host_0, {}, {}, []);
export { AccountsComponentNgFactory as AccountsComponentNgFactory };
