/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-notifications.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "ngx-bootstrap/modal";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "@angular/common";
import * as i6 from "./login-notifications.component";
import * as i7 from "../../services/notification.service";
var styles_LoginNotificationsComponent = [i0.styles];
var RenderType_LoginNotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginNotificationsComponent, data: {} });
export { RenderType_LoginNotificationsComponent as RenderType_LoginNotificationsComponent };
function View_LoginNotificationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "cursor-hand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.page < (_co.nbPages - 1)) ? (_co.setPage((_co.page + 1)) || _co.getNotificationsByType("LOGIN", _co.page, 1)) : 0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("home.notifications.pagination.next")); _ck(_v, 1, 0, currVal_0); }); }
function View_LoginNotificationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "background-red1-xs padding-md-xs cursor-hand text-white-xs"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.removeUserFromAllNotifications();
        var pd_0 = (_co.toggleModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("home.notifications.confirm")); _ck(_v, 1, 0, currVal_0); }); }
function View_LoginNotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 28, "div", [["bsModal", ""], ["class", "modal fade"]], null, [[null, "onHidden"], [null, "mousedown"], [null, "mouseup"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClickStarted($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseup" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onClickStop($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.esc" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onEsc($event) !== false);
        ad = (pd_2 && ad);
    } if (("onHidden" === en)) {
        var pd_3 = (_co.toggleModal() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, [[1, 4], ["notificationModal", 4]], 0, i3.ModalDirective, [i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i4.ComponentLoaderFactory], { config: [0, "config"] }, { onHidden: "onHidden" }), i1.ɵpod(2, { show: 0, backdrop: 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 25, "div", [["class", "modal-dialog modal-md-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 24, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h4", [["class", "modal-title dosis-bold text-blue3-xs text-18-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "modal-body openSans-regular"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [["class", "openSans-bold text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "p", [["class", "margin-top-md-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, [" ", " "])), (_l()(), i1.ɵeld(17, 0, null, null, 11, "div", [["class", "modal-footer dosis-bold text-blue3-xs text-16-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.page > 0) ? (_co.setPage((_co.page - 1)) || _co.getNotificationsByType("LOGIN", _co.page, 1)) : 0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(20, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(22, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(23, null, ["", " ", " / ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginNotificationsComponent_2)), i1.ɵdid(26, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginNotificationsComponent_3)), i1.ɵdid(28, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, true, "static"); _ck(_v, 1, 0, currVal_0); var currVal_4 = ((_co.page <= 0) ? "text-grey3-xs" : "cursor-hand"); _ck(_v, 19, 0, currVal_4); var currVal_9 = (_co.page < (_co.nbPages - 1)); _ck(_v, 26, 0, currVal_9); var currVal_10 = (_co.page >= (_co.nbPages - 1)); _ck(_v, 28, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("home.notifications.title")); _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.loginNotifications[0].subject; _ck(_v, 14, 0, currVal_2); var currVal_3 = _co.loginNotifications[0].content; _ck(_v, 16, 0, currVal_3); var currVal_5 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform("home.notifications.pagination.previous")); _ck(_v, 20, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform("home.notifications.pagination.page")); var currVal_7 = (_co.page + 1); var currVal_8 = _co.nbPages; _ck(_v, 23, 0, currVal_6, currVal_7, currVal_8); }); }
export function View_LoginNotificationsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { notificationModal: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginNotificationsComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isModalDisplayed; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LoginNotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-login-notifications", [], null, null, null, View_LoginNotificationsComponent_0, RenderType_LoginNotificationsComponent)), i1.ɵdid(1, 114688, null, 0, i6.LoginNotificationsComponent, [i7.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginNotificationsComponentNgFactory = i1.ɵccf("n9-login-notifications", i6.LoginNotificationsComponent, View_LoginNotificationsComponent_Host_0, {}, {}, []);
export { LoginNotificationsComponentNgFactory as LoginNotificationsComponentNgFactory };
