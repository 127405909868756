import { Component, OnInit } from '@angular/core';
import { User } from '@core/models';
import { ROLE } from '@core/models/role.model';
import { SidebarElement } from '@core/models/sidebar-element.model';
import { SessionService } from '@core/session/session.service';
import { AdminNavigationService } from '@modules/admin/home/shared';

@Component({
  selector: 'n9-admin-home',
  templateUrl: 'admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {
  features: SidebarElement[];

  constructor(private navigationService: AdminNavigationService, private sessionService: SessionService) {}

  ngOnInit(): void {
    const user: User = this.sessionService.getUser();

    this.features = [
      { path: '/admin/home', name: 'admin.sidebar.welcomeTab.label' },
      { path: 'membership', name: 'admin.sidebar.membershipTab.label' },
      {
        path: '',
        name: 'admin.sidebar.usersTab.label',
        children: [
          {
            path: 'users',
            name: 'admin.sidebar.usersTab.notificationTab.label'
          },
          {
            path: 'history',
            name: 'admin.sidebar.usersTab.historyTab.label'
          }
        ]
      },
      { path: 'messages', name: 'admin.sidebar.messagesTab.label' },
      /*{ path: 'threads', name: 'admin.sidebar.threadsTab.label' },*/
      /*{ path: 'assemblies', name: 'admin.sidebar.assembliesTab.label' },*/
      {
        path: 'accounts',
        name: 'admin.sidebar.accountsTab.label',
        condition: user.roles.includes(ROLE.ROLE_ADMIN)
      },
      {
        path: '',
        name: 'admin.sidebar.disconnect.label',
        callback: () => {
          this.sessionService.logout();
          this.navigationService.navigateToLogin();
        }
      }
    ];
  }
}
