/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sort-arrows.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sort-arrows.component";
var styles_SortArrowsComponent = [i0.styles];
var RenderType_SortArrowsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SortArrowsComponent, data: {} });
export { RenderType_SortArrowsComponent as RenderType_SortArrowsComponent };
export function View_SortArrowsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "arrows-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "triangle down"], ["placement", "top"], ["tooltip", "Tri d\u00E9croissant"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doSort(_co.SORT_ORDER.DESC) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "triangle up"], ["placement", "top"], ["tooltip", "Tri croissant"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doSort(_co.SORT_ORDER.ASC) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.order === _co.SORT_ORDER.DESC); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.order === _co.SORT_ORDER.ASC); _ck(_v, 2, 0, currVal_1); }); }
export function View_SortArrowsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-sort-arrows", [], null, null, null, View_SortArrowsComponent_0, RenderType_SortArrowsComponent)), i1.ɵdid(1, 49152, null, 0, i2.SortArrowsComponent, [], null, null)], null, null); }
var SortArrowsComponentNgFactory = i1.ɵccf("n9-sort-arrows", i2.SortArrowsComponent, View_SortArrowsComponent_Host_0, { order: "order" }, { sort: "sort" }, []);
export { SortArrowsComponentNgFactory as SortArrowsComponentNgFactory };
