<form
  novalidate
  [formGroup]="parentForm"
  class="form form-horizontal padding-left-sm-sm padding-right-sm-sm margin-none-xs"
>
  <div class="form-group">
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <label for="collectivityType" class="padding-none-xs text-left openSans-bold text-bold text-black-xs">
          {{ 'subscription.mandates.form.collectivityType.label' | translate }}
          <span class="text-red1-xs"> *</span>
          :
        </label>

        <select
          #type
          class="form-control input-lg text-14-xs"
          id="collectivityType"
          formControlName="collectivityType"
          (change)="this.onCollectivityTypeChange($event)"
          required
        >
          <option value="">
            {{ 'subscription.common.form.select' | translate }}
          </option>
          <option value="{{ referentialTypes['mairie'] }}">
            {{ 'subscription.mandates.form.collectivityType.choices.0' | translate }}
          </option>
          <option value="{{ referentialTypes['epci'] }}">
            {{ 'subscription.mandates.form.collectivityType.choices.1' | translate }}
          </option>
          <option value="{{ referentialTypes['departements'] }}">
            {{ 'subscription.mandates.form.collectivityType.choices.2' | translate }}
          </option>
          <option value="{{ referentialTypes['regions'] }}">
            {{ 'subscription.mandates.form.collectivityType.choices.3' | translate }}
          </option>
        </select>
        <p
          *ngIf="
            parentForm.controls.collectivityType.errors && (parentForm.controls.collectivityType.dirty || showErrors)
          "
          class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
        >
          {{ 'subscription.common.form.errors.required' | translate }}
        </p>
      </div>

      <div class="col-sm-6 col-xs-12">
        <label for="collectivityName" class="padding-none-xs text-left openSans-bold text-bold text-black-xs">
          {{ 'subscription.mandates.form.collectivityName' | translate }}
          <span class="text-red1-xs"> *</span>
          :
        </label>
        <input
          *ngIf="type.selectedIndex < 2; else selectCollectivity"
          class="form-control input-lg text-14-xs"
          id="collectivityName"
          formControlName="collectivityName"
          (input)="formatControlValue('collectivityName')"
          (ngModelChange)="onCollectivityNameChange($event)"
          required
        />
        <ng-template #selectCollectivity>
          <select
            class="form-control input-lg text-14-xs"
            id="collectivityName"
            formControlName="collectivityName"
            required
          >
            <option value>
              {{ 'subscription.common.form.select' | translate }}
            </option>
            <option *ngFor="let elem of this.mandatesService.referentials" value="{{ elem.name }}">
              {{ type.value === referentialTypes['departements'] ? elem.code + ' - ' : null }}{{ elem.name }}
            </option>
          </select>
        </ng-template>
        <p
          *ngIf="
            parentForm.controls.collectivityName.errors && (parentForm.controls.collectivityName.dirty || showErrors)
          "
          class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
        >
          {{ 'subscription.common.form.errors.required' | translate }}
        </p>
      </div>
    </div>

    <div *ngIf="type.value === referentialTypes['epci']" class="row">
      <div class="col-sm-offset-6 col-sm-6 col-xs-12">
        <label for="epciName" class="padding-none-xs text-left openSans-bold text-bold text-black-xs">
          {{ 'subscription.mandates.form.epciName' | translate }}
          <span class="text-red1-xs"> *</span>
          :
        </label>
        <input
          type="text"
          (input)="formatControlValue('epciName')"
          class="form-control input-lg text-14-xs"
          id="epciName"
          formControlName="epciName"
        />
      </div>
      <p
        *ngIf="parentForm.controls.epciName.errors && (parentForm.controls.epciName.dirty || showErrors)"
        class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
      >
        {{ 'subscription.common.form.errors.required' | translate }}
      </p>
    </div>
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <label for="mandateType" class="padding-none-xs text-left openSans-bold text-bold text-black-xs">
          {{ 'subscription.mandates.form.mandateType.label' | translate }}
          <span class="text-red1-xs"> *</span>
          :
        </label>
        <select class="form-control input-lg text-14-xs" id="mandateType" formControlName="type" required>
          <option value="">
            {{ 'subscription.common.form.select' | translate }}
          </option>
          <option
            *ngFor="
              let mandateType of (isCityWithBorough ? mandateTypes['borough'] : mandateTypes[type.value]) ||
                mandateTypes['default'];
              let i = index
            "
            value="{{ mandateType }}"
          >
            {{
              'subscription.mandates.form.mandateType.' +
                (mandateTypes[type.value] ? (isCityWithBorough ? 'borough' : type.value) : 'default') +
                '.' +
                i | translate
            }}
          </option>
        </select>
        <p
          *ngIf="parentForm.controls.type.errors && (parentForm.controls.type.dirty || showErrors)"
          class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
        >
          {{ 'subscription.common.form.errors.required' | translate }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-9 col-xs-12">
        <label class="padding-none-xs text-left openSans-bold text-bold text-blue3-xs text-16-xs">
          {{ 'subscription.mandates.form.monthlyContribution' | translate }}
          <span class="text-red1-xs"> *</span>
          :
        </label>

        <div id="monthlyContribution" class="radio-group">
          <input
            type="radio"
            name="monthlyContribution"
            id="8"
            value="8"
            formControlName="monthlyContribution"
            required
          />
          <label class="radio-label text-center" for="8">8 %</label>

          <input
            type="radio"
            name="monthlyContribution"
            id="6"
            value="6"
            formControlName="monthlyContribution"
            required
          />
          <label class="radio-label text-center" for="6">6 %</label>

          <input
            type="radio"
            name="monthlyContribution"
            id="4"
            value="4"
            formControlName="monthlyContribution"
            required
          />
          <label class="radio-label text-center" for="4">4 %</label>
        </div>
        <p
          *ngIf="
            parentForm.controls.monthlyContribution.errors &&
            (parentForm.controls.monthlyContribution.dirty || showErrors)
          "
          class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
        >
          {{ 'subscription.common.form.errors.required' | translate }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-xs-12">
        <label class="padding-none-xs text-left openSans-bold text-bold text-blue3-xs text-16-xs">
          {{ 'subscription.mandates.form.retroactive.label' | translate }}
          <span class="text-red1-xs"> *</span>
          :
        </label>

        <div id="retroactive" class="radio-group">
          <input
            type="radio"
            name="isRetroactive"
            id="isRetroactive-yes"
            value="true"
            formControlName="isRetroactive"
            #isRetroactive
            (click)="removeValidators('optinContribution'); removeValidators('staggeringMonth')"
            required
          />
          <label class="radio-label text-center" for="isRetroactive-yes">
            {{ 'subscription.common.form.yes' | translate }}</label
          >

          <input
            type="radio"
            name="isRetroactive"
            id="isRetroactive-no"
            value="false"
            (click)="addValidators('optinContribution')"
            formControlName="isRetroactive"
            required
          />
          <label class="radio-label text-center" for="isRetroactive-no">{{
            'subscription.common.form.no' | translate
          }}</label>
        </div>
        <p
          *ngIf="parentForm.controls.isRetroactive.errors && (parentForm.controls.isRetroactive.dirty || showErrors)"
          class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
        >
          {{ 'subscription.common.form.errors.required' | translate }}
        </p>
      </div>
    </div>

    <div id="retroactivity-group">
      <div class="row margin-top-sm-xs" *ngIf="parentForm.get('isRetroactive').value === 'true'">
        <div id="retroactivityType" class="radio-group col-lg-6 col-xs-12">
          <input
            type="radio"
            name="retroactivityType"
            id="fromMandate"
            value="fromMandate"
            formControlName="retroactivityType"
            (click)="
              removeValidators('dueDate'); parentForm.get('dueDate').reset(); parentForm.get('dueDate').setValue('')
            "
            required
          />
          <label class="radio-label text-center" for="fromMandate">{{
            'subscription.mandates.form.retroactive.fromMandate' | translate
          }}</label>

          <input
            type="radio"
            name="retroactivityType"
            id="fromEffective"
            value="fromEffective"
            formControlName="retroactivityType"
            #isDueDate
            (click)="addValidators('dueDate')"
            required
          />
          <label class="radio-label text-center" for="fromEffective">{{
            'subscription.mandates.form.retroactive.fromEffective' | translate
          }}</label>
        </div>
        <p
          *ngIf="
            parentForm.controls.retroactivityType.errors && (parentForm.controls.retroactivityType.dirty || showErrors)
          "
          class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs col-xs-12"
        >
          {{ 'subscription.common.form.errors.required' | translate }}
        </p>

        <div class="col-xs-12" *ngIf="isDueDate.checked">
          <label class="col-xs-12 padding-none-xs margin-top-none-xs text-left openSans-bold text-bold text-black-xs">
            {{ 'subscription.mandates.form.retroactive.dueDate' | translate }}
            <span class="text-red1-xs"> *</span>
            :
          </label>

          <div class="col-xs-12 margin-top-none-xs padding-left-none-xs" style="display: flex; align-items: center">
            <n9-form-datepicker
              [parentForm]="parentForm"
              [controlName]="'dueDate'"
              [minDate]="dueDateMin"
              [maxDate]="dueDateMax"
            >
            </n9-form-datepicker>

            <span
              *ngIf="
                !this.parentForm.get('dueDate').valid &&
                this.parentForm.get('dueDate').touched &&
                this.parentForm.get('dueDate').dirty &&
                showErrors
              "
              class="text-red1-xs openSans-semibold col-xs-9"
              >{{ 'subscription.mandates.form.retroactive.dateError' | translate }}</span
            >
          </div>
        </div>

        <div class="col-xs-12">
          <input
            type="radio"
            name="optinContribution"
            class="checkbox-input"
            value="RetroactiveAtReception"
            formControlName="optinContribution"
            (click)="removeValidators('staggeringMonth')"
          />
          {{ 'subscription.mandates.form.retroactive.optin-contribution-retroactive' | translate }}
        </div>

        <div class="col-xs-12 margin-top-xs-xs">
          <input
            type="radio"
            name="optinContribution"
            class="checkbox-input"
            value="RetroactiveWithStaggering"
            formControlName="optinContribution"
            (click)="addValidators('staggeringMonth')"
          />
          {{ 'subscription.mandates.form.retroactive.optin-contribution-retroactive-with-staggering.0' | translate }}
          <input
            type="number"
            class="text-center"
            formControlName="staggeringMonth"
            value=""
            min="0"
            max="72"
            (change)="onStaggeringMonthChange($event)"
            class="stagering-months-box"
          />
          {{ 'subscription.mandates.form.retroactive.optin-contribution-retroactive-with-staggering.1' | translate }}
          <p
            *ngIf="this.parentForm.controls.optinContribution.errors && showErrors"
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs col-xs-12"
          >
            {{ 'subscription.mandates.form.retroactive.contributionError' | translate }}
          </p>
          <p
            [ngClass]="{
              'text-red1-xs text-16-xs': this.parentForm.controls.staggeringMonth.errors && showErrors
            }"
            class="openSans-bold margin-bottom-none-xs margin-top-xs-xs padding-left-md-xs"
          >
            {{ 'subscription.mandates.form.retroactive.optin-retroactive-info' | translate }}
          </p>
          <p class="text-italic hint">
            {{ 'subscription.mandates.form.retroactive.optin-retroactive-hint' | translate }}
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-9 col-xs-12">
        <label class="padding-none-xs text-left openSans-bold text-bold text-blue3-xs text-16-xs">
          {{ 'subscription.mandates.form.inCaseOfDeath.label' | translate }}
          <span class="text-red1-xs"> *</span>
          :
        </label>

        <div id="inCaseOfDeath" class="radio-group">
          <input
            type="radio"
            name="inCaseOfDeath"
            id="successor"
            value="Régime général"
            formControlName="inCaseOfDeath"
            #successor
          />
          <label class="radio-label text-center" for="successor">
            {{ 'subscription.mandates.form.inCaseOfDeath.successor.label' | translate }}
          </label>

          <input
            type="radio"
            name="inCaseOfDeath"
            id="express"
            value="Désignation expresse"
            formControlName="inCaseOfDeath"
            #express
          />
          <label class="radio-label text-center" for="express">
            {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.label' | translate }}
          </label>

          <input
            type="radio"
            name="inCaseOfDeath"
            id="notary"
            value="Désignation notariale"
            formControlName="inCaseOfDeath"
            #notary
          />
          <label class="radio-label text-center" for="notary">
            {{ 'subscription.mandates.form.inCaseOfDeath.notary.label' | translate }}
          </label>
        </div>
        <p
          *ngIf="parentForm.controls.inCaseOfDeath.errors && (parentForm.controls.inCaseOfDeath.dirty || showErrors)"
          class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs col-xs-12"
        >
          {{ 'subscription.common.form.errors.required' | translate }}
        </p>
      </div>

      <p *ngIf="successor.checked" class="col-sm-10 col-xs-12 text-italic padding-top-sm-xs">
        {{ 'subscription.mandates.form.inCaseOfDeath.successor.tooltip' | translate }}
      </p>

      <div [hidden]="!express.checked" class="col-xs-12 margin-none-xs">
        <ng-container #beneficiaries></ng-container>

        <button
          #addBeneficiaryButton
          class="button-mandate add-beneficiary text-uppercase text-center openSans-semibold border-blue3-sm-xs margin-top-lg-xs margin-bottom-lg-xs"
          (click)="createNewBeneficiary()"
          [ngClass]="{
            disabled: this.parentForm.get('beneficiaries')?.length > 9
          }"
        >
          <span
            class="glyphicon glyphicon-plus borders-20-percent background-blue3-xs text-white-xs padding-xs-xs margin-right-xs-xs"
          ></span>
          {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.addBeneficiary' | translate }}
        </button>

        <div *ngIf="beneficiaryContainer.length > 1" formGroupName="beneficiariesDivision" class="inFormArray">
          <p class="text-uppercase dosis-bold text-16-xs text-blue3-xs">
            {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.division.title' | translate }}
          </p>

          <input type="radio" id="equalShares" name="divisionType" formControlName="divisionType" value="equalShares" />
          <label for="equalShares" class="padding-left-sm-xs text-14-xs opensans-bold">
            {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.division.divisionTypes.0' | translate }}
          </label>
          <br />

          <input
            type="radio"
            id="priorityOrder"
            name="divisionType"
            formControlName="divisionType"
            value="priorityOrder"
            (change)="resetPriorityValues()"
            #priorityOrder
          />
          <label for="priorityOrder" class="padding-left-sm-xs text-14-xs opensans-bold">
            {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.division.divisionTypes.1' | translate }}
          </label>
          <br />

          <div *ngIf="priorityOrder.checked" class="col-xs-12 margin-top-sm-xs margin-bottom-lg-xs">
            <div
              *ngFor="
                let b of parentForm.get('beneficiariesDivision.beneficiaryPriorityOrder').value;
                let i = index;
                trackBy: trackByFn
              "
              class="col-sm-4 col-xs-12 inFormArray"
              formArrayName="beneficiaryPriorityOrder"
            >
              <span class="text-14-xs opensans-bold">
                {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.division.priorityOrder' | translate
                }}{{ i + 1 }}
              </span>
              <br />

              <div formGroupName="{{ i }}" class="border-none-xs">
                <select id="priority-{{ i }}" class="form-control input-lg text-14-xs" formControlName="priority">
                  <option value="">
                    {{ 'subscription.common.form.select' | translate }}
                  </option>
                  <option
                    *ngFor="
                      let b of parentForm.get('beneficiariesDivision.beneficiaryPriorityOrder').value;
                      let i = index
                    "
                    value="{{ i + 1 }}"
                  >
                    {{ i + 1 }}
                  </option>
                </select>
              </div>
            </div>
            <div
              *ngIf="
                !parentForm.get('beneficiariesDivision.beneficiaryPriorityOrder').valid &&
                parentForm.get('beneficiariesDivision.beneficiaryPriorityOrder').dirty
              "
              class="col-xs-12"
            >
              <span class="text-red1-xs opensans-bold">
                {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.division.errors.order' | translate }}
              </span>
            </div>
          </div>

          <input
            type="radio"
            id="perPercent"
            name="divisionType"
            formControlName="divisionType"
            value="perPercent"
            (change)="resetPriorityValues()"
            #perPercent
          />
          <label for="perPercent" class="padding-left-sm-xs text-14-xs opensans-bold">
            {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.division.divisionTypes.2' | translate }}
          </label>

          <div *ngIf="perPercent.checked">
            <div
              *ngFor="
                let b of parentForm.get('beneficiariesDivision.beneficiaryPriorityOrder').value;
                let i = index;
                trackBy: trackByFn
              "
              class="col-sm-4 col-xs-12 inFormArray"
              formArrayName="beneficiaryPriorityOrder"
            >
              <span class="text-14-xs opensans-bold">
                {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.division.perPercent' | translate }}{{ i + 1 }}
              </span>
              <br />

              <div formGroupName="{{ i }}" class="border-none-xs">
                <input
                  id="percent-{{ i }}"
                  class="form-control input-lg text-14-xs"
                  formControlName="priority"
                  (keydown.enter)="$event.preventDefault()"
                  [ngClass]="{
                    'invalid-beneficiary-division':
                      !parentForm.get('beneficiariesDivision.beneficiaryPriorityOrder').valid &&
                      parentForm.get('beneficiariesDivision.beneficiaryPriorityOrder').dirty
                  }"
                />
              </div>
            </div>
            <div
              *ngIf="
                !parentForm.get('beneficiariesDivision.beneficiaryPriorityOrder').valid &&
                parentForm.get('beneficiariesDivision.beneficiaryPriorityOrder').dirty
              "
              class="col-xs-12"
            >
              <span class="text-red1-xs opensans-bold">
                {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.division.errors.perPercent' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div [hidden]="!notary.checked" class="margin-none-xs border-none-xs" formGroupName="beneficiariesDivision">
        <div class="col-xs-12 margin-top-lg-xs">
          <p class="dosis-bold text-16-xs text-uppercase text-blue3-xs">
            {{ 'subscription.mandates.form.inCaseOfDeath.notary.title' | translate }}
          </p>
        </div>

        <div class="col-sm-6 col-xs-12 margin-top-sm-xs">
          <label for="notary-firstname" class="padding-none-xs text-left openSans-bold text-bold text-black-xs">
            {{ 'subscription.mandates.form.inCaseOfDeath.notary.firstname' | translate }}
            <span class="text-red1-xs"> *</span>
            :
          </label>
          <input
            type="text"
            (input)="formatControlValue('beneficiariesDivision.notaryFirstname')"
            class="form-control input-lg text-14-xs"
            id="notary-firstname"
            formControlName="notaryFirstname"
          />
          <p
            *ngIf="
              parentForm.get('beneficiariesDivision.notaryFirstname').errors &&
              (parentForm.get('beneficiariesDivision.notaryFirstname').dirty || showErrors)
            "
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
          >
            {{ 'subscription.mandates.form.inCaseOfDeath.notary.errors.lettersOnly' | translate }}
          </p>
        </div>

        <div class="col-sm-6 col-xs-12 margin-top-sm-xs">
          <label for="notary-lastname" class="padding-none-xs text-left openSans-bold text-bold text-black-xs">
            {{ 'subscription.mandates.form.inCaseOfDeath.notary.lastname' | translate }}
            <span class="text-red1-xs"> *</span>
            :
          </label>
          <input
            type="text"
            (input)="formatControlValue('beneficiariesDivision.notaryLastname')"
            class="form-control input-lg text-14-xs"
            id="notary-lastname"
            formControlName="notaryLastname"
          />
          <p
            *ngIf="
              parentForm.get('beneficiariesDivision.notaryLastname').errors &&
              (parentForm.get('beneficiariesDivision.notaryLastname').dirty || showErrors)
            "
            class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
          >
            {{ 'subscription.mandates.form.inCaseOfDeath.notary.errors.lettersOnly' | translate }}
          </p>
        </div>

        <div formGroupName="notaryAddress" class="border-none-xs">
          <div class="col-sm-6 col-xs-12 margin-top-sm-xs">
            <label for="address" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
              {{ 'subscription.mandates.form.inCaseOfDeath.notary.address' | translate }}
              <span class="text-red1-xs"> *</span>
              :
            </label>
            <input
              type="text"
              (input)="formatControlValue('beneficiariesDivision.notaryAddress.address')"
              class="form-control input-lg text-14-xs"
              id="address"
              formControlName="address"
            />
            <p
              *ngIf="
                parentForm.get('beneficiariesDivision.notaryAddress.address').errors &&
                (parentForm.get('beneficiariesDivision.notaryAddress.address').dirty || showErrors)
              "
              class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
            >
              {{ 'subscription.mandates.form.inCaseOfDeath.notary.errors.lettersAndNumbersOnly' | translate }}
            </p>
          </div>

          <div class="col-sm-6 col-xs-12 margin-top-sm-xs">
            <label for="postalCode" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
              {{ 'subscription.subscriber.form.postalCode' | translate }}
              <span class="text-red1-xs"> *</span>
              :
            </label>
            <input type="text" class="form-control input-lg text-14-xs" id="postalCode" formControlName="postalCode" />
            <p
              *ngIf="
                parentForm.get('beneficiariesDivision.notaryAddress.postalCode').errors &&
                (parentForm.get('beneficiariesDivision.notaryAddress.postalCode').dirty || showErrors)
              "
              class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
            >
              {{ 'subscription.mandates.form.inCaseOfDeath.notary.errors.numbersOnly' | translate }}
            </p>
          </div>

          <div class="col-sm-6 col-xs-12 margin-top-sm-xs">
            <label for="city" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
              {{ 'subscription.subscriber.form.city' | translate }}
              <span class="text-red1-xs"> *</span>
              :
            </label>
            <input
              type="text"
              (input)="formatControlValue('beneficiariesDivision.notaryAddress.city')"
              class="form-control input-lg text-14-xs col-xs-6"
              id="city"
              formControlName="city"
            />
            <p
              *ngIf="
                parentForm.get('beneficiariesDivision.notaryAddress.city').errors &&
                (parentForm.get('beneficiariesDivision.notaryAddress.city').dirty || showErrors)
              "
              class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
            >
              {{ 'subscription.mandates.form.inCaseOfDeath.notary.errors.lettersAndNumbersOnly' | translate }}
            </p>
          </div>
        </div>

        <div class="col-sm-6 col-xs-12 margin-top-sm-xs">
          <label for="phone" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
            {{ 'subscription.subscriber.form.phone' | translate }}
            :
          </label>
          <input
            type="text"
            class="form-control input-lg text-14-xs col-xs-6"
            id="notaryPhone"
            formControlName="notaryPhone"
          />
        </div>
      </div>
    </div>
  </div>
</form>
