/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidebar-element.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./sidebar-element.component";
import * as i4 from "ngx-bootstrap/collapse";
import * as i5 from "@angular/animations";
import * as i6 from "@angular/router";
import * as i7 from "@ngx-translate/core";
var styles_SidebarElementComponent = [i0.styles];
var RenderType_SidebarElementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidebarElementComponent, data: {} });
export { RenderType_SidebarElementComponent as RenderType_SidebarElementComponent };
function View_SidebarElementComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "caret"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "caret"; var currVal_1 = (_co.element.collapsed ? "caret-right" : ""); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SidebarElementComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-sidebar-element", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.recursiveEmitter($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_SidebarElementComponent_0, RenderType_SidebarElementComponent)), i1.ɵdid(1, 114688, null, 0, i3.SidebarElementComponent, [], { element: [0, "element"] }, { selected: "selected" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SidebarElementComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarElementComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.condition !== false) ? "" : "hide"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.$implicit.condition !== false); _ck(_v, 3, 0, currVal_1); }, null); }
function View_SidebarElementComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [["class", "col-xs-12 padding-none-xs margin-none-xs"], ["id", "sidebarCollapse"]], [[2, "collapse", null], [2, "in", null], [2, "show", null], [1, "aria-expanded", 0], [1, "aria-hidden", 0], [2, "collapsing", null]], null, null, null, null)), i1.ɵdid(1, 8404992, null, 0, i4.CollapseDirective, [i1.ElementRef, i1.Renderer2, i5.AnimationBuilder], { collapse: [0, "collapse"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarElementComponent_3)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.element.collapsed; _ck(_v, 1, 0, currVal_6); var currVal_7 = _co.element.children; _ck(_v, 3, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isCollapse; var currVal_1 = i1.ɵnov(_v, 1).isExpanded; var currVal_2 = i1.ɵnov(_v, 1).isExpanded; var currVal_3 = i1.ɵnov(_v, 1).isExpanded; var currVal_4 = i1.ɵnov(_v, 1).isCollapsed; var currVal_5 = i1.ɵnov(_v, 1).isCollapsing; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_SidebarElementComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["aria-controls", "sidebarCollapse"], ["class", "cursor-hand padding-md-xs"]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(2, 16384, null, 0, i6.RouterLink, [i6.Router, i6.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 4, "span", [["class", "text-white-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarElementComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SidebarElementComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "cursor-hand padding-md-xs"; var currVal_2 = (_co.element.selected ? "selected" : ""); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = (_co.element.path ? _ck(_v, 3, 0, _co.element.path) : i1.ɵEMPTY_ARRAY); _ck(_v, 2, 0, currVal_3); var currVal_5 = _co.element.children; _ck(_v, 8, 0, currVal_5); var currVal_6 = _co.element.children; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.element.collapsed; _ck(_v, 0, 0, currVal_0); var currVal_4 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.element.name)); _ck(_v, 5, 0, currVal_4); }); }
export function View_SidebarElementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-sidebar-element", [], null, null, null, View_SidebarElementComponent_0, RenderType_SidebarElementComponent)), i1.ɵdid(1, 114688, null, 0, i3.SidebarElementComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarElementComponentNgFactory = i1.ɵccf("n9-sidebar-element", i3.SidebarElementComponent, View_SidebarElementComponent_Host_0, { element: "element" }, { selected: "selected" }, []);
export { SidebarElementComponentNgFactory as SidebarElementComponentNgFactory };
