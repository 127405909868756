import { Component, Input } from '@angular/core';

import { User } from '@core/models';

@Component({
  selector: 'n9-user-info',
  templateUrl: 'user-info.component.html'
})
export class UserInfoComponent {
  @Input() public user: User;

  public hideContracts: boolean;
  public hideMandates: boolean;

  constructor() {
    this.hideContracts = false;
    this.hideMandates = false;
  }

  public toggleContract(): void {
    this.hideContracts = !this.hideContracts;
  }

  public toggleMandates(): void {
    this.hideMandates = !this.hideMandates;
  }

  public hasContracts(): any {
    return this.user.contracts && this.user.contracts.length;
  }

  public hasMandates(): any {
    return this.user.mandates && this.user.mandates.length;
  }
}
