import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validators } from '@angular/forms';

@Directive({
  selector: '[n9-latin-alphabetic][ngModel],[n9-latin-alphabetic][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LatinAlphabeticValidatorDirective),
      multi: true
    }
  ]
})
export class LatinAlphabeticValidatorDirective implements Validators {
  public static validInput(control: FormControl): object {
    const value: string = control.value;
    const regex: RegExp = /^[a-zà-ÿ]+( ?[a-zà-ÿ]+)*$/i;
    if (!value || value === '') return null;

    return !regex.test(value) ? { n9LatinAlphabetic: { valid: false } } : null;
  }
}
