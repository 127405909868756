/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./pagination.component";
var styles_PaginationComponent = [];
var RenderType_PaginationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PaginationComponent, data: {} });
export { RenderType_PaginationComponent as RenderType_PaginationComponent };
function View_PaginationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [["class", "cursor-hand background-transparent border-none-xs"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickOnPage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", "background-transparent border-none-xs margin-top-xs-xs text-14-xs text-blue3-xs"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "pagination-selected": 0 }), (_l()(), i0.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "background-transparent border-none-xs margin-top-xs-xs text-14-xs text-blue3-xs"; var currVal_1 = _ck(_v, 3, 0, (_co.currentPage === _v.context.$implicit)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 4, 0, currVal_2); }); }
export function View_PaginationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "ul", [["class", "pagination"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "li", [["class", "cursor-hand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickPrevious() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["class", "background-transparent border-none-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-angle-left text-30-xs"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_1)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 2, "li", [["class", "cursor-hand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickNext() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "a", [["class", "background-transparent border-none-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-angle-right text-30-xs"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pages; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_PaginationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "n9-pagination", [], null, null, null, View_PaginationComponent_0, RenderType_PaginationComponent)), i0.ɵdid(1, 114688, null, 0, i2.PaginationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaginationComponentNgFactory = i0.ɵccf("n9-pagination", i2.PaginationComponent, View_PaginationComponent_Host_0, { maxPages: "maxPages" }, { nextPage: "nextPage" }, []);
export { PaginationComponentNgFactory as PaginationComponentNgFactory };
