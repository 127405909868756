import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'n9-form-datepicker',
  templateUrl: './form-datepicker.component.html',
  styleUrls: ['./form-datepicker.component.scss']
})
export class FormDatepickerComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() controlName: string;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  bsConfig: Partial<BsDatepickerConfig>;

  constructor() {}

  public ngOnInit(): void {
    this.bsConfig = Object.assign(
      {},
      {
        containerClass: 'theme-dark-blue',
        dateInputFormat: 'DD/MM/YYYY',
        minDate: this.minDate,
        maxDate: this.maxDate
      }
    );

    this.parentForm.get(this.controlName).valueChanges.subscribe((date: Date) => {
      if (date) {
        this.parentForm
          .get(this.controlName)
          .setValue(
            new Date(date.getFullYear(), date.getMonth(), date.getDate(), (date.getTimezoneOffset() / 60) * -1, 0, 0),
            { emitEvent: false }
          );
      }
    });
  }
}
