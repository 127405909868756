/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./contracts.component";
var styles_ContractsComponent = [];
var RenderType_ContractsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContractsComponent, data: {} });
export { RenderType_ContractsComponent as RenderType_ContractsComponent };
export function View_ContractsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ContractsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "n9-contracts", [], null, null, null, View_ContractsComponent_0, RenderType_ContractsComponent)), i0.ɵdid(1, 49152, null, 0, i2.ContractsComponent, [], null, null)], null, null); }
var ContractsComponentNgFactory = i0.ɵccf("n9-contracts", i2.ContractsComponent, View_ContractsComponent_Host_0, {}, {}, []);
export { ContractsComponentNgFactory as ContractsComponentNgFactory };
