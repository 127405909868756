import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[n9-alphanum][ngModel],[n9-alphanum][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AlphanumericValidatorDirective),
      multi: true
    }
  ]
})
export class AlphanumericValidatorDirective implements Validator {
  public static validInput(control: FormControl): object {
    const value: string = control.value;
    const regex: RegExp = /^[a-z0-9\-\s]*$/i;

    if (!value || value === '') return null;

    return regex.test(value) ? null : { n9AlphaNum: { valid: false } };
  }

  public validate(control: FormControl): object {
    return AlphanumericValidatorDirective.validInput(control);
  }
}
