import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiHttpClient } from '@core/interceptors/api-http-client.service';
import { ConfigurationService } from '@core/services/conf/configuration.service';
import { SessionService } from '@core/session/session.service';

@Component({
  selector: 'n9-app',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private translateService: TranslateService,
    private session: SessionService,
    private conf: ConfigurationService,
    private http: ApiHttpClient
  ) {
    // i18n configuration
    this.translateService.setDefaultLang('en-GB');
    this.translateService.use('fr-FR');

    // session configuration
    this.session.load();

    // api client configuration
    this.http.baseUrl = this.conf.getAsString('api');

    const envElements: string[] = [
      `espace-adherent : version ${this.conf.get('app').version}`,
      `environment : ${this.conf.get('name')}`
    ];
    const separator: string = '-'.repeat(envElements.sort((a, b) => b.length - a.length)[0].length);

    // tslint:disable-next-line:no-console
    console.log([separator].concat(...envElements, separator).join('\n'));
  }
}
