import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Message } from '@shared/components/threads/message.models';
import { BaseService } from '@core/services/base.service';

@Injectable()
export class AdminDiscussionsService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public getMessagesList(page: number = 0, size: number = 200): Observable<any> {
    return this.http.get(`/threads` + '?page=' + page + '&size=' + size);
  }

  public getMessagesByEluNumber(eluNumber: number, page: number = 0, size: number = 200): Observable<Message[]> {
    return this.http
      .get('/messages/threads/' + eluNumber + '?page=' + page + '&size=' + size)
      .map((messages) => messages['content'])
      .catch((error) => Observable.throwError(error));
  }

  public getThreadsList(): Observable<any> {
    return this.http
      .get(`/threads/ids`)
      .map((threads) => threads['content'])
      .catch((error) => Observable.throwError(error));
  }

  public getThreadMessagesByThreadId(threadId: string, page: number = 0, size: number = 200): Observable<Message[]> {
    return this.http
      .get('/threads/' + threadId + '?page=' + page + '&size=' + size)
      .map((messages) => messages['content'])
      .catch((error) => Observable.throwError(error));
  }
}
