import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SessionService } from '@core/session/session.service';
import { User } from '@core/models';

@Component({
  selector: 'n9-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss']
})
export class HomeComponent implements OnInit {
  user: User;

  constructor(private sessionService: SessionService, private router: Router) {
    this.user = this.sessionService.getUser();
  }

  public ngOnInit(): void {
    this.checkAndRedirect();
  }

  public checkAndRedirect(): void {
    if (
      !this.user.email ||
      !this.user.title ||
      !this.user.firstname ||
      (!this.user.lastname && !this.user.marriedName) ||
      !this.user.birthDate ||
      !this.user.phoneNumber ||
      !this.user.address ||
      !this.user.postalCode ||
      !this.user.city ||
      !this.user.country
    )
      this.router.navigateByUrl('/home/profile');
  }
}
