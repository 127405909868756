import { Directive, forwardRef } from '@angular/core';
import { FormArray, NG_VALIDATORS, ValidatorFn, Validators } from '@angular/forms';

@Directive({
  selector: '[n9-priority-order][ngModel],[n9-priority-order][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BeneficiaryDivisionValidatorDirective),
      multi: true
    }
  ]
})
export class BeneficiaryDivisionValidatorDirective implements Validators {
  public static validInput(type: string): ValidatorFn {
    return (array: FormArray): object => {
      const division: number[] = array.value.map((control) => parseInt(control['priority'], 10));

      switch (type) {
        case 'priorityOrder':
          // Invalid if two beneficiaries have the same priority
          return division.length !== division.filter((v, i, self) => self.indexOf(v) === i).length
            ? { n9PriorityOrder: { valid: false } }
            : null;
        case 'perPercent':
          if (division.length === 0) {
            return;
          }
          // Invalid if the sum of values is not 100%
          return division.reduce((a, b) => a + b) !== 100 ? { n9PriorityOrder: { valid: false } } : null;
      }
    };
  }
}
