import { Component, Input, OnInit } from '@angular/core';

import { Contract } from '@core/models';

@Component({
  selector: 'n9-contract-detail',
  templateUrl: 'contract-detail.component.html'
})
export class ContractDetailComponent implements OnInit {
  @Input() public contract: Contract;

  public ngOnInit(): void {
    if (this.contract) {
      switch (this.contract.status) {
        case 'PENDING':
          this.contract.status = 'En cours';
          break;
        case 'TERMINATED':
          this.contract.status = 'Résilié';
          break;
        case 'LIQUIDATED':
          this.contract.status = 'Liquidé';
          break;
      }
    }
  }
}
