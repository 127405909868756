/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./carel-dropdown.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "ngx-bootstrap/dropdown";
import * as i5 from "ngx-bootstrap/component-loader";
import * as i6 from "@angular/animations";
import * as i7 from "./carel-dropdown.component";
var styles_CarelDropdownComponent = [i0.styles];
var RenderType_CarelDropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarelDropdownComponent, data: {} });
export { RenderType_CarelDropdownComponent as RenderType_CarelDropdownComponent };
function View_CarelDropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "openSans-bold text-black-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " : "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; _ck(_v, 1, 0, currVal_0); }); }
function View_CarelDropdownComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "text-grey4-xs": 0, "text-blue3-xs": 1, "text-red3-xs": 2, "text-green2-xs": 3 }), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_co.title === "SENT"), (_co.title === "IN_PROGRESS"), (_co.title === "REJECTED"), (_co.title === "DONE")); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform((_co.i18nTitle + (_co.title ? ("." + _co.title) : ".NONE")))); _ck(_v, 3, 0, currVal_1); }); }
function View_CarelDropdownComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.optionFormat ? _co.formatOption(_co.selectedOptionDisplay) : i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(((_co.i18nOptions + ".") + _co.selectedOptionDisplay)))); _ck(_v, 1, 0, currVal_0); }); }
function View_CarelDropdownComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "caret"]], null, null, null, null, null))], null, null); }
function View_CarelDropdownComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "col-xs-12 padding-none-xs"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectOption(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "dropdown-item"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.optionFormat ? _co.formatOption(_v.context.$implicit) : i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(((_co.i18nOptions + ".") + _v.context.$implicit)))); _ck(_v, 2, 0, currVal_0); }); }
function View_CarelDropdownComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "dropdown-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarelDropdownComponent_6)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CarelDropdownComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 14, "div", [["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], null, null, null, null)), i1.ɵprd(512, null, i4.BsDropdownState, i4.BsDropdownState, []), i1.ɵdid(2, 212992, null, 0, i4.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i5.ComponentLoaderFactory, i4.BsDropdownState, i4.BsDropdownConfig, i6.AnimationBuilder], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 9, "a", [["aria-controls", "basic-link-dropdown"], ["class", "openSans-semibold background-white-xs rounded padding-left-md-xs padding-right-md-xs padding-top-sm-xs padding-bottom-sm-xs border-grey3-xs-xs"], ["dropdownToggle", ""], ["href", ""]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (false !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 147456, null, 0, i4.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i4.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i4.BsDropdownState], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarelDropdownComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarelDropdownComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarelDropdownComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarelDropdownComponent_4)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarelDropdownComponent_5)), i1.ɵdid(14, 16384, null, 0, i4.BsDropdownMenuDirective, [i4.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_6 = _co.type; _ck(_v, 6, 0, currVal_6); var currVal_7 = !_co.selectedOptionDisplay; _ck(_v, 8, 0, currVal_7); var currVal_8 = _co.selectedOptionDisplay; _ck(_v, 10, 0, currVal_8); var currVal_9 = !_co.cantSet; _ck(_v, 12, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).dropup; var currVal_1 = i1.ɵnov(_v, 2).isOpen; var currVal_2 = (i1.ɵnov(_v, 2).isOpen && i1.ɵnov(_v, 2).isBs4); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = true; var currVal_4 = i1.ɵnov(_v, 4).isDisabled; var currVal_5 = i1.ɵnov(_v, 4).isOpen; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_CarelDropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-carel-dropdown", [], null, null, null, View_CarelDropdownComponent_0, RenderType_CarelDropdownComponent)), i1.ɵdid(1, 49152, null, 0, i7.CarelDropdownComponent, [], null, null)], null, null); }
var CarelDropdownComponentNgFactory = i1.ɵccf("n9-carel-dropdown", i7.CarelDropdownComponent, View_CarelDropdownComponent_Host_0, { options: "options", optionFormat: "optionFormat", title: "title", i18nTitle: "i18nTitle", i18nOptions: "i18nOptions", type: "type", cantSet: "cantSet" }, { selectedOption: "selectedOption" }, []);
export { CarelDropdownComponentNgFactory as CarelDropdownComponentNgFactory };
