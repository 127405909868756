import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { MembershipStatus } from '@core/models/membership-status.model';
import { RequestService } from '@shared/services/request.service';
import { SubscriptionService } from '../subscription.service';
import { HomeNavigationService } from '@core/services/navigation.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/platform-browser";
import * as i3 from "../subscription.service";
import * as i4 from "../../../shared/services/request.service";
import * as i5 from "../../../core/services/navigation.service";
export class SignatureService {
    constructor(http, sanitizer, subscriptionService, requestService, navigation) {
        this.http = http;
        this.sanitizer = sanitizer;
        this.subscriptionService = subscriptionService;
        this.requestService = requestService;
        this.navigation = navigation;
        this.stepNumber = 3;
    }
    startSignature() {
        return this.http
            .post('/signature', {
            membershipMarketingId: localStorage.getItem('membershipMarketingId')
        })
            .map((response) => {
            this.signUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response['url']);
        })
            .catch((error) => Observable.throwError(error));
    }
    startUserRequestSignature(requestToken, requestType, eluNumber) {
        return this.http
            .post('/signature/user-request', {
            token: requestToken,
            userRequestType: requestType,
            eluNumber
        })
            .map((response) => {
            this.signUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response['url']);
        })
            .catch((error) => Observable.throwError(error));
    }
    downloadSignedFile(fileId) {
        return this.http.get(`/signature/documents/${fileId}/download/`, {
            responseType: 'blob',
            observe: 'response'
        });
    }
    checkSignatureComplete(isMembership, isContract) {
        setTimeout(() => this.subscriptionService.getSubscriberValues().subscribe((response) => {
            switch (response['status']) {
                case MembershipStatus.SIGNATURE_REFUSED:
                    localStorage.setItem('step', '0');
                    if (!isContract) {
                        this.subscriptionService.goToStep(0);
                    }
                    else {
                        this.navigation.navigateToRoute(`/home/contracts`, null);
                    }
                    return;
                case MembershipStatus.SIGNATURE_COMPLETE:
                    const marketingId = localStorage.getItem('membershipMarketingId');
                    this.damId = response['signatureInformations']['damId'];
                    if (!isContract) {
                        setTimeout(() => this.subscriptionService.goForward(this.stepNumber), 10000);
                    }
                    else {
                        this.navigation.navigateToRoute(`/home/contracts`, null);
                    }
                    return;
                default:
                    this.checkSignatureComplete(isMembership);
            }
        }, (error) => Observable.throwError(error)), 3000);
    }
}
SignatureService.ngInjectableDef = i0.defineInjectable({ factory: function SignatureService_Factory() { return new SignatureService(i0.inject(i1.HttpClient), i0.inject(i2.DomSanitizer), i0.inject(i3.SubscriptionService), i0.inject(i4.RequestService), i0.inject(i5.HomeNavigationService)); }, token: SignatureService, providedIn: "root" });
