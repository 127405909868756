import { Injectable } from '@angular/core';
import { ApiHttpClient } from '@core/interceptors/api-http-client.service';
import { BaseService } from '@core/services/base.service';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PasswordSetService {
  constructor(private http: HttpClient) {}

  public setPassword(password: string): Observable<any> {
    return this.http.post(`/users/mypassword`, password);
  }
}
