import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { userMock } from '@core/mock/variables/user.mock';
import { User } from '@core/models';
import { Session } from '@core/session/session.models';
import { SessionService } from '@core/session/session.service';
import { Credentials, FirstLoginCredentials } from './login.models';

@Injectable()
export class LoginService {
  constructor(private sessionService: SessionService, private http: HttpClient) {}

  login(credentials: Credentials): Observable<Session> {
    return this.http.post('/login', credentials).flatMap((res) => {
      this.sessionService.openSession(res as Session);
      return this.http.get('/me').map((user: User) => {
        return this.sessionService.setUser(user);
      });
    });
    // this.sessionService.openSession({ token: 'oui' } as Session);
    // this.sessionService.setUser(userMock);
    // return of(this.sessionService.getSession());
  }

  firstLogin(credentials: FirstLoginCredentials): Observable<Session> {
    return this.http.post('/login/first', credentials).flatMap((res) => {
      this.sessionService.openSession(res as Session);
      return this.http.get('/me').map((user: User) => {
        return this.sessionService.setUser(user);
      });
    });
  }

  public forgottenPassword(
    email: string,
    name: string,
    firstName: string,
    eluNumber: string,
    phone: number
  ): Observable<any> {
    return this.http
      .post('/login/forgotten', {
        email,
        name,
        firstName,
        eluNumber,
        phone
      })
      .catch((error) => Observable.throw(error.json() || { status: 500, error: 'Server error' }));
  }

  public createPassword(password: string, token: string): Observable<any> {
    return this.http
      .post('/login/createPassword', {
        password,
        token
      })
      .catch((error) => Observable.throw(error.json() || { status: 500, error: 'Server error' }));
  }

  public setEmail(eluNumber: string, email: string): Observable<object> {
    return this.http
      .post('/login/addEmail', { eluNumber, email })
      .map((res) => res)
      .catch((err) => Observable.throwError(err));
  }
}
