export class Constants {
  public static errorsMessages: any = {
    MANDATE_ALREADY_EXISTS: 'Le mandat que vous essayez de déclarer existe déja',
    MANDATE_INVALID: 'Le mandat que vous essayez de déclarer est invalide',
    MANDATE_INVALID_CONTRIBUTION: 'Le taux de cotisation demandé est invalide',
    MANDATE_INVALID_CLAUSE: 'La nouvelle clause est invalide',
    MESSAGE_POST_INVALID: 'Votre message ne peut etre posté car invalide',
    MESSAGE_EDIT_INVALID: 'Votre message ne peut etre edité car invalide',
    SERVER_ERROR: 'Une erreur est survenue pendant le traitement, veuillez réssayer plus tard',
    THREAD_NOT_FOUND: "Le sujet de discussion n'a pas ete trouvé",
    USER_EDIT_INVALID: 'Les modifications demandées sont invalides',
    INVALID_FILE_FORMAT: "Le format du fichier que vous voulez ajouter n'est pas accepté"
  };

  public static successMessages: any = {
    MESSAGE_POST_SUCCESS: 'Votre message a été envoyé avec succès'
  };

  public static clauseConstants: any = {
    generalClauseType: 'GENERAL_CASE',
    customClauseType: 'CUSTOM',
    generalClauseFullName: 'Cas général successoral',
    customClauseFullName: 'Désignation expresse'
  };

  public static contractActions: any = {
    CHANGE_BENEFICIARY_CLAUSE: 'beneficiary',
    CHANGE_CONTRIBUTION_RATE: 'contribution'
  };
}
