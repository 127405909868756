<div
  class="cursor-hand padding-md-xs"
  [ngClass]="this.element.selected ? 'selected' : ''"
  [routerLink]="element.path ? [element.path] : []"
  (click)="onClick($event)"
  [attr.aria-expanded]="!element.collapsed"
  aria-controls="sidebarCollapse"
>
  <span class="text-white-xs">
    {{ element.name | translate }}
    <span *ngIf="element.children" class="caret" [ngClass]="element.collapsed ? 'caret-right' : ''"></span>
  </span>

  <ul
    *ngIf="element.children"
    id="sidebarCollapse"
    class="col-xs-12 padding-none-xs margin-none-xs"
    [collapse]="element.collapsed"
  >
    <li *ngFor="let feature of element.children; let i = index" [ngClass]="feature.condition !== false ? '' : 'hide'">
      <n9-sidebar-element *ngIf="feature.condition !== false" [element]="feature" (selected)="recursiveEmitter($event)">
      </n9-sidebar-element>
    </li>
  </ul>
</div>
