<div class="col-xs-12 padding-none-xs">
  <h4 class="col-xs-12 text-left text-blue3-xs text-bold">
    {{ 'admin.assemblies.form.title' | translate }}
  </h4>

  <form
    class="col-xs-12 form form-horizontal padding-top-md-xs padding-bottom-md-xs background-white-xs border-xs-grey6-xs"
    novalidate
    [formGroup]="assemblyForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="col-xs-12 form-group padding-left-sm-xs">
      <label class="col-xs-6 col-md-5 col-lg-4 text-blue3-xs text-right">
        {{ 'admin.assemblies.form.labels.location' | translate }}
      </label>
      <input class="col-xs-5" type="text" formControlName="location" />
      <div class="col-xs-5" *ngIf="assemblyForm.controls.location.errors && assemblyForm.controls.location.dirty">
        <div
          class="alert text-10-xs text-red2-xs margin-bottom-none-xs"
          *ngIf="assemblyForm.controls.location.errors.required"
        >
          {{ 'admin.assemblies.form.errors.location.required' | translate }}
        </div>
        <div
          class="alert text-10-xs text-red2-xs margin-bottom-none-xs"
          *ngIf="assemblyForm.controls.location.errors.n9AlphaNum"
        >
          {{ 'admin.assemblies.form.errors.location.alphaNum' | translate }}
        </div>
      </div>
    </div>
    <div class="col-xs-12 form-group padding-left-sm-xs">
      <label class="col-xs-6 col-md-5 col-lg-4 text-blue3-xs text-right">
        {{ 'admin.assemblies.form.labels.conveningDate' | translate }}
      </label>
      <n9-datepicker formControlName="conveningDate"></n9-datepicker>
      <div
        class="col-xs-5"
        *ngIf="assemblyForm.controls.conveningDate.errors && assemblyForm.controls.conveningDate.dirty"
      >
        <div
          class="alert text-10-xs text-red2-xs margin-bottom-none-xs"
          *ngIf="assemblyForm.controls.conveningDate.errors.required"
        >
          {{ 'admin.assemblies.form.errors.conveningDate.required' | translate }}
        </div>
        <div
          class="alert text-10-xs text-red2-xs margin-bottom-none-xs"
          *ngIf="assemblyForm.controls.conveningDate.errors.n9AfterNow"
        >
          {{ 'admin.assemblies.form.errors.conveningDate.afterNow' | translate }}
        </div>
      </div>
    </div>
    <div class="col-xs-12 form-group padding-left-sm-xs">
      <label class="col-xs-6 col-md-5 col-lg-4 text-blue3-xs text-right">
        {{ 'admin.assemblies.form.labels.endVoteDate' | translate }}
      </label>
      <n9-datepicker formControlName="endVoteDate"></n9-datepicker>
      <div class="col-xs-5" *ngIf="assemblyForm.controls.endVoteDate.errors && assemblyForm.controls.endVoteDate.dirty">
        <div
          class="alert text-10-xs text-red2-xs margin-bottom-none-xs"
          *ngIf="assemblyForm.controls.endVoteDate.errors.required"
        >
          {{ 'admin.assemblies.form.errors.endVoteDate.required' | translate }}
        </div>
        <div
          class="alert text-10-xs text-red2-xs margin-bottom-none-xs"
          *ngIf="assemblyForm.controls.endVoteDate.errors.n9AfterNow"
        >
          {{ 'admin.assemblies.form.errors.endVoteDate.afterNow' | translate }}
        </div>
      </div>
    </div>
    <div class="col-xs-12 form-group padding-left-sm-xs">
      <label class="col-xs-4 text-right text-blue3-xs text-bold">
        {{ 'admin.assemblies.form.labels.possibleVotes' | translate }}
      </label>
      <div class="col-xs-8">
        <label class="text-blue3-xs margin-left-md-xs" *ngFor="let vote of votes">
          <input
            type="checkbox"
            value="{{ vote.value }}"
            formControlName="possibleVotes"
            [checked]="voteMap[vote.value]"
            (change)="updatePossibleVotes(vote.value)"
          />
          {{ vote.display }}
        </label>
      </div>
      <div
        class="col-xs-8 col-xs-offset-4"
        *ngIf="assemblyForm.controls.possibleVotes.errors && assemblyForm.controls.possibleVotes.dirty"
      >
        <div
          class="alert text-10-xs text-red2-xs margin-bottom-none-xs"
          *ngIf="assemblyForm.controls.possibleVotes.errors.required"
        >
          {{ 'admin.assemblies.form.errors.possibleVotes.required' | translate }}
        </div>
        <div
          class="alert text-10-xs text-red2-xs margin-bottom-none-xs"
          *ngIf="assemblyForm.controls.possibleVotes.errors.choices"
        >
          {{ 'admin.assemblies.form.errors.possibleVotes.choices' | translate }}
        </div>
      </div>
    </div>

    <h5 class="col-xs-12 margin-top-md-xs margin-bottom-sm-xs text-left text-blue3-xs text-bold">
      {{ 'admin.assemblies.form.labels.resolutions' | translate }}
    </h5>
    <div class="col-xs-12 form-group padding-left-sm-xs">
      <div class="col-xs-6 col-lg-4 padding-left-sm-xs padding-right-md-xs">
        <input
          class="col-xs-12"
          type="text"
          formControlName="resolution"
          placeholder="{{ 'admin.assemblies.form.labels.resolutions' | translate }}"
        />
        <div
          class="col-xs-12"
          *ngIf="assemblyForm.controls.resolution.errors && assemblyForm.controls.resolution.dirty"
        >
          <div
            class="alert text-10-xs text-red2-xs margin-bottom-none-xs"
            *ngIf="assemblyForm.controls.resolution.errors.required"
          >
            {{ 'admin.assemblies.form.errors.resolutions' | translate }}
          </div>
        </div>
        <a
          class="col-xs-6 btn btn-default margin-top-md-xs background-blue3-xs text-white-xs"
          (click)="addResolution()"
        >
          {{ 'admin.assemblies.form.labels.resolutionsAdd' | translate }}
        </a>
      </div>
      <ul class="col-xs-6 list-group padding-none-xs">
        <li class="col-xs-12 col-lg-8 list-group-item" *ngFor="let resolution of resolutions">
          <span class="col-xs-10">{{ resolution }}</span>
          <i
            class="col-xs-2 fa fa-times cursor-hand text-right"
            aria-hidden="true"
            (click)="deleteResolution(resolution)"
          ></i>
        </li>
      </ul>
    </div>

    <h5 class="col-xs-12 margin-top-md-xs margin-bottom-sm-xs text-left text-blue3-xs text-bold">
      {{ 'admin.assemblies.form.labels.documents' | translate }}
    </h5>
    <div class="col-xs-12 from-group padding-left-sm-xs">
      <label
        for="order-of-the-day"
        class="btn btn-default col-xs-4 col-md-3 col-lg-2 background-blue3-xs text-white-xs"
      >
        {{ 'admin.assemblies.form.labels.orderOfTheDay' | translate }}
      </label>
      <input
        id="order-of-the-day"
        type="file"
        (change)="uploadFile('orderOfTheDay', $event)"
        class="hidden-xs hidden-sm hidden-md hidden-lg"
        formControlName="orderOfTheDay"
      />
      <span
        class="col-xs-8 col-md-6 text-left margin-top-xs-xs"
        [ngClass]="{
          'text-red1-xs': assemblyForm.controls.orderOfTheDay.errors !== null
        }"
      >
        {{ orderOfTheDayMessage }}
      </span>
    </div>
    <div class="col-xs-12 from-group padding-left-sm-xs">
      <label for="central-book" class="btn btn-default col-xs-4 col-md-3 col-lg-2 background-blue3-xs text-white-xs">
        {{ 'admin.assemblies.form.labels.centralBook' | translate }}
      </label>
      <input
        id="central-book"
        type="file"
        (change)="uploadFile('centralBook', $event)"
        class="hidden-xs hidden-sm hidden-md hidden-lg"
        formControlName="centralBook"
      />
      <span
        class="col-xs-8 col-lg-6 text-left margin-top-xs-xs"
        [ngClass]="{
          'text-red1-xs': assemblyForm.controls.centralBook.errors !== null
        }"
      >
        {{ centralBookMessage }}
      </span>
    </div>
    <div class="col-xs-12 from-group padding-left-sm-xs">
      <label for="entry-form" class="btn btn-default col-xs-4 col-md-3 col-lg-2 background-blue3-xs text-white-xs">
        {{ 'admin.assemblies.form.labels.entryForm' | translate }}
      </label>
      <input
        id="entry-form"
        type="file"
        (change)="uploadFile('entryForm', $event)"
        class="hidden-xs hidden-sm hidden-md hidden-lg"
        formControlName="entryForm"
      />
      <span
        class="col-xs-8 col-lg-6 text-left margin-top-xs-xs"
        [ngClass]="{
          'text-red1-xs': assemblyForm.controls.entryForm.errors !== null
        }"
      >
        {{ entryFormMessage }}
      </span>
    </div>
    <div class="col-xs-12 from-group padding-left-sm-xs">
      <label for="ballot" class="btn btn-default col-xs-4 col-md-3 col-lg-2 background-blue3-xs text-white-xs">
        {{ 'admin.assemblies.form.labels.ballot' | translate }}
      </label>
      <input
        id="ballot"
        type="file"
        (change)="uploadFile('ballot', $event)"
        class="hidden-xs hidden-sm hidden-md hidden-lg"
        formControlName="ballot"
      />
      <span
        class="col-xs-8 col-lg-6 text-left margin-top-xs-xs"
        [ngClass]="{
          'text-red1-xs': assemblyForm.controls.ballot.errors !== null
        }"
      >
        {{ ballotMessage }}
      </span>
    </div>
    <div class="col-xs-12 from-group padding-left-sm-xs">
      <label for="voting-rules" class="btn btn-default col-xs-4 col-md-3 col-lg-2 background-blue3-xs text-white-xs">
        {{ 'admin.assemblies.form.labels.centralBook' | translate }}
      </label>
      <input
        id="voting-rules"
        type="file"
        (change)="uploadFile('votingRules', $event)"
        class="hidden-xs hidden-sm hidden-md hidden-lg"
        formControlName="votingRules"
      />
      <span
        class="col-xs-8 col-lg-6 text-left margin-top-xs-xs"
        [ngClass]="{
          'text-red1-xs': assemblyForm.controls.votingRules.errors !== null
        }"
      >
        {{ votingRulesMessage }}
      </span>
    </div>
    <div class="col-xs-12 margin-top-md-xs">
      <button
        type="submit"
        [disabled]="!assemblyForm.valid"
        class="col-xs-2 col-xs-offset-5 btn btn-default background-blue3-xs text-white-xs"
      >
        {{ 'admin.assemblies.form.submit' | translate }}
      </button>
    </div>
  </form>
</div>
