import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Beneficiary } from '@core/models/beneficiary.model';
import { Destroyed } from '@core/services/destroyed.service';
import { BeneficiaryDivisionValidatorDirective } from '@core/validators/beneficiary-division-validator';
import { SubscriptionService } from '../../subscription.service';
import { MandatesService } from '../mandates.service';
import { NewBeneficiaryComponent } from '../new-beneficiary/new-beneficiary.component';

@Component({
  selector: 'n9-new-mandate',
  templateUrl: './new-mandate.component.html',
  styleUrls: ['./new-mandate.component.scss']
})
export class NewMandateComponent extends Destroyed implements OnInit, OnChanges {
  @Input() parentForm: FormGroup;
  @Input() beneficiariesOnStart: Beneficiary[];
  @Input() priorityOnStart: string[];
  @Input() validatorsType: object;
  @Input() eventNewBeneficiary: Observable<number>;
  @Input() showErrors: boolean;

  @ViewChild('beneficiaries', { read: ViewContainerRef })
  beneficiaryContainer: ViewContainerRef;
  @ViewChild('addBeneficiaryButton') addBeneficiaryButton: ElementRef;

  tmpBeneficiaries: Beneficiary[] = [];
  dueDateMin: Date = new Date(1992, 3, 1);
  dueDateMax: Date = new Date();
  bsConfig: Partial<BsDatepickerConfig>;
  referentialTypes: object = {
    mairie: 'Mairie',
    epci: 'EPCI',
    departements: 'Conseil Départemental',
    regions: 'Conseil Régional / Territorial'
  };
  mandateTypes: object = {
    Mairie: ['Maire', 'Maire-adjoint(e)', 'Conseiller(ère)'],
    default: ['Président(e)', 'Vice-Président(e)', 'Conseiller(ère)'],
    borough: [
      'Maire',
      'Maire-adjoint(e)',
      'Conseiller(ère) Municipal(e)',
      'Maire d’arrondissement',
      'Adjoint(e) d’arrondissement',
      'Conseiller(ère) d’arrondissement'
    ]
  };
  isCityWithBorough: boolean = false;
  beneficiariesMap: object = {};

  constructor(
    private fb: FormBuilder,
    private subscriptionService: SubscriptionService,
    private mandatesService: MandatesService,
    private factoryResolver: ComponentFactoryResolver
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.beneficiariesOnStart && this.beneficiariesOnStart.length > 0) {
      this.tmpBeneficiaries = this.beneficiariesOnStart;
      this.retrieveBeneficiariesData();
    }
    if (this.priorityOnStart && this.priorityOnStart.length > 0) {
    }
    this.parentForm
      .get('isRetroactive')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((value: string) => {
        if (value === 'true') {
          this.addValidators('retroactivityType');
          this.addValidators('optinContribution');
        } else {
          this.removeValidators('retroactivityType');
          this.removeValidators('optinContribution');
        }
      });

    if (this.parentForm.get('isRetroactive').value === 'true') {
      this.addValidators('retroactivityType');
      this.addValidators('optinContribution');
    } else {
      this.removeValidators('retroactivityType');
      this.removeValidators('optinContribution');
    }

    this.onInCaseOfDeathChange(this.parentForm.get('inCaseOfDeath').value);
    this.parentForm
      .get('inCaseOfDeath')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((value: string) => {
        this.onInCaseOfDeathChange(value);
      });

    this.parentForm
      .get('beneficiariesDivision.divisionType')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((value: string) => {
        this.onDivisionTypeChange(value);
      });

    this.eventNewBeneficiary.pipe(takeUntil(this.destroyed$)).subscribe((i) => {
      this.createNewBeneficiary();
    });

    this.parentForm
      .get('beneficiaries')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((beneficiaries: unknown[]) => {
        (beneficiaries.length >= 2 ? this.addValidators : this.removeValidators).bind(this)(
          'beneficiariesDivision.beneficiaryPriorityOrder'
        );
      });

    if (this.parentForm.get('collectivityName').value && this.parentForm.get('collectivityName').value !== '') {
      this.onCollectivityNameChange(this.parentForm.get('collectivityName').value);
    }
    this.priorityOnStart = [];
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.showErrors && changes.showErrors.currentValue) {
      this.markFormGroupDirty(this.parentForm);
    }
  }

  public trackByFn(index: number, item: string): number {
    if (!item) return null;
    return index;
  }

  public upperCaseFirst(str: string): void {
    const control: AbstractControl = this.parentForm.get(str);

    control.setValue(control.value.toString().charAt(0).toUpperCase() + control.value.toString().slice(1));
  }

  public formatControlValue(str: string): void {
    const control: AbstractControl = this.parentForm.get(str);

    if (control instanceof FormControl) control.setValue(control.value.toString().toUpperCase());
  }

  public onCollectivityTypeChange(event: Event): void {
    const value: string = (event.target as HTMLSelectElement).value;
    const collectivityName: AbstractControl = this.parentForm.get('collectivityName');

    if (value && value !== this.referentialTypes['mairie']) {
      this.mandatesService.getReferential(
        Object.keys(this.referentialTypes).find((k) => this.referentialTypes[k] === value)
      );
      collectivityName.setValidators(Validators.required);
    } else {
      collectivityName.setValidators(this.validatorsType['collectivityName']);
    }

    if (value === this.referentialTypes['epci']) {
      this.addValidators('epciName');
    } else {
      this.removeValidators('epciName');
    }

    collectivityName.setValue('');
    this.parentForm.get('type').setValue(null);
    collectivityName.updateValueAndValidity();
  }

  public onCollectivityNameChange(newValue: string): void {
    this.isCityWithBorough = new RegExp(/\b(PARIS|MARSEILLE|LYON)\b/, 'ig').test(newValue);
  }

  public onStaggeringMonthChange(event: Event): void {
    const value = parseInt((event.target as HTMLSelectElement).value, 10);

    if (value > 72) this.parentForm.get('staggeringMonth').setValue(72);
  }

  public createNewBeneficiary(): void {
    if ((this.parentForm.get('beneficiaries') as FormArray).length > 9) return;

    const factory = this.factoryResolver.resolveComponentFactory(NewBeneficiaryComponent);
    const component = factory.create(this.beneficiaryContainer.injector);

    const timestamps = new Date().getTime();

    this.beneficiariesMap[timestamps] = {
      id: timestamps,
      index: (this.parentForm.get('beneficiaries') as FormArray).length + 1
    };

    component.instance.infos = this.beneficiariesMap[timestamps];
    component.instance.delete.subscribe((id) => this.removeBeneficiaryFromId(id));

    this.beneficiaryContainer.insert(component.hostView);
    (this.parentForm.get('beneficiaries') as FormArray).push(component.instance.newBeneficiaryForm);

    if (this.beneficiaryContainer.length >= 2) {
      this.addValidators('beneficiariesDivision');
    } else {
      this.removeValidators('beneficiariesDivision');
    }

    if (this.parentForm.get('inCaseOfDeath').value !== 'Désignation notariale') this.removeValidatorsNotary();

    this.onDivisionTypeChange(this.parentForm.get('beneficiariesDivision.divisionType').value);
    this.addBeneficiaryButton.nativeElement.blur();
  }

  public removeBeneficiaryFromId(id: number): void {
    if (!this.beneficiariesMap[id]) return;

    this.removeBeneficiary(this.beneficiariesMap[id].index);

    delete this.beneficiariesMap[id];

    this.updateBeneficiariesIndexes();
  }

  public removeBeneficiary(index: number): void {
    this.removePriority(index);

    this.beneficiaryContainer.remove(index - 1);
    (this.parentForm.get('beneficiaries') as FormArray).removeAt(index - 1);

    if (this.beneficiaryContainer.length < 2) {
      this.removeValidators('beneficiariesDivision');
    }

    this.onDivisionTypeChange(this.parentForm.get('beneficiariesDivision.divisionType').value);
  }

  public updateBeneficiariesIndexes(): void {
    Object.keys(this.beneficiariesMap).forEach((k, i) => {
      this.beneficiariesMap[k].index = i + 1;
    });
  }

  public storeBeneficiariesData(): void {
    this.removeValidators('beneficiaries');
    this.removeValidators('beneficiariesDivision');
    this.tmpBeneficiaries = this.parentForm.getRawValue()['beneficiaries'];

    if (this.beneficiaryContainer) {
      for (let i: number = this.beneficiaryContainer.length; i > 0; i--) {
        this.removeBeneficiary(i);
      }
      Object.keys(this.beneficiariesMap).forEach((k) => {
        delete this.beneficiariesMap[k];
      });
    }

    this.removePriority();
  }

  public addPriority(validators: any, value?: string): void {
    const po: FormArray = this.parentForm.get('beneficiariesDivision.beneficiaryPriorityOrder') as FormArray;

    if (!value) {
      po.push(
        this.fb.group({
          priority: ['', validators]
        })
      );
    } else {
      po.push(
        this.fb.group({
          priority: [value, validators]
        })
      );
    }
  }

  public removePriority(index: number = -1): void {
    const po: FormArray = this.parentForm.get('beneficiariesDivision.beneficiaryPriorityOrder') as FormArray;

    if (index >= 0) {
      index = index - 1;
      po.removeAt(index);
    } else {
      po.controls = [];
      po.setValidators([]);
      po.reset([], { emitEvent: false });
    }

    this.parentForm
      .get('beneficiariesDivision.beneficiaryPriorityOrder')
      .updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.parentForm.updateValueAndValidity({
      onlySelf: true,
      emitEvent: false
    });
  }

  public resetPriorityValues(): void {
    this.parentForm.get('beneficiariesDivision.beneficiaryPriorityOrder').reset();
  }

  public retrieveBeneficiariesData(): void {
    // tslint:disable-next-line:prefer-for-of
    for (let i: number = 0; i < this.tmpBeneficiaries.length; i++) {
      this.createNewBeneficiary();
      if (this.tmpBeneficiaries[i].birthDate) {
        this.tmpBeneficiaries[i].birthDate = new Date(this.tmpBeneficiaries[i].birthDate);
      }
    }

    if (this.beneficiaryContainer.length >= 2) {
      this.addValidators('beneficiariesDivision');
    }

    this.parentForm.get('beneficiaries').patchValue(this.tmpBeneficiaries);
    this.tmpBeneficiaries = [];
  }

  public addValidators(fb: string): void {
    const c: AbstractControl = this.parentForm.get(fb);

    if (c instanceof FormControl) {
      const t: string[] = fb.split('.');

      c.setValidators(this.validatorsType[t[t.length - 1]]);
      c.updateValueAndValidity();
      return;
    }
    // tslint:disable-next-line:forin
    for (const key in (this.parentForm.get(fb) as FormGroup).controls) {
      const control = this.parentForm.get(fb).get(key);

      control.setValidators(this.validatorsType[key]);
      control.updateValueAndValidity();
    }
  }

  public removeValidators(fb: string): void {
    const control: AbstractControl = this.parentForm.get(fb);

    control.clearValidators();
    control.updateValueAndValidity();

    if (control instanceof FormGroup) this.removeFormGroupValidators(control as FormGroup);
    else if (control instanceof FormArray) this.removeFormArrayValidators(control as FormArray);
  }

  private removeFormGroupValidators(fg: FormGroup): void {
    // tslint:disable-next-line:forin
    for (const key in fg.controls) {
      const control = fg.get(key);

      control.clearValidators();
      control.updateValueAndValidity();

      if (control instanceof FormGroup) {
        this.removeFormGroupValidators(control as FormGroup);
      } else if (control instanceof FormArray) {
        this.removeFormArrayValidators(control as FormArray);
      }
    }
  }

  private removeFormArrayValidators(formArray: FormArray): void {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < formArray.controls.length; i++) {
      formArray.controls[i].clearValidators();
      formArray.controls[i].updateValueAndValidity();

      if (formArray.controls[i] instanceof FormGroup) {
        this.removeFormGroupValidators(formArray.controls[i] as FormGroup);
      } else if (formArray.controls[i] instanceof FormArray) {
        this.removeFormArrayValidators(formArray.controls[i] as FormArray);
      }
    }
  }

  private addValidatorsNotary(): void {
    this.addValidators('beneficiariesDivision.notaryLastname');
    this.addValidators('beneficiariesDivision.notaryFirstname');
    this.addValidators('beneficiariesDivision.notaryAddress');
    this.addValidators('beneficiariesDivision.notaryPhone');
  }

  private removeValidatorsNotary(): void {
    this.removeValidators('beneficiariesDivision.notaryLastname');
    this.removeValidators('beneficiariesDivision.notaryFirstname');
    this.removeValidators('beneficiariesDivision.notaryAddress');
    this.removeValidators('beneficiariesDivision.notaryPhone');
  }

  private markFormGroupDirty(formGroup: FormGroup): void {
    (Object as any).values(formGroup.controls).forEach((control) => {
      control.markAsDirty();

      if (control.controls) {
        this.markFormGroupDirty(control);
      }
    });
  }

  private onInCaseOfDeathChange(value: string): void {
    const order: FormArray = this.parentForm.get('beneficiariesDivision.beneficiaryPriorityOrder') as FormArray;

    if (value === 'Désignation expresse') {
      this.retrieveBeneficiariesData();

      if (!this.beneficiaryContainer.length) this.createNewBeneficiary();

      if (this.beneficiaryContainer.length >= 2) {
        const divisionType = this.parentForm.get('beneficiariesDivision.divisionType').value;
        order.setValidators([Validators.required, BeneficiaryDivisionValidatorDirective.validInput(divisionType)]);
      } else {
        order.clearValidators();
      }
    } else {
      this.storeBeneficiariesData();
      order.clearValidators();
    }

    if (value === 'Désignation notariale') {
      this.addValidatorsNotary();
    } else {
      this.removeValidatorsNotary();
    }
  }

  private onDivisionTypeChange(value: string): void {
    if (this.parentForm.get('beneficiaries').value.length < 2) {
      return;
    }

    const order: FormArray = this.parentForm.get('beneficiariesDivision.beneficiaryPriorityOrder') as FormArray;

    if (value === 'equalShares') {
      this.removePriority();
      order.setValidators([Validators.required]);
    } else {
      order.setValidators([Validators.required, BeneficiaryDivisionValidatorDirective.validInput(value)]);

      for (let i = order.controls.length; i < this.beneficiaryContainer.length; i++) {
        if (this.priorityOnStart && this.priorityOnStart[i]) {
          this.addPriority([Validators.required], this.priorityOnStart[i]['priority']);
        } else {
          this.addPriority([Validators.required]);
        }
      }
      order.updateValueAndValidity();
    }
  }
}
