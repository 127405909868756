import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataStatus } from '@core/models/data-status';
import { Contract, User } from '@core/models';
import { RequestService } from '@shared/services/request.service';
import { SessionService } from '@core/session/session.service';
import { HomeNavigationService } from '@core/services/navigation.service';
import { USER_REQUEST_TYPE } from '@core/models/request.model';

@Component({
  selector: 'n9-retroactivity-form',
  templateUrl: 'retroactivity-form.component.html'
})
export class RetroactivityFormComponent implements OnInit {
  public user: User;
  public contract: Contract;

  public retroactivityForm: FormGroup;
  public dataSent: DataStatus;
  public ds: typeof DataStatus = DataStatus;
  public minDate: Date = new Date(1992, 3, 1);
  public maxDate: Date = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private navigation: HomeNavigationService,
    private requestService: RequestService
  ) {
    this.retroactivityForm = this.formBuilder.group({
      retroactivityDate: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.user = this.sessionService.getUser();
    const route: string[] = this.navigation.getRoute().split('/');
    const contractNumber: number = parseInt(route[route.length - 3], 10);
    this.contract = this.user.contracts.find((contract) => contract.number === contractNumber);

    this.maxDate = this.contract.retroactivityDate
      ? new Date(this.contract.retroactivityDate)
      : new Date(this.contract.subscriptionDate);
    this.retroactivityForm.setValue({
      retroactivityDate: this.contract.retroactivityDate ? new Date(this.contract.retroactivityDate) : null
    });
    this.dataSent = DataStatus.WAITING;
    this.retroactivityForm.updateValueAndValidity();
  }

  public onSubmit(event: any): void {
    if (this.retroactivityForm.valid) {
      this.dataSent = DataStatus.PENDING;

      this.requestService
        .contractRetroactivityDateRequest(this.contract.number, this.retroactivityForm.value)
        .take(1)
        .subscribe(
          () => {
            this.dataSent = DataStatus.SUCCESS;
            this.navigation.navigateToRoute(
              `/home/contracts/${this.contract.number}/signature/${USER_REQUEST_TYPE.CONTRACT_RETROACTIVITY_DATE}`,
              null
            );
          },
          (err) => {
            if (err.status === 409) {
              this.dataSent = DataStatus.FORBIDDEN;
            } else {
              this.dataSent = DataStatus.ERROR;
            }
          }
        );
    }
  }
}
