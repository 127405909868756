import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { MembershipStatus } from '@core/models/membership-status.model';
import { RequestService } from '@shared/services/request.service';
import { SubscriptionService } from '../subscription.service';
import { USER_REQUEST_TYPE } from '@core/models/request.model';
import { HomeNavigationService } from '@core/services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class SignatureService {
  signUrl: SafeResourceUrl;
  damId: string;
  stepNumber: number = 3;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private subscriptionService: SubscriptionService,
    private requestService: RequestService,
    private navigation: HomeNavigationService
  ) {}

  startSignature(): Observable<any> {
    return this.http
      .post('/signature', {
        membershipMarketingId: localStorage.getItem('membershipMarketingId')
      })
      .map((response) => {
        this.signUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response['url']);
      })
      .catch((error) => Observable.throwError(error));
  }

  startUserRequestSignature(requestToken: number, requestType: string, eluNumber: string): Observable<any> {
    return this.http
      .post('/signature/user-request', {
        token: requestToken,
        userRequestType: requestType,
        eluNumber
      })
      .map((response) => {
        this.signUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response['url']);
      })
      .catch((error) => Observable.throwError(error));
  }

  public downloadSignedFile(fileId: string): Observable<any> {
    return this.http.get(`/signature/documents/${fileId}/download/`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  checkSignatureComplete(isMembership: boolean, isContract?: boolean): void {
    setTimeout(
      () =>
        this.subscriptionService.getSubscriberValues().subscribe(
          (response) => {
            switch (response['status']) {
              case MembershipStatus.SIGNATURE_REFUSED:
                localStorage.setItem('step', '0');
                if (!isContract) {
                  this.subscriptionService.goToStep(0);
                } else {
                  this.navigation.navigateToRoute(`/home/contracts`, null);
                }
                return;
              case MembershipStatus.SIGNATURE_COMPLETE:
                const marketingId = localStorage.getItem('membershipMarketingId');

                this.damId = response['signatureInformations']['damId'];

                if (!isContract) {
                  setTimeout(() => this.subscriptionService.goForward(this.stepNumber), 10000);
                } else {
                  this.navigation.navigateToRoute(`/home/contracts`, null);
                }

                return;
              default:
                this.checkSignatureComplete(isMembership);
            }
          },
          (error) => Observable.throwError(error)
        ),
      3000
    );
  }
}
