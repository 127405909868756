import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { SessionService } from '@core/session/session.service';
import { Credentials } from '../../login/login.models';

import { LoginService } from '../../login/login.service';

@Component({
  selector: 'n9-admin-login',
  templateUrl: 'admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  public loginForm: FormGroup;
  public errorMessage: string;
  isRoleInsufficient: boolean;

  constructor(
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private loginService: LoginService
  ) {
    this.loginForm = this.formBuilder.group({
      login: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    if (this.sessionService.getSession()) {
      this.router.navigate(['/admin/home']);
    }

    this.route.queryParams.subscribe((params) => {
      this.isRoleInsufficient = params['role'];
    });
  }

  onSubmit(): void {
    if (!this.loginForm.valid) return;

    const credentials = this.loginForm.getRawValue() as Credentials;
    this.loginService.login(Object.assign(credentials, { origin: 'BO' })).subscribe(
      () => this.router.navigate(['/admin/home']),
      (error) => {
        if (error.status === 401) this.errorMessage = 'Mauvaise combinaison identifiant/mot de passe';
        else if (error.status === 404)
          this.errorMessage = "Le compte auquel vous essayez de vous connecter n'existe pas";
        else this.errorMessage = 'Une erreur est survenue, veuillez reesayer plus tard';
      }
    );
  }
}
