import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BirthDateValidatorDirective } from '@core/validators/birth-date-validator.directive';
import { LatinAlphabeticValidatorDirective } from '@core/validators/latin-alphabetic-validator.directive';
import { LatinAlphanumericValidatorDirective } from '@core/validators/latin-alphanumeric-validator.directive';
import { PhoneValidatorDirective } from '@core/validators/phone-validator.directive';
import { SiretValidatorDirective } from '@core/validators/siret-validator.directive';
import { ZipCodeValidatorDirective } from '@core/validators/zip-code-validator.directive';

@Component({
  selector: 'n9-new-beneficiary',
  templateUrl: './new-beneficiary.component.html',
  styleUrls: ['./new-beneficiary.component.scss']
})
export class NewBeneficiaryComponent {
  @Input() infos: { index: number; id: number };
  @Output() delete: EventEmitter<number> = new EventEmitter<number>();
  newBeneficiaryForm: FormGroup;
  minDate: Date = new Date(1900, 0, 1);
  maxDate: Date = new Date();
  displayCivility: boolean;

  constructor(private fb: FormBuilder) {
    this.newBeneficiaryForm = this.fb.group({
      company: [false, Validators.required],
      companyName: ['', []],
      siret: ['', []],
      civility: ['', [Validators.required]],
      lastname: ['', [Validators.required, LatinAlphabeticValidatorDirective.validInput]],
      firstname: ['', [Validators.required, LatinAlphabeticValidatorDirective.validInput]],
      birthDate: ['', [Validators.required, BirthDateValidatorDirective.validDate]],
      address: this.fb.group({
        address: ['', [Validators.required, LatinAlphanumericValidatorDirective.validInput]],
        complement: ['', [LatinAlphanumericValidatorDirective.validInput]],
        postalCode: ['', [Validators.required, ZipCodeValidatorDirective.validInput]],
        city: ['', [Validators.required, LatinAlphanumericValidatorDirective.validInput]]
      }),
      phone: ['', PhoneValidatorDirective.validInput]
    });

    this.displayCivility = true;
    this.newBeneficiaryForm.get('company').valueChanges.subscribe((value: boolean) => {
      if (value) {
        this.newBeneficiaryForm.get('companyName').setValidators([Validators.required]);
        this.newBeneficiaryForm.get('siret').setValidators([SiretValidatorDirective.validInput]);
        this.newBeneficiaryForm.get('civility').setValue('');
        this.removeValidators('lastname');
        this.removeValidators('firstname');
        this.removeValidators('civility');
        this.removeValidators('birthDate');
        this.newBeneficiaryForm.get('lastname').setValue(null);
        this.newBeneficiaryForm.get('firstname').setValue(null);
        this.newBeneficiaryForm.get('civility').setValue(null);
        this.newBeneficiaryForm.get('birthDate').setValue(null);
        this.displayCivility = false;
      } else {
        this.displayCivility = true;
        this.newBeneficiaryForm
          .get('lastname')
          .setValidators([Validators.required, LatinAlphabeticValidatorDirective.validInput]);
        this.newBeneficiaryForm
          .get('firstname')
          .setValidators([Validators.required, LatinAlphabeticValidatorDirective.validInput]);
        this.newBeneficiaryForm.get('civility').setValidators([Validators.required]);
        this.newBeneficiaryForm
          .get('birthDate')
          .setValidators([Validators.required, BirthDateValidatorDirective.validDate]);
        this.removeValidators('companyName');
        this.removeValidators('siret');
      }
    });
  }

  formatControlValue(str: string): void {
    const control: AbstractControl = this.newBeneficiaryForm.get(str);

    if (control instanceof FormControl) control.setValue(control.value.toString().toUpperCase());
  }

  public selfDelete(): void {
    this.delete.emit(this.infos.id);
  }

  private removeValidators(fb: string): void {
    const control = this.newBeneficiaryForm.get(fb);

    if (control instanceof FormControl) {
      control.clearValidators();
      control.updateValueAndValidity();
    }
  }
}
