/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/components/loader/loader.component.ngfactory";
import * as i2 from "../../../../shared/components/loader/loader.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "./contract-signature.component";
import * as i6 from "../../../../core/services/navigation.service";
import * as i7 from "../../../subscription/signature/signature.service";
import * as i8 from "../../../../core/session/session.service";
import * as i9 from "../../../subscription/subscription.service";
var styles_ContractSignatureComponent = [];
var RenderType_ContractSignatureComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContractSignatureComponent, data: {} });
export { RenderType_ContractSignatureComponent as RenderType_ContractSignatureComponent };
function View_ContractSignatureComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "n9-loader", [["class", "spinner-border margin-top-md-xs margin-bottom-md-xs"], ["role", "status"]], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.LoaderComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Loading..."]))], null, null); }
function View_ContractSignatureComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "iframe", [["class", "border-none-xs"], ["height", "1315px"], ["name", "signature"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.signatureService.signUrl; _ck(_v, 0, 0, currVal_0); }); }
export function View_ContractSignatureComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "col-xs-12 padding-left-sm-xs padding-right-sm-xs padding-left-lg-sm padding-right-lg-sm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "p", [["class", "col-xs-12 padding-none-xs margin-top-md-xs margin-bottom-sm-xs dosis-bold text-18-xs text-24-sm text-left text-bold text-blue3-xs"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 7, "div", [["class", "col-xs-12 padding-none-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["class", "col-xs-12 padding-md-xs background-white-xs border-grey3-xs-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "section", [["class", "col-xs-12 background-grey2-xs border-blue5-xs-xs margin-top-md-md margin-top-sm-xs margin-bottom-lg-md margin-bottom-md-xs padding-none-xs padding-sm-sm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["id", "yousign-box"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContractSignatureComponent_1)), i0.ɵdid(9, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContractSignatureComponent_2)), i0.ɵdid(11, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.signatureService.signUrl; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.signatureService.signUrl; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("home.contracts.signature.title")); _ck(_v, 2, 0, currVal_0); }); }
export function View_ContractSignatureComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "n9-contract-signature", [], null, null, null, View_ContractSignatureComponent_0, RenderType_ContractSignatureComponent)), i0.ɵdid(1, 114688, null, 0, i5.ContractSignatureComponent, [i6.HomeNavigationService, i7.SignatureService, i8.SessionService, i9.SubscriptionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContractSignatureComponentNgFactory = i0.ɵccf("n9-contract-signature", i5.ContractSignatureComponent, View_ContractSignatureComponent_Host_0, {}, {}, []);
export { ContractSignatureComponentNgFactory as ContractSignatureComponentNgFactory };
