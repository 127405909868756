import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { User } from '@core/models';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'n9-user-list',
  templateUrl: 'user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  users$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

  constructor(private userService: UserService) {}

  public ngOnInit(): void {
    this.userService.getUsers(0, 10).subscribe(
      (res) => {
        this.users$.next(res['content']);
      },
      (err) => Observable.throwError(err)
    );
  }
}
