<header class="container-fluid padding-none-xs">
  <!-- XS devices header -->
  <section
    class="col-xs-12 xs-header height-50-xs hidden-sm hidden-md hidden-lg padding-none-xs background-white-xs border-bottom-grey3-xs-xs"
  >
    <div class="logo col-xs-4">
      <img
        src="../../../../assets/img/logo.png"
        (click)="navigateToContracts()"
        class="margin-top-sm-xs cursor-hand"
        style="height: 80%"
      />
    </div>
    <div dropdown class="col-xs-2 col-xs-offset-6 height-100p-xs padding-none-xs text-center">
      <i
        dropdownToggle
        id="button-basic"
        class="fa fa-bars text-blue3-xs text-20-xs burger-button"
        aria-hidden="true"
        aria-controls="dropdown-basic"
        (click)="displayOrHideMenu($event)"
      >
      </i>
      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
        <li *ngFor="let feature of features" role="menuitem">
          <a class="dropdown-item" (click)="navigation.selectFeature(feature.name)">{{ feature.label | translate }}</a>
        </li>
      </ul>
    </div>
  </section>

  <!-- MD and lG devices header -->
  <section class="col-xs-12 height-100-sm hidden-xs padding-none-xs background-white-xs border-bottom-grey3-xs-xs">
    <div
      class="logo col-xs-3 padding-left-sm-xs padding-right-sm-xs text-center cursor-hand"
      (click)="navigateToContracts()"
    >
      <img src="../../../../assets/img/logo.png" class="margin-top-md-xs" width="80%" height="80%" />
    </div>
  </section>
</header>
