/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./reception.component";
import * as i3 from "@angular/common/http";
import * as i4 from "../../../../core/session/session.service";
var styles_ReceptionComponent = [];
var RenderType_ReceptionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ReceptionComponent, data: {} });
export { RenderType_ReceptionComponent as RenderType_ReceptionComponent };
export function View_ReceptionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "container-fluid margin-top-md-xs margin-bottom-md-xs background-white-xs border-xs-grey6-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h4", [["class", "col-xs-12 margin-top-sm-xs text-center text-bold text-blue3-xs"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " ", ", ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "welcome-panel col-xs-12 padding-top-md-xs padding-bottom-md-xs margin-top-md-xs text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.manager.firstname; var currVal_1 = _co.manager.lastname; var currVal_2 = i0.ɵunv(_v, 2, 2, i0.ɵnov(_v, 3).transform("admin.reception.title")); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("admin.reception.welcome")); _ck(_v, 6, 0, currVal_3); }); }
export function View_ReceptionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "n9-admin-reception", [], null, null, null, View_ReceptionComponent_0, RenderType_ReceptionComponent)), i0.ɵdid(1, 49152, null, 0, i2.ReceptionComponent, [i3.HttpClient, i4.SessionService], null, null)], null, null); }
var ReceptionComponentNgFactory = i0.ɵccf("n9-admin-reception", i2.ReceptionComponent, View_ReceptionComponent_Host_0, {}, {}, []);
export { ReceptionComponentNgFactory as ReceptionComponentNgFactory };
