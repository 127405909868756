<div>
  <span class="openSans-bold"
    >{{ 'admin.membership.detail.mandates.infos.beneficiaryClause' | translate }}
    :
  </span>
  <span class="openSans-regular">{{ mandate.inCaseOfDeath }}</span>
</div>

<div class="col-xs-12 padding-none-xs margin-top-xs-xs" *ngIf="mandate?.inCaseOfDeath === 'Régime général'">
  <p class="text-italic text-14-xs">
    {{ 'subscription.mandates.form.inCaseOfDeath.successor.tooltip' | translate }}
  </p>
</div>

<div class="col-xs-12 padding-none-xs margin-top-sm-xs" *ngIf="mandate?.inCaseOfDeath === 'Désignation expresse'">
  <p class="dosis-bold text-blue3-xs text-18-xs margin-bottom-md-xs">
    {{ 'admin.membership.detail.mandates.infos.beneficiary.title' | translate }}
  </p>

  <div *ngIf="mandate.beneficiaries && mandate.beneficiaries.length > 0; else noBeneficiary">
    <p *ngIf="mandate.beneficiariesDivision.divisionType">
      <span class="openSans-bold">Type de division entre bénéficiaires : </span>
      <span>{{
        'admin.membership.detail.mandates.infos.beneficiariesDivision.' + mandate.beneficiariesDivision.divisionType
          | translate
      }}</span>
    </p>

    <div
      *ngFor="let beneficiary of mandate.beneficiaries; let i = index"
      class="col-xs-12 padding-none-xs margin-bottom-md-xs"
    >
      <p class="dosis-bold text-blue3-xs text-14-xs padding-bottom-sm-xs border-bottom-grey3-xs-xs">
        {{ 'admin.membership.detail.mandates.infos.beneficiary.number' | translate }}{{ i + 1 }}
      </p>
      <p *ngIf="beneficiary.lastname">
        <span class="openSans-bold">{{ 'subscription.subscriber.form.lastname' | translate }} : </span>
        <span>{{ beneficiary.lastname }}</span>
      </p>
      <p *ngIf="beneficiary.firstname">
        <span class="openSans-bold"
          >{{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.new.firstname' | translate }}
          :
        </span>
        <span>{{ beneficiary.firstname }}</span>
      </p>
      <p *ngIf="beneficiary.birthDate">
        <span class="openSans-bold"
          >{{ 'subscription.subscriber.form.birthDate' | translate }}
          :
        </span>
        <span>{{ beneficiary.birthDate | date: 'd MMMM yyyy' }}</span>
      </p>

      <p *ngIf="beneficiary.companyName">
        <span class="openSans-bold"
          >{{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.new.companyName' | translate }}
          :
        </span>
        <span>{{ beneficiary.companyName }}</span>
      </p>
      <p *ngIf="beneficiary.siret">
        <span class="openSans-bold"
          >{{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.new.siret' | translate }}
          :
        </span>
        <span>{{ beneficiary.siret }}</span>
      </p>
      <p *ngIf="beneficiary.phone">
        <span class="openSans-bold"
          >{{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.new.phone' | translate }}
          :
        </span>
        <span>{{ beneficiary.phone }}</span>
      </p>

      <p class="openSans-bold">
        {{ 'admin.membership.detail.general.infos.address' | translate }}
        :
      </p>

      <div class="col-xs-12 padding-left-lg-xs">
        <p>
          <span class="openSans-bold"
            >{{ 'subscription.subscriber.form.address' | translate }}
            :
          </span>
          <span>{{ beneficiary.address.address }}</span>
        </p>
        <p>
          <span class="openSans-bold"
            >{{ 'subscription.subscriber.form.postalCode' | translate }}
            :
          </span>
          <span>{{ beneficiary.address.postalCode }}</span>
        </p>
        <p>
          <span class="openSans-bold">{{ 'subscription.subscriber.form.city' | translate }} : </span>
          <span>{{ beneficiary.address.city }}</span>
        </p>
        <p>
          <span class="openSans-bold"
            >{{ 'subscription.subscriber.form.complement' | translate }}
            :
          </span>
          <span>{{ beneficiary.address.complement ? beneficiary.address.complement : 'N/A' }}</span>
        </p>
      </div>

      <div
        *ngIf="
          mandate.beneficiariesDivision &&
          mandate.beneficiariesDivision.beneficiaryPriorityOrder &&
          mandate.beneficiariesDivision.beneficiaryPriorityOrder.length > 1
        "
      >
        <p *ngIf="mandate.beneficiariesDivision.divisionType === 'perPercent'; else priorityOrder">
          <span class="openSans-bold">Part : </span>
          <span>{{ mandate.beneficiariesDivision.beneficiaryPriorityOrder[i] }}%</span>
        </p>
        <ng-template #priorityOrder>
          <span class="openSans-bold">Priorité : </span>
          <span>{{ mandate.beneficiariesDivision.beneficiaryPriorityOrder[i] }}</span>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-template #noBeneficiary>
    <p class="text-italic text-14-xs">Le souscripteur n'a pas renseigné de bénéficiaire</p>
  </ng-template>
</div>

<div
  *ngIf="
    mandate?.beneficiariesDivision &&
    mandate?.beneficiariesDivision.notary &&
    mandate?.inCaseOfDeath === 'Désignation notariale'
  "
  class="col-xs-12 padding-none-xs"
>
  <p class="dosis-bold text-blue3-xs text-18-xs padding-top-xs-xs">
    {{ 'admin.membership.detail.mandates.infos.notary-information' | translate }}
  </p>
  <p>
    <span class="openSans-bold">{{ 'subscription.subscriber.form.lastname' | translate }} : </span>
    <span>{{ mandate.beneficiariesDivision.notaryLastname }}</span>
  </p>
  <p>
    <span class="openSans-bold">{{ 'subscription.subscriber.form.firstnames.label' | translate }} : </span>
    <span>{{ mandate.beneficiariesDivision.notaryFirstname }}</span>
  </p>

  <p class="openSans-bold">{{ 'admin.membership.detail.general.infos.address' | translate }} :</p>

  <div class="col-xs-12 padding-left-lg-xs">
    <p>
      <span class="openSans-bold">{{ 'subscription.subscriber.form.address' | translate }} : </span>
      <span>{{ mandate.beneficiariesDivision.notaryAddress.address }}</span>
    </p>
    <p>
      <span class="openSans-bold">{{ 'subscription.subscriber.form.postalCode' | translate }} : </span>
      <span>{{ mandate.beneficiariesDivision.notaryAddress.postalCode }}</span>
    </p>
    <p>
      <span class="openSans-bold">{{ 'subscription.subscriber.form.city' | translate }} : </span>
      <span>{{ mandate.beneficiariesDivision.notaryAddress.city }}</span>
    </p>
    <p>
      <span class="openSans-bold">{{ 'subscription.subscriber.form.complement' | translate }} : </span>
      <span>{{
        mandate.beneficiariesDivision.notaryAddress.complement
          ? mandate.beneficiariesDivision.notaryAddress.complement
          : 'N/A'
      }}</span>
    </p>
  </div>
</div>
