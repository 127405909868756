<div class="col-xs-12 padding-xs-xs padding-sm-sm background-white-xs border-grey3-xs-xs">
  <form
    class="col-md-10 col-md-offset-1 col-sm-12 form openSans-regular text-left padding-left-xs-xs padding-right-xs-xs padding-left-sm-sm padding-right-sm-sm margin-top-md-xs"
    [formGroup]="contactForm"
    novalidate
    (ngSubmit)="onSubmit($event)"
  >
    <div class="col-xs-12">
      <span class="openSans-bold">{{ 'home.contact.form.memberId' | translate }} :</span>
      {{ user.eluNumber }}
    </div>

    <div class="col-xs-12 margin-top-lg-xs">
      <div class="col-xs-5 padding-none-xs">
        <label class="openSans-bold" for="mandate">{{ 'home.contact.form.mandate' | translate }} :</label>
        <select class="form-control" id="mandate" formControlName="mandateId">
          <option value="">
            {{ 'home.contact.form.select' | translate }}
          </option>
          <option *ngFor="let mandate of mandates" value="{{ mandate.number }}">
            {{ mandate.mandate }}
            {{ formatDate(mandate.subscriptionDate) }}
          </option>
        </select>
      </div>
      <div class="col-xs-offset-2 col-xs-5 padding-none-xs">
        <label class="openSans-bold" for="subject">{{ 'home.contact.form.subject' | translate }} :</label>
        <select class="form-control" id="subject" formControlName="subject">
          <option value="">
            {{ 'home.contact.form.select' | translate }}
          </option>
          <option *ngFor="let subject of subjects" value="{{ subject }}">
            {{ subject }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-xs-12 margin-top-md-xs">
      <label for="message" class="openSans-bold">{{ 'home.contact.form.message' | translate }} :</label>
      <textarea
        id="message"
        class="form-control resize-zone"
        minlength="1"
        maxlength="{{ MAX_CHAR }}"
        placeholder="{{ 'home.contact.form.placeholder' | translate }}"
        formControlName="content"
        required
      ></textarea>
    </div>

    <p class="col-xs-12 margin-top-xs-xs">
      {{
        MAX_CHAR - (this.contactForm.get('content').value ? this.contactForm.get('content').value.toString().length : 0)
      }}
      {{ 'home.contact.form.remainingCharacters' | translate }}
    </p>

    <div class="col-xs-12 margin-top-md-xs">
      <div class="col-xs-12 padding-none-xs">
        <p>
          <span class="openSans-bold">{{ 'home.contact.form.attachFiles' | translate }} :</span>
          <br />
          {{ 'home.contact.form.allowedFormats' | translate }}
        </p>
        <label
          for="file"
          class="background-grey2-xs cursor-hand padding-top-sm-xs padding-bottom-sm-xs padding-left-lg-xs padding-right-lg-xs"
        >
          {{ 'home.contact.form.browse' | translate }}
        </label>
        <input
          #document
          type="file"
          class="form-control hidden-xs hidden-sm hidden-md hidden-lg"
          id="file"
          accept="image/png,image/jpeg,.pdf"
          formControlName="firstFile"
          multiple
          (ngModelChange)="getFile(document.files)"
        />
        <ul class="" *ngIf="this.uploadedFiles.length > 0">
          <li class="" *ngFor="let file of this.uploadedFiles; let i = index">
            {{ file.name }}
            <span
              class="glyphicon glyphicon-remove text-red1-xs cursor-hand"
              (click)="deleteFile(i)"
              style="float: right"
            ></span>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-xs-12 margin-top-md-xs text-center">
      <label
        class="dosis-bold text-white-xs text-16-xs background-red1-xs text-center padding-top-sm-xs padding-bottom-sm-xs padding-left-lg-xs padding-right-lg-xs cursor-hand"
        for="submit"
        [ngClass]="!contactForm.valid ? 'disabled' : ''"
        >{{ 'home.contact.form.submit.button' | translate | uppercase }}</label
      >
      <input
        type="submit"
        id="submit"
        class="hidden-xs hidden-sm hidden-md hidden-lg"
        [disabled]="!contactForm.valid"
      />
    </div>

    <div *ngIf="this.isSubmitted" class="col-xs-12 text-center openSans-regular text-red1-xs">
      {{ 'home.contact.form.submit.' + (this.isSubmitted ? 'success' : 'error') | translate }}
    </div>
  </form>
</div>
