<div class="col-xs-12 padding-bottom-md-xs border-xs-grey3-xs background-white-xs">
  <p
    class="col-xs-12 padding-none-xs margin-top-sm-xs margin-bottom-sm-xs openSans-bold text-bold text-14-xs text-center"
  >
    Retraire complementaire CAREL
  </p>

  <div
    class="col-xs-12 padding-left-sm-xs padding-right-none-xs padding-top-sm-xs padding-bottom-sm-xs background-grey2-xs"
  >
    <div class="padding-none-xs">
      <img src="../../../../../assets/img/avatar.png" height="40px" width="40px" />
    </div>
    <div class="col-xs-10 padding-left-sm-xs padding-right-sm-xs">
      <span class="col-xs-12 padding-none-xs openSans-bold text-12-xs text-left text-blue3-xs">
        {{ 'home.contracts.list.card.mandate' | translate }}
      </span>
      <span class="col-xs-12 padding-none-xs openSans-semibold text-12-xs text-left text-blue3-xs">
        {{ mandate.type }}
      </span>
    </div>
  </div>
  <div class="col-xs-12 padding-none-xs">
    <div class="col-xs-12 padding-none-xs margin-top-xs-xs openSans-semibold text-left text-10-xs text-12-sm">
      <strong class="openSans-bold">{{ 'home.contracts.list.card.collectivity' | translate }}</strong>
      {{ mandate.collectivityName }}
    </div>
    <div class="col-xs-12 padding-none-xs margin-top-xs-xs openSans-semibold text-left text-10-xs text-12-sm">
      <strong class="openSans-bold">{{ 'home.contracts.list.card.subscriptionDate' | translate }}</strong>
      {{ startDate }} au {{ endDate }}
    </div>
  </div>
</div>
