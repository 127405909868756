import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { Notification, NotificationType } from '@core/models/notification.model';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'n9-login-notifications',
  templateUrl: './login-notifications.component.html',
  styleUrls: ['./login-notifications.component.scss']
})
export class LoginNotificationsComponent implements OnInit {
  @ViewChild('notificationModal') notificationModal: ModalDirective;
  isModalDisplayed: boolean = false;
  nbPages: number;
  nbElements: number;
  loginNotifications: Notification[];
  page: number = 0;

  constructor(private notificationService: NotificationService) {}

  public ngOnInit(): void {
    this.getNotificationsByType(NotificationType.LOGIN, this.page, 1);
  }

  public getNotificationsByType(type: string, page: number, size: number): void {
    this.notificationService
      .getNotificationsByType(type as NotificationType, page, size)
      .take(1)
      .subscribe(
        (notifications: object) => {
          this.nbPages = notifications['nbPages'];
          this.nbElements = notifications['nbElements'];
          this.loginNotifications = notifications['content'] as Notification[];

          if (this.nbElements > 0 && !this.isModalDisplayed) this.toggleModal();
        },
        (err) => Observable.throwError(err)
      );
  }

  public removeRecipientFromNotification(id: string): void {
    this.notificationService
      .removeRecipientFromNotification(id)
      .take(1)
      .subscribe(
        (notification: object) => {},
        (err) => Observable.throwError(err)
      );
  }

  public removeUserFromAllNotifications(): void {
    this.notificationService
      .getNotificationsByType(NotificationType.LOGIN, 0, this.nbElements)
      .take(1)
      .subscribe(
        (pageable: object) => {
          const notifications: Notification[] = pageable['content'];

          for (const n of notifications) this.removeRecipientFromNotification(n.id);
        },
        (err) => Observable.throwError(err)
      );
  }

  public toggleModal(): void {
    this.isModalDisplayed = !this.isModalDisplayed;
  }

  public setPage(page: number): void {
    this.page = page;
  }
}
