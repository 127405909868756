/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/router";
import * as i3 from "./retroactivity.component";
var styles_RetroactivityComponent = [];
var RenderType_RetroactivityComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RetroactivityComponent, data: {} });
export { RenderType_RetroactivityComponent as RenderType_RetroactivityComponent };
export function View_RetroactivityComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "col-xs-12 padding-left-sm-xs padding-right-sm-xs padding-left-lg-sm padding-right-lg-sm"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "p", [["class", "col-xs-12 padding-none-xs margin-top-md-xs margin-bottom-sm-xs dosis-bold text-18-xs text-24-sm text-left text-bold text-blue3-xs"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "col-xs-12 padding-none-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "col-xs-12 padding-md-xs background-white-xs border-grey3-xs-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(7, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 7, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("home.contracts.retroactivity.title")); _ck(_v, 2, 0, currVal_0); }); }
export function View_RetroactivityComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "n9-retroactivity", [], null, null, null, View_RetroactivityComponent_0, RenderType_RetroactivityComponent)), i0.ɵdid(1, 49152, null, 0, i3.RetroactivityComponent, [], null, null)], null, null); }
var RetroactivityComponentNgFactory = i0.ɵccf("n9-retroactivity", i3.RetroactivityComponent, View_RetroactivityComponent_Host_0, {}, {}, []);
export { RetroactivityComponentNgFactory as RetroactivityComponentNgFactory };
