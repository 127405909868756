import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { MembershipMandate } from '@core/models/membership-mandate.model';
import { Membership } from '@core/models/membership.model';
import { MembershipService } from '../membership.service';
import { MandateService } from '../../..//../home/contracts/shared';
import { saveAs } from 'file-saver';

@Component({
  selector: 'n9-membership-detail',
  templateUrl: './membership-detail.component.html',
  styleUrls: ['./membership-detail.component.scss']
})
export class MembershipDetailComponent implements OnInit {
  membership: Membership;
  mandates$: BehaviorSubject<MembershipMandate[]> = new BehaviorSubject<MembershipMandate[]>([]);
  uploadedFiles: File[] = [];

  images: string[] = [];

  constructor(
    private translate: TranslateService,
    private membershipService: MembershipService,
    private mandatesService: MandateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((param) =>
      this.membershipService.getMembershipByMarketingId(param['id']).subscribe((membership: Membership) => {
        this.membership = membership;
        this.mandates$.next(this.membership.mandates);

        if (membership.identityDocumentIds && membership.identityDocumentIds.length > 0) {
          membership.identityDocumentIds.forEach((id: string, index: number) =>
            this.mandatesService.downloadFilewithDamId(id).subscribe(
              (res) => {
                res.body.name = res.headers.get('x-filename');
                this.uploadedFiles.push(res.body as File);
                this.toBase64(res.body, index);
              },
              (err) => {}
            )
          );
        }
      })
    );
  }

  public validateMembership(): void {
    this.membershipService.updateMembershipStatus(this.membership, 'MEMBERSHIP_COMPLETE').subscribe(() => {});
  }

  public toBase64(file: File, index: number): void {
    const fileReader: FileReader = new FileReader();

    fileReader.onload = (e: any) => {
      this.images[index] = e.target.result;
    };
    fileReader.readAsDataURL(file);
  }

  public saveFile(index: number): void {
    saveAs(
      this.uploadedFiles[index],
      this.membership.subscriber.lastname +
        '_' +
        this.membership.subscriber.firstnames +
        '-' +
        this.uploadedFiles[index].name
    );
  }
}
