import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MembershipService } from '../membership.service';
import { MembershipStatus } from '@core/models/membership-status.model';
import { MandateService } from '../../../../home/contracts/shared';
import { saveAs } from 'file-saver';
import { Membership } from '@core/models/membership.model';
import { MembershipStatusFilter } from '@core/models/membership-status-filter.model';

@Component({
  selector: 'n9-membership-list',
  templateUrl: './membership-list.component.html',
  styleUrls: ['./membership-list.component.scss']
})
export class MembershipListComponent implements OnInit {
  page: number;
  size: number;
  nbElements: number = 0;
  pendingMemberships: object[];
  currentStatus: MembershipStatusFilter;
  status: typeof MembershipStatusFilter;
  keys: any;

  constructor(
    private membershipService: MembershipService,
    private mandateService: MandateService,
    private route: ActivatedRoute
  ) {
    this.size = 25;
    this.status = MembershipStatusFilter;
    this.currentStatus = MembershipStatusFilter.SIGNATURE_COMPLETE;
    this.keys = Object.keys;

    // Necessary to refresh data on membership validation (see membership-detail)
    route.params.subscribe((params) => {
      this.resetData();
      this.getAllMemberships(MembershipStatusFilter.SIGNATURE_COMPLETE);
    });
  }

  ngOnInit(): void {}

  public resetData(): void {
    this.nbElements = 0;
    this.pendingMemberships = null;
  }

  public getAllMemberships(status: MembershipStatusFilter, event?: any): void {
    this.currentStatus = status;
    this.pendingMemberships = [];
    this.page = event ? event.page - 1 : 0;

    if (status !== MembershipStatusFilter.ALL) {
      this.membershipService.getMembershipsByStatus(status, this.page, this.size).subscribe((memberships: object[]) => {
        if (memberships['nbElements'] > 0) {
          this.nbElements = memberships['nbElements'];
          this.pendingMemberships = memberships['content'].map((m) => {
            m['createdDateTime'] = new Date(m['createdDateTime']);
            m['lastModifiedDateTime'] = new Date(m['lastModifiedDateTime']);

            return m;
          });
        } else this.resetData();
      });
    } else {
      this.membershipService.getAllMemberships(this.page, this.size).subscribe((memberships: object[]) => {
        if (memberships['nbElements'] > 0) {
          this.nbElements = memberships['nbElements'];
          this.pendingMemberships = memberships['content'].map((m) => {
            m['createdDateTime'] = new Date(m['createdDateTime']);
            m['lastModifiedDateTime'] = new Date(m['lastModifiedDateTime']);

            return m;
          });
        } else this.resetData();
      });
    }
  }

  public downloadDocummentWithId(fileId: string, membership: any): void {
    this.mandateService.downloadFilewithDamId(fileId).subscribe(
      (res) => {
        saveAs(res.body, res.headers.get('x-filename'));
        if (membership.status !== MembershipStatus.MEMBERSHIP_TREATED) {
          this.membershipService.updateMembershipStatus(membership, MembershipStatus.MEMBERSHIP_TREATED).subscribe();
        }
      },
      (err) => {
        if (membership.status !== MembershipStatus.MEMBERSHIP_TREATED) {
          this.membershipService.updateMembershipStatus(membership, MembershipStatus.MEMBERSHIP_TREATED).subscribe();
        }
        alert('An error occured');
      }
    );
  }
}
