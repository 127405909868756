import { Component, Input } from '@angular/core';

@Component({
  selector: 'n9-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  @Input() set size(size: number) {
    this.styles['width.px'] = size;
    this.styles['height.px'] = size;
  }

  @Input() set thickness(thickness: number) {
    this.styles['border-width.px'] = thickness;
    this.styles['border-radius.px'] = thickness * 4;
  }

  @Input() set color(color: string) {
    this.styles['color'] = color;
  }

  public styles: { [attribute: string]: unknown } = {
    'width.px': 50,
    'height.px': 50,
    'border-width.px': 5,
    'border-radius.px': 25,
    color: '#005b9c'
  };
}
