<div class="container-fluid padding-none-xs dosis-semibold text-16-xs">
  <ul class="col-xs-12 padding-none-xs margin-none-xs">
    <li *ngFor="let feature of features" [ngClass]="feature.condition !== false ? '' : 'hide'">
      <n9-sidebar-element
        *ngIf="feature.condition !== false"
        [element]="feature"
        (selected)="onSelection(features, $event)"
      >
      </n9-sidebar-element>
    </li>
  </ul>
</div>
