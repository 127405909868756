import { LocalStorageStoreService } from './local-storage-store.service';
import { StoreService } from './store.service';

const storeFactory = (): StoreService => {
  return new LocalStorageStoreService();
};

// tslint:disable-next-line:variable-name
export const StoreProvider = {
  provide: StoreService,
  useFactory: storeFactory
};
