/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./personal-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./personal-data-form/personal-data-form.component.ngfactory";
import * as i5 from "./personal-data-form/personal-data-form.component";
import * as i6 from "@angular/forms";
import * as i7 from "../../../shared/services/request.service";
import * as i8 from "./personal-data.component";
import * as i9 from "../../../core/session/session.service";
import * as i10 from "../../../core/services/navigation.service";
var styles_PersonalDataComponent = [i0.styles];
var RenderType_PersonalDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PersonalDataComponent, data: {} });
export { RenderType_PersonalDataComponent as RenderType_PersonalDataComponent };
function View_PersonalDataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [["class", "openSans-bold margin-bottom-sm-xs text-center text-16-xs"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "openSans-bold margin-bottom-sm-xs text-center text-16-xs"; var currVal_1 = ((_co.dataStatus == _co.ds.PENDING) ? "text-grey4-xs" : ((_co.dataStatus == _co.ds.SUCCESS) ? "text-green2-xs" : "text-red1-xs")); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(("home.personalData.form.submit." + ((_co.dataStatus == _co.ds.PENDING) ? "pending" : ((_co.dataStatus == _co.ds.SUCCESS) ? "success" : ((_co.dataStatus == _co.ds.FORBIDDEN) ? "errorDuplicate" : "error")))))); _ck(_v, 2, 0, currVal_2); }); }
export function View_PersonalDataComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "col-xs-12 padding-left-sm-xs padding-right-sm-xs padding-left-lg-sm padding-right-lg-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "col-xs-12 padding-none-xs margin-top-md-xs margin-bottom-sm-xs dosis-bold text-18-xs text-24-sm text-left text-bold text-blue3-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "col-xs-12 padding-none-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "col-xs-12 padding-left-xs-xs padding-right-xs-xs padding-left-sm-sm padding-right-sm-sm padding-bottom-sm-xs padding-top-sm-xs background-white-xs border-grey3-xs-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "n9-personal-data-form", [], null, [[null, "doneEditing"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("doneEditing" === en)) {
        var pd_0 = (_co.doneEditing($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_PersonalDataFormComponent_0, i4.RenderType_PersonalDataFormComponent)), i1.ɵdid(7, 114688, null, 0, i5.PersonalDataFormComponent, [i6.FormBuilder, i7.RequestService, i1.ChangeDetectorRef], { user: [0, "user"] }, { doneEditing: "doneEditing" }), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "info col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonalDataComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.user; _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.dataStatus !== _co.ds.WAITING); _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("home.personalData.title")); _ck(_v, 2, 0, currVal_0); }); }
export function View_PersonalDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-personal-data", [], null, null, null, View_PersonalDataComponent_0, RenderType_PersonalDataComponent)), i1.ɵdid(1, 49152, null, 0, i8.PersonalDataComponent, [i9.SessionService, i10.HomeNavigationService], null, null)], null, null); }
var PersonalDataComponentNgFactory = i1.ɵccf("n9-personal-data", i8.PersonalDataComponent, View_PersonalDataComponent_Host_0, {}, {}, []);
export { PersonalDataComponentNgFactory as PersonalDataComponentNgFactory };
