import { PublicConf } from './public-conf.models';

// tslint:disable-next-line:no-var-requires
const pkg = require('../../package.json');

export default {
  maintenance: true,
  public: {
    production: false,
    app: {
      name: pkg.name,
      version: pkg.version
    },
    api: {
      baseHref: '',
      serverHost: '/api',
      ignoredRoutes: [
        // '^/dist/assets/.*$',
        '^/assets/.*$',
        '^http://.*',
        '^https://.*'
      ]
    }
  } as PublicConf
};
