import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { PageableResult, User } from '@core/models';
import { UserRequest, USER_REQUEST_STATUS } from '@core/models/request.model';

@Injectable()
export class HistoryService {
  constructor(private http: HttpClient) {}

  public getUsersRequestsFromManager(managerId: string, page: number = 0, size: number = 10): Observable<any> {
    return this.http
      .get(`/request/manager/${managerId}?page=${page}&size=${size}`)
      .take(1)
      .map((r) => r)
      .catch((err) => Observable.throwError(err));
  }

  public searchUsersRequestsFromManager(
    managerId: string,
    type: string,
    status: string,
    page: number = 0,
    size: number = 10
  ): Observable<PageableResult<UserRequest>> {
    return this.http
      .post<PageableResult<UserRequest>>(`/request/manager/search?page=${page}&size=${size}`, {
        gestionnaireId: managerId,
        type,
        status
      })
      .pipe(
        take(1),
        catchError((err) => Observable.throwError(err))
      );
  }

  public getAllManagers(): Observable<User[]> {
    return this.http
      .get(`/users/managers`)
      .take(1)
      .map((managers: User[]) => managers)
      .catch((err) => Observable.throwError(err));
  }

  public editRequestStatus(id: string, state: USER_REQUEST_STATUS): Observable<any> {
    return this.http
      .post(`/request/edit/${id}`, { state })
      .take(1)
      .map((request) => request)
      .catch((err) => Observable.throwError(err));
  }
}
