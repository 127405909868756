import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlphabeticValidatorDirective } from '@core/validators/alphabetic-validator.directive';
import { AlphanumericValidatorDirective } from '@core/validators/alphanumeric-validator.directive';
import { BeneficiaryDivisionValidatorDirective } from '@core/validators/beneficiary-division-validator';
import { BirthDateValidatorDirective } from '@core/validators/birth-date-validator.directive';
import { CityValidatorDirective } from '@core/validators/city-validator.directive';
import { DateAfterNowValidatorDirective } from '@core/validators/date-after-now-validator.directive';
import { DateBeforeNowValidatorDirective } from '@core/validators/date-before-now-validator.directive';
import { EmailValidatorDirective } from '@core/validators/email-validator.directive';
import { FileValidatorDirective } from '@core/validators/file-validator.directive';
import { FirstnamesValidatorDirective } from '@core/validators/firstnames-validator.directive';
import { LatinAlphabeticValidatorDirective } from '@core/validators/latin-alphabetic-validator.directive';
import { LatinAlphanumericValidatorDirective } from '@core/validators/latin-alphanumeric-validator.directive';
import { MobilePhoneValidatorDirective } from '@core/validators/mobile-phone-validator.directive';
import { PasswordValidatorDirective } from '@core/validators/password-validator.directive';
import { PhoneValidatorDirective } from '@core/validators/phone-validator.directive';
import { SiretValidatorDirective } from '@core/validators/siret-validator.directive';
import { ZipCodeValidatorDirective } from '@core/validators/zip-code-validator.directive';
import { BeneficiaryClauseComponent } from '@shared/components/beneficiary-clause/beneficiary-clause.component';
import { FormDatepickerComponent } from '@shared/components/form-inputs/form-datepicker/form-datepicker.component';
import { MandateInfosComponent } from '@shared/components/mandate-infos/mandate-infos.component';
import { SharedModule } from '@shared/shared.module';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { MandatesComponent } from './mandates/mandates.component';
import { NewBeneficiaryComponent } from './mandates/new-beneficiary/new-beneficiary.component';
import { NewMandateComponent } from './mandates/new-mandate/new-mandate.component';
import { PreambleComponent } from './preamble/preamble.component';
import { SignatureComponent } from './signature/signature.component';
import { SubscriberComponent } from './subscriber/subscriber.component';
import { SubscriptionRoutingModule } from './subscription-routing.module';
import { SubscriptionComponent } from './subscription.component';

@NgModule({
  imports: [
    CollapseModule.forRoot(),
    CommonModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    SharedModule,
    SubscriptionRoutingModule
  ],
  declarations: [
    MandatesComponent,
    MandateInfosComponent,
    SubscriptionComponent,
    SubscriberComponent,
    NewMandateComponent,
    SignatureComponent,
    ConfirmationComponent,
    NewBeneficiaryComponent,
    FormDatepickerComponent,
    BeneficiaryClauseComponent,
    PreambleComponent,
    AlphabeticValidatorDirective,
    AlphanumericValidatorDirective,
    PhoneValidatorDirective,
    SiretValidatorDirective,
    ZipCodeValidatorDirective,
    FirstnamesValidatorDirective,
    CityValidatorDirective,
    LatinAlphanumericValidatorDirective,
    LatinAlphabeticValidatorDirective,
    MobilePhoneValidatorDirective,
    BeneficiaryDivisionValidatorDirective,
    BirthDateValidatorDirective,
    DateAfterNowValidatorDirective,
    FileValidatorDirective,
    DateBeforeNowValidatorDirective,
    PasswordValidatorDirective
  ],
  exports: [
    SubscriptionComponent,
    SubscriptionRoutingModule,
    MandateInfosComponent,
    NewBeneficiaryComponent,
    BeneficiaryClauseComponent,
    FormDatepickerComponent,
    PreambleComponent
  ],
  entryComponents: [NewBeneficiaryComponent, NewMandateComponent],
  providers: [
    AlphabeticValidatorDirective,
    AlphanumericValidatorDirective,
    EmailValidatorDirective,
    PhoneValidatorDirective,
    FirstnamesValidatorDirective,
    ZipCodeValidatorDirective,
    CityValidatorDirective
  ]
})
export class SubscriptionModule {}
