import { HttpClient } from '@angular/common/http';
import { SUBJECT, THREAD_STATUS } from '@core/models/request.model';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export var FILTER_TYPE;
(function (FILTER_TYPE) {
    FILTER_TYPE["ELU_NUMBER"] = "eluNumber";
    FILTER_TYPE["OBJECT_ID"] = "objectId";
    FILTER_TYPE["CREATED_DATE_TIME"] = "createdDateTime";
})(FILTER_TYPE || (FILTER_TYPE = {}));
export class MessageThreadsService {
    constructor(http) {
        this.http = http;
        this.selectedSubject = new BehaviorSubject(SUBJECT.ALL);
        this.selectedStatus = new BehaviorSubject(THREAD_STATUS.ALL);
        this.sortStatus = new BehaviorSubject([]);
        this.selectedSubject$ = this.selectedSubject.asObservable();
        this.selectedStatus$ = this.selectedStatus.asObservable();
        this.sortStatus$ = this.sortStatus.asObservable();
    }
    getMessagesList(page = 0, size = 200) {
        const object = this.selectedSubject.getValue() !== SUBJECT.ALL ? `/object/${this.selectedSubject.getValue()}` : '';
        const status = this.selectedStatus.getValue() !== THREAD_STATUS.ALL ? `/status/${this.selectedStatus.getValue()}` : '';
        return this.http.get(`/threads${object}${status}?page=${page}&size=${size}`);
    }
    changeThreadStatus(threadId, body) {
        return this.http.put(`/threads/` + threadId + '/state', body);
    }
    getMessagesFromThreadId(threadId, page, size) {
        return this.http.get('/threads/' + threadId + '?page=' + page + '&size=' + size);
    }
    postMessage(body) {
        return this.http.post('/messages', body);
    }
    postMessageToThread(threadId, body) {
        return this.http.post('/threads/message/' + threadId, body);
    }
    uploadImageFile(file) {
        return this.http.post(`/documents/create/as-byte-array/BASE?name=${file.name}&label=${file.name}&content-type=${file.type}`, file);
    }
    downloadImageFile(id, type = 'BASE') {
        return this.http.get(`/documents/download/${id}/type/${type}`, {
            responseType: 'blob',
            observe: 'response'
        });
    }
    getAllManagers() {
        return this.http
            .get(`/users/managers`)
            .map((managers) => managers)
            .catch((err) => Observable.throwError(err));
    }
    setSubjectFilter(selectedSubject) {
        this.selectedSubject.next(selectedSubject);
    }
    setStatusFilter(selectedStatus) {
        this.selectedStatus.next(selectedStatus);
    }
    updateSort(type, order) {
        const value = this.sortStatus.getValue().filter((sortStatus) => sortStatus.type !== type);
        value.unshift({ type, order });
        this.sortStatus.next(value);
    }
}
MessageThreadsService.ngInjectableDef = i0.defineInjectable({ factory: function MessageThreadsService_Factory() { return new MessageThreadsService(i0.inject(i1.HttpClient)); }, token: MessageThreadsService, providedIn: "root" });
