<div
  id="subscription-container"
  class="background-grey2-xs padding-left-lg-md padding-left-none-xs padding-right-lg-md padding-right-none-xs padding-top-lg-xs"
>
  <div class="col-lg-10 col-xs-12">
    <p
      class="col-xs-12 dosis-bold text-24-sm text-20-xs text-bold"
      *ngIf="subscriptionService.getSelectedStep() != 0"
      [ngClass]="
        isMembership
          ? 'padding-none-xs text-blue3-xs'
          : 'margin-bottom-none-xs padding-sm-xs text-white-xs text-center background-blue3-xs'
      "
    >
      {{ 'subscription.common.subscription' | translate }}
      <span *ngIf="!isProduction" (click)="flushCache()" class="openSans-regular text-14-xs cursor-hand">
        - Réinitialiser le formulaire</span
      >
    </p>
    <p
      class="col-xs-12 dosis-bold text-24-xs text-bold margin-bottom-none-xs padding-sm-xs text-white-xs text-center background-blue3-xs"
      *ngIf="subscriptionService.getSelectedStep() == 0"
      style="height: 54px"
    ></p>

    <div *ngIf="!isMembership" class="col-xs-1 square background-blue4-xs" id="left-square"></div>
    <div
      class="container-fluid subscription-panel padding-none-xs margin-bottom-lg-xs background-white-xs border-grey3-xs-xs"
      [ngStyle]="{ width: !isMembership ? 'calc(100% - 2 * 42px)' : '' }"
      [ngClass]="!isMembership ? 'col-xs-10' : 'col-xs-12'"
    >
      <ul
        id="steps-container"
        class="col-xs-12 col-sm-10 col-sm-offset-1 col-lg-8 col-lg-offset-2 text-center margin-top-lg-xs margin-bottom-lg-xs"
        *ngIf="subscriptionService.getSelectedStep() != 0"
      >
        <li *ngFor="let step of labels; let i = index" class="step-container col-sm-2 padding-none-xs">
          <div [ngClass]="i === 0 ? 'background-white-xs' : 'background-blue3-xs'" class="line step-element"></div>
          <div
            [ngClass]="
              i < subscriptionService.getSelectedStep()
                ? 'background-blue3-xs text-white-xs'
                : 'background-white-xs text-blue3-xs'
            "
            class="step-bullet step-element openSans-Bold"
          >
            <span class="dosis-bold">{{ i + 1 }}</span>
          </div>
          <div
            [ngClass]="i === labels.length - 1 ? 'background-white-xs' : 'background-blue3-xs'"
            class="line step-element"
          ></div>
          <p class="dosis-bold step-element text-center text-18-sm text-12-xs text-blue3-xs">
            {{ labels[i] | translate }}
          </p>
        </li>
      </ul>

      <div class="col-xs-12" [ngSwitch]="subscriptionService.getSelectedStep()">
        <n9-preamble *ngSwitchCase="0" [isMembership]="isMembership"></n9-preamble>
        <n9-subscriber *ngSwitchCase="1" [isMembership]="isMembership"></n9-subscriber>
        <n9-mandates *ngSwitchCase="2" [isMembership]="isMembership"></n9-mandates>
        <n9-signature *ngSwitchCase="3" [isMembership]="isMembership"></n9-signature>
        <n9-confirmation *ngSwitchCase="4" [isMembership]="isMembership"></n9-confirmation>
      </div>
    </div>
    <div *ngIf="!isMembership" class="col-xs-1 background-blue4-xs square" id="right-square"></div>
  </div>
</div>
