import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Page, User, Thread } from '@core/models';
import { ThreadService } from '@shared/services/thread.service';
import { UserService } from '@shared/services/user.service';
import { HomeNavigationService } from '@core/services/navigation.service';

@Component({
  selector: 'n9-new-message',
  templateUrl: 'message-new.component.html'
})
export class MessageNewComponent {
  public messageForm: FormGroup;
  public users: Page<User>;
  public selectedUsers: User[];

  constructor(
    private formBuilder: FormBuilder,
    private threadService: ThreadService,
    private userService: UserService,
    private navigation: HomeNavigationService
  ) {
    this.selectedUsers = [];
    this.messageForm = this.formBuilder.group({
      subject: ['', [Validators.required]],
      content: ['', [Validators.required, Validators.maxLength(3000)]],
      recipient: ['', []]
    });

    this.getUsers(1);
  }

  public onSubmit(): void {
    if (this.messageForm.valid) {
      const thread: Thread = {
        id: null,
        subject: this.messageForm.get('subject').value,
        users: this.selectedUsers,
        messages: [this.messageForm.getRawValue()]
      };

      /*this.threadService.createThread(thread)
					.subscribe((res) => this.navigation.navigate('threads'),
							(error) => this.handleError(error));*/
    }
  }

  public addRecipient(): void {
    const tmp: User = this.users.content.find((user) => user.id === this.messageForm.get('recipient').value);

    if (tmp != null) {
      const userIndex: number = this.selectedUsers.findIndex((user) => user.id === tmp.id);

      if (userIndex < 0) this.selectedUsers.push(tmp);
    }
  }

  public removeRecipient(id: string): void {
    const index: number = this.selectedUsers.findIndex((user: User) => user.id === id);

    if (index >= 0) {
      if (index === 0) this.selectedUsers.splice(index, index + 1);
      else this.selectedUsers.splice(index, index);
    }
  }

  public getUsers(page: number): void {
    this.userService.getUsers(page, 10).subscribe(
      (res) => {
        this.users = res;
        const firstUser = this.users.content[0];
        this.messageForm.get('recipient').setValue(firstUser.id);
      },
      (error) => this.handleError(error)
    );
  }

  private handleError(error: any): void {
    if (error.status) {
      if (error.status === 403 || error.status === 401) {
        this.navigation.navigateToLogin();
        return;
      }

      if (error.status === 400) {
        return;
      }
    }

    this.navigation.navigate('threads');
  }
}
