import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../services/conf/configuration.service';
import { noop } from '../utils/misc.util';
import { SessionService } from './session.service';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private sessionService: SessionService, private router: Router, private conf: ConfigurationService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Mock only
    if (this.sessionService.getUser()) return true;

    this.router.navigate(['login']).then(noop);
    return false;
  }
}
