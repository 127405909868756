export class Message {
  content?: string;
  isCarelSender?: boolean;
  idSender?: string;
  createdDateTime?: Date;
  lastModifiedDateTime?: Date;
  attachedFileIds?: string[];
  attachedFileNames?: string[];
  sameAuthor?: boolean;
  dateDiff?: boolean;

  constructor(copy: Message) {
    this.content = copy.content;
    this.isCarelSender = copy.isCarelSender;
    this.idSender = copy.idSender;
    this.createdDateTime = copy.createdDateTime;
    this.lastModifiedDateTime = copy.createdDateTime;
    this.attachedFileIds = copy.attachedFileIds;
    this.attachedFileNames = copy.attachedFileNames;
    this.sameAuthor = copy.sameAuthor;
    this.dateDiff = copy.dateDiff;
  }
}
