import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { InputElement } from '@core/models/input-element.model';

@Component({
  selector: 'n9-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputPhoneComponent implements OnInit {
  @Input() prefixData: InputElement<string>[];

  public shortPrefixData: InputElement<string>[];
  public currentData: InputElement<string>[];

  public get control(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  constructor(private readonly controlContainer: ControlContainer) {
    // Default prefix data
    this.prefixData = [
      { label: "Veuillez choisir un l'indicatif national de la France", value: '' },
      { label: 'France', value: '+33' },
      { label: 'Guadeloupe', value: '+590' },
      { label: 'Guyane', value: '+594' },
      { label: 'La Réunion', value: '+262' },
      { label: 'Martinique', value: '+596' },
      { label: 'Mayotte', value: '+262' },
      { label: 'Saint-Barthélemy', value: '+590' },
      { label: 'Saint-Martin', value: '+590' }
    ].map((data: InputElement<string>) => {
      data.label = `${data.label} (${data.value})`;
      return data;
    });
  }

  public ngOnInit(): void {
    // Copies prefixData object and map label to value
    // This list is used when the input is blurred
    this.shortPrefixData = JSON.parse(JSON.stringify(this.prefixData)).map((data: InputElement<string>) => {
      data.label = data.value;
      return data;
    });
    this.currentData = this.shortPrefixData;
  }
}
