<div class="col-xs-12 background-white-xs border-xs-grey6-xs margin-top-lg-xs margin-bottom-lg-xs">
  <h4 class="col-xs-12 margin-top-md-xs text-left text-blue3-xs">Nouveau fil de discussion</h4>
  <form
    novalidate
    [formGroup]="messageForm"
    (ngSubmit)="onSubmit()"
    class="col-xs-12 form padding-none-xs margin-top-md-xs"
  >
    <div class="col-xs-12 form-group">
      <label class="col-xs-12 text-left text-blue3-xs" for="subject">Sujet du fil</label>
      <input class="col-xs-6" type="text" id="subject" formControlName="subject" />
    </div>
    <div class="col-xs-12 form-group margin-top-sm-xs">
      <div class="col-xs-8 padding-none-xs">
        <div class="col-xs-12 padding-none-xs">
          <div class="col-xs-6 padding-none-xs" *ngIf="users">
            <select class="col-xs-10 padding-none-xs" formControlName="recipient">
              <option *ngFor="let user of users.content" value="{{ user.id }}">
                {{ user.firstname }} {{ user.lastname }} ({{ user.email }})
              </option>
            </select>
            <n9-pagination
              class="col-xs-12 padding-none-xs"
              [maxPages]="users.totalPages"
              (nextPage)="getUsers($event)"
            >
            </n9-pagination>
          </div>
          <ul class="padding-left-sm-xs col-xs-6">
            <li *ngFor="let user of selectedUsers">
              {{ user.firstname }} {{ user.lastname }} ({{ user.email }})
              <i class="fa fa-times margin-left-xs-xs" aria-hidden="true" (click)="removeRecipient(user.id)"></i>
            </li>
          </ul>
        </div>
        <a class="col-xs-4 btn btn-default margin-top-md-xs background-blue3-xs text-white-xs" (click)="addRecipient()">
          Ajouter un destinataire
        </a>
      </div>
    </div>

    <div class="col-xs-12 form-group">
      <label class="col-xs-12 text-left text-blue3-xs">Le message que vous souhaitez envoyer</label>
      <textarea
        class="col-xs-12 min-height-100-xs"
        n9Autosize
        placeholder="Entrez votre message"
        formControlName="content"
      >
      </textarea>
    </div>
    <div class="col-xs-12 form-group">
      <button
        type="submit"
        [disabled]="!messageForm.valid"
        class="btn btn-default col-xs-2 col-xs-offset-5 background-blue3-xs text-white-xs text-uppercase"
      >
        Creer
      </button>
    </div>
  </form>
</div>
