<div class="container-fluid margin-top-md-xs margin-bottom-md-xs background-white-xs border-xs-grey6-xs">
  <h4 class="col-xs-12 margin-top-sm-xs text-center text-bold text-blue3-xs">
    {{ this.manager.firstname }} {{ this.manager.lastname }},
    {{ 'admin.reception.title' | translate }}
  </h4>
  <div class="welcome-panel col-xs-12 padding-top-md-xs padding-bottom-md-xs margin-top-md-xs text-center">
    <p>{{ 'admin.reception.welcome' | translate }}</p>
  </div>

  <!-- Temporary button to update managers -->
  <!--<button *ngIf="!isProduction" (click)="updateManagers()" class="">Update managers</button>-->
</div>
