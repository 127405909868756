/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./data-summary.component";
import * as i3 from "@angular/common";
var styles_DataSummaryComponent = [];
var RenderType_DataSummaryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DataSummaryComponent, data: {} });
export { RenderType_DataSummaryComponent as RenderType_DataSummaryComponent };
function View_DataSummaryComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "p", [["class", "openSans-bold"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "openSans-regular"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(((_co.i18n + ".") + _co.keys[_v.parent.context.index]))); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit; _ck(_v, 5, 0, currVal_1); }); }
function View_DataSummaryComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "padding-left-md-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "n9-data-summary", [], null, null, null, View_DataSummaryComponent_0, RenderType_DataSummaryComponent)), i0.ɵdid(2, 114688, null, 0, i2.DataSummaryComponent, [], { data: [0, "data"], i18n: [1, "i18n"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; var currVal_1 = ((_co.i18n + ".") + _co.keys[_v.parent.context.index]); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_DataSummaryComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DataSummaryComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["recursive", 2]], null, 0, null, View_DataSummaryComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.typeof(_v.context.$implicit) !== "object"); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_DataSummaryComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DataSummaryComponent_1)), i0.ɵdid(1, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.values; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DataSummaryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "n9-data-summary", [], null, null, null, View_DataSummaryComponent_0, RenderType_DataSummaryComponent)), i0.ɵdid(1, 114688, null, 0, i2.DataSummaryComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DataSummaryComponentNgFactory = i0.ɵccf("n9-data-summary", i2.DataSummaryComponent, View_DataSummaryComponent_Host_0, { data: "data", i18n: "i18n" }, {}, []);
export { DataSummaryComponentNgFactory as DataSummaryComponentNgFactory };
