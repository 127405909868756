<div id="mandates" class="row">
  <div *ngIf="this.mandates$ | async; else noMandate">
    <div
      *ngFor="let mandate of this.mandates$ | async; let i = index"
      class="col-xs-12 mandate-container border-grey3-xs-xs padding-md-xs openSans-regular margin-bottom-md-xs"
      (click)="isCollapsed[i] = !isCollapsed[i]"
      [attr.aria-expanded]="!isCollapsed[i]"
    >
      <div>
        <span class="openSans-bold margin-bottom-xs-xs">{{
          'subscription.mandates.form.newMandate.title' | translate
        }}</span>
        <button
          *ngIf="this.isAlterable"
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="onAlterClick($event, i, 'delete')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="col-xs-12 padding-none-xs" [ngClass]="!isCollapsed[i] ? 'margin-bottom-md-xs' : ''">
        <div class="col-sm-4 col-xs-12 padding-md-xs background-grey2-xs" style="display: flex">
          <img src="../../../../assets/img/avatar.png" class="margin-right-md-xs" height="42px" width="35px" />

          <div class="text-blue3-xs" style="display: flex; flex-direction: column">
            <p class="openSans-bold margin-bottom-xs-xs">
              {{ 'subscription.mandates.form.newMandate.mandate' | translate }}
              :
            </p>
            <p class="margin-none-xs">{{ mandate.type }}</p>
          </div>
        </div>

        <div class="col-sm-6 col-xs-12 padding-none-xs padding-left-md-sm" *ngIf="isCollapsed[i]">
          <p>
            <span class="openSans-bold"
              >{{ 'subscription.mandates.form.collectivityType.label' | translate }}
              :
            </span>
            <span>{{ mandate.collectivityType }}</span>
          </p>
          <p>
            <span class="openSans-bold"
              >{{ 'subscription.mandates.form.collectivityName' | translate }}
              :
            </span>
            <span>{{ mandate.collectivityName }}</span>
          </p>
        </div>

        <label
          *ngIf="isAlterable"
          for="alter"
          class="alter padding-sm-xs padding-left-lg-xs padding-right-lg-xs background-blue3-xs dosis-bold text-16-xs text-uppercase text-white-xs cursor-hand"
          (click)="onAlterClick($event, i, 'update')"
        >
          Modifier
          <input type="button" id="alter" class="hidden-xs hidden-sm hidden-md hidden-lg" />
        </label>
      </div>

      <div id="mandate-container" class="infos-container" *ngIf="isCollapsed" [collapse]="isCollapsed[i]">
        <div class="col-xs-12 padding-none-xs">
          <p class="dosis-bold text-blue3-xs text-18-xs margin-bottom-md-xs">
            {{ 'admin.membership.detail.mandates.infos.title' | translate }}
          </p>

          <p>
            <span class="openSans-bold"
              >{{ 'subscription.mandates.form.collectivityType.label' | translate }}
              :
            </span>
            <span>{{ mandate.collectivityType }}</span>
          </p>
          <p>
            <span class="openSans-bold"
              >{{ 'subscription.mandates.form.collectivityName' | translate }}
              :
            </span>
            <span>{{ mandate.collectivityName }}</span>
          </p>
          <p *ngIf="mandate.epciName">
            <span class="openSans-bold"
              >{{ 'subscription.mandates.form.epciName' | translate }}
              :
            </span>
            <span>{{ mandate.epciName }}</span>
          </p>
          <p>
            <span class="openSans-bold"
              >{{ 'subscription.mandates.form.mandateType.label' | translate }}
              :
            </span>
            <span>{{ mandate.type }}</span>
          </p>
          <p>
            <span class="openSans-bold"
              >{{ 'subscription.mandates.form.monthlyContribution' | translate }}
              :
            </span>
            <span>{{ mandate.monthlyContribution }} %</span>
          </p>
          <p>
            <span class="openSans-bold"
              >{{ 'subscription.mandates.form.retroactive.label' | translate }}
              :
            </span>
            <span *ngIf="mandate.isRetroactive === false">Non</span>
            <span *ngIf="mandate.isRetroactive === true && mandate.retroactivityType === 'fromMandate'"
              >A compter de la {{ 'subscription.mandates.form.retroactive.mandateStartDate' | translate }}</span
            >
            <span *ngIf="mandate.isRetroactive === true && mandate.retroactivityType === 'fromEffective'"
              >A compter du {{ mandate.dueDate | date: 'd MMMM yyyy' }}</span
            >
          </p>
          <p>
            <span class="openSans-bold"
              >{{ 'admin.membership.detail.mandates.infos.optinContributionRetroactive' | translate }}
              :
            </span>
            <span>{{
              (mandate.optinContribution && mandate.optinContribution == 'RetroactiveAtReception'
                ? 'subscription.common.form.yes'
                : 'subscription.common.form.no'
              ) | translate
            }}</span>
          </p>

          <p *ngIf="mandate.optinContribution === 'RetroactiveWithStaggering'" class="margin-left-md-xs">
            <span class="openSans-bold"
              >{{
                'admin.membership.detail.mandates.infos.optinContributionRetroactiveWithStaggering.label' | translate
              }}
              :
            </span>
            <span>{{
              mandate.staggeringMonth +
                ' ' +
                ('admin.membership.detail.mandates.infos.optinContributionRetroactiveWithStaggering.month' | translate)
            }}</span>
          </p>
        </div>

        <n9-beneficiary-clause [mandate]="mandate"></n9-beneficiary-clause>
      </div>
    </div>
  </div>

  <ng-template #noMandate>
    <p class="openSans-regular text-italic text-16-xs">
      {{ 'admin.membership.detail.mandates.noMandate' | translate }}
    </p>
  </ng-template>
</div>
