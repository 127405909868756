import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BeneficiariesDivision } from '@core/models/beneficiaries-division.model';
import { MembershipMandate } from '@core/models/membership-mandate.model';

@Component({
  selector: 'n9-beneficiary-clause',
  templateUrl: './beneficiary-clause.component.html'
})
export class BeneficiaryClauseComponent {
  @Input() mandate: MembershipMandate;

  constructor() {}
}
