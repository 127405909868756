import { Component, OnInit } from '@angular/core';
import { Contract, User } from '@core/models';
import { SessionService } from '@core/session/session.service';
import { HomeNavigationService } from '@core/services/navigation.service';

@Component({
  selector: 'n9-contribution-select',
  templateUrl: 'contribution-select.component.html'
})
export class ContributionSelectComponent implements OnInit {
  public user: User;
  public contract: Contract;
  public contractNumber: number;

  constructor(private sessionService: SessionService, private navigation: HomeNavigationService) {}

  ngOnInit(): void {
    this.user = this.sessionService.getUser();
    this.loadContract();
  }

  loadContract(): void {
    const route: string[] = this.navigation.getRoute().split('/');
    this.contractNumber = parseInt(route[route.length - 2], 10);
    this.contract = this.user.contracts.find((contract) => contract.number === this.contractNumber);
  }

  onSubmit(): void {
    this.navigation.navigateToRoute(`/home/contracts/${this.contractNumber}/contribution/form`, null);
  }

  returnToContracts(): void {
    this.navigation.navigate('contracts');
  }
}
