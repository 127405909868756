/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./user-info.component";
import * as i2 from "../../../core/session/session.service";
var styles_UserInfoComponent = [];
var RenderType_UserInfoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserInfoComponent, data: {} });
export { RenderType_UserInfoComponent as RenderType_UserInfoComponent };
export function View_UserInfoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "col-xs-12 padding-xs-xs background-white-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "col-xs-7 padding-none-xs"], ["style", "display: inline-flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["height", "20px"], ["src", "../../../../assets/img/avatar-grey.png"], ["width", "20px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "col-xs-12 text-blue3-xs text-bold text-left text-8-xs"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " ", " "])), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "col-xs-5 padding-none-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "span", [["class", "col-xs-12 padding-none-xs text-blue3-xs text-left text-8-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["N'adherent :"])), (_l()(), i0.ɵted(9, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user.firstname; var currVal_1 = _co.user.lastname; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.user.eluNumber; _ck(_v, 9, 0, currVal_2); }); }
export function View_UserInfoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "n9-user-info", [], null, null, null, View_UserInfoComponent_0, RenderType_UserInfoComponent)), i0.ɵdid(1, 114688, null, 0, i1.UserInfoComponent, [i2.SessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserInfoComponentNgFactory = i0.ɵccf("n9-user-info", i1.UserInfoComponent, View_UserInfoComponent_Host_0, {}, {}, []);
export { UserInfoComponentNgFactory as UserInfoComponentNgFactory };
