import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'n9-carel-dropdown',
  templateUrl: './carel-dropdown.component.html',
  styleUrls: ['./carel-dropdown.component.scss']
})
export class CarelDropdownComponent {
  // Interactibles
  @Input() options: any[];
  @Input() optionFormat: string;
  @Output() selectedOption: EventEmitter<any>;
  selectedOptionDisplay: string;

  // Texts
  @Input() title: string;
  @Input() i18nTitle: string;
  @Input() i18nOptions: string;
  @Input() type: string;
  @Input() cantSet: boolean;

  constructor() {
    this.selectedOption = new EventEmitter<any>();
  }

  private selectOption(value: string): void {
    this.selectedOptionDisplay = value;
    this.selectedOption.emit(value);
  }

  private formatOption(option: any): string {
    const matches = this.optionFormat.match(/(\$\w+)/g);
    const splits = this.optionFormat.split(/(\$\w+)/g).filter((w) => w[0] !== '$');

    if (typeof option === 'object') {
      let ret = '';

      // Replace splitted string with matched keys
      matches.forEach((match, i) => {
        ret += splits[i] + option[match.replace('$', '')];
      });

      if (splits[splits.length - 1]) ret += splits[splits.length - 1];

      return ret;
    }

    return '';
  }
}
