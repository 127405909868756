import { NgModule, ModuleWithProviders } from '@angular/core';
import { ConfigurationService } from './configuration.service';

@NgModule({
  providers: [ConfigurationService]
})
export class ConfigurationModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ConfigurationModule,
      providers: [ConfigurationService]
    };
  }
}
