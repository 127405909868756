/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home-subscription.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../subscription/subscription.component.ngfactory";
import * as i3 from "../../subscription/subscription.component";
import * as i4 from "../../subscription/subscription.service";
import * as i5 from "./home-subscription.component";
var styles_HomeSubscriptionComponent = [i0.styles];
var RenderType_HomeSubscriptionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeSubscriptionComponent, data: {} });
export { RenderType_HomeSubscriptionComponent as RenderType_HomeSubscriptionComponent };
export function View_HomeSubscriptionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-subscription", [], null, null, null, i2.View_SubscriptionComponent_0, i2.RenderType_SubscriptionComponent)), i1.ɵdid(1, 49152, null, 0, i3.SubscriptionComponent, [i4.SubscriptionService], { isMembership: [0, "isMembership"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HomeSubscriptionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-home-subscription", [], null, null, null, View_HomeSubscriptionComponent_0, RenderType_HomeSubscriptionComponent)), i1.ɵdid(1, 49152, null, 0, i5.HomeSubscriptionComponent, [], null, null)], null, null); }
var HomeSubscriptionComponentNgFactory = i1.ɵccf("n9-home-subscription", i5.HomeSubscriptionComponent, View_HomeSubscriptionComponent_Host_0, {}, {}, []);
export { HomeSubscriptionComponentNgFactory as HomeSubscriptionComponentNgFactory };
