/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./maintenance.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./maintenance.component";
var styles_MaintenanceComponent = [i0.styles];
var RenderType_MaintenanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MaintenanceComponent, data: {} });
export { RenderType_MaintenanceComponent as RenderType_MaintenanceComponent };
export function View_MaintenanceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "maintenance"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "maintenance-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Notre espace adh\u00E9rent est non fonctionnel pour le moment, nous vous prions de nous en excuser."])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Pour toute demande, nous vous remercions d'adresser un mail \u00E0 notre \u00E9quipe de gestion \u00E0 l'adresse suivante : "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["href", "mailto:gestion@carelmutuelle.fr"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["gestion@carelmutuelle.fr"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["src", "../../../assets/img/construction.jpeg"]], null, null, null, null, null))], null, null); }
export function View_MaintenanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-maintenance", [], null, null, null, View_MaintenanceComponent_0, RenderType_MaintenanceComponent)), i1.ɵdid(1, 49152, null, 0, i2.MaintenanceComponent, [], null, null)], null, null); }
var MaintenanceComponentNgFactory = i1.ɵccf("n9-maintenance", i2.MaintenanceComponent, View_MaintenanceComponent_Host_0, {}, {}, []);
export { MaintenanceComponentNgFactory as MaintenanceComponentNgFactory };
