import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AdminDiscussionsService } from '../admin/home/discussions/admin-discussions.service';
import { LoginNotificationsComponent } from '@shared/components/login-notifications/login-notifications.component';
import { ProfileResolver } from '@core/resolvers/profile.resolver';
import { ActionService } from '@shared/services/action.service';
import { GeneralAssemblyService } from '@shared/services/general-assembly.service';
import { NotificationService } from '@shared/services/notification.service';
import { RequestService } from '@shared/services/request.service';
import { UserService } from '@shared/services/user.service';
import { SessionService } from '@core/session/session.service';
import { SharedModule } from '@shared/shared.module';
import { NewBeneficiaryComponent } from '../subscription/mandates/new-beneficiary/new-beneficiary.component';
import { SubscriptionModule } from '../subscription/subscription.module';
import { ContactFormComponent } from './contact/contact-form/contact-form.component';
import { ContactComponent } from './contact/contact.component';
import { BeneficiaryFormComponent } from './contracts/beneficiary/beneficiary-form/beneficiary-form.component';
import { BeneficiarySelectComponent } from './contracts/beneficiary/beneficiary-select/beneficiary-select.component';
import { BeneficiaryComponent } from './contracts/beneficiary/beneficiary.component';
import { ContractCardComponent } from './contracts/contract-card/contract-card.component';
import { ContractListComponent } from './contracts/contract-list/contract-list.component';
import { ContractsComponent } from './contracts/contracts.component';
import { ContributionFormComponent } from './contracts/contribution/contribution-form/contribution-form.component';
import { ContributionSelectComponent } from './contracts/contribution/contribution-select/contribution-select.component';
import { ContributionComponent } from './contracts/contribution/contribution.component';
import { DeclareMandateComponent } from './contracts/declare-mandate/declare-mandate.component';
import { MandateCardComponent } from './contracts/mandate-card/mandate-card.component';
import { RetroactivityFormComponent } from './contracts/retroactivity/retroactivity-form/retroactivity-form.component';
import { RetroactivitySelectComponent } from './contracts/retroactivity/retroactivity-select/retroactivity-select.component';
import { RetroactivityComponent } from './contracts/retroactivity/retroactivity.component';
import { ContractService } from '@modules/home/contracts/shared';
import { MandateService } from '@modules/home/contracts/shared';
import { AssemblyCardBisComponent } from './general-assembly/assembly-card-bis/assembly-card-bis.component';
import { AssemblyCardComponent } from './general-assembly/assembly-card/assembly-card.component';
import { GeneralAssemblyComponent } from './general-assembly/general-assembly.component';
import { HomeRoutingModule } from './home-routing.module';
import { HomeSubscriptionComponent } from './home-subscription/home-subscription.component';
import { HomeComponent } from './home.component';
import { PersonalDataFormComponent } from './personal-data/personal-data-form/personal-data-form.component';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { SideBarComponent } from './sidebar/sidebar.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { UserThreadsComponent } from './user-threads/user-threads.component';
import { PasswordSetComponent } from './password-change/password-set.component';
import { PasswordSetService } from './password-change/password-set.service';
import { ContractSignatureComponent } from './contracts/signature/contract-signature.component';
import { HomeNavigationService } from '@core/services/navigation.service';

@NgModule({
  providers: [
    ActionService,
    ContractService,
    HomeNavigationService,
    GeneralAssemblyService,
    MandateService,
    NotificationService,
    SessionService,
    AdminDiscussionsService,
    UserService,
    RequestService,
    ProfileResolver,
    PasswordSetService
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule.forRoot(),
    SubscriptionModule,
    HomeRoutingModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    CollapseModule,
    ButtonsModule
  ],
  declarations: [
    AssemblyCardComponent,
    AssemblyCardBisComponent,
    BeneficiaryComponent,
    BeneficiaryFormComponent,
    BeneficiarySelectComponent,
    ContactComponent,
    ContactFormComponent,
    ContractCardComponent,
    ContractListComponent,
    ContractsComponent,
    ContributionComponent,
    ContributionSelectComponent,
    ContributionFormComponent,
    RetroactivityComponent,
    RetroactivitySelectComponent,
    RetroactivityFormComponent,
    DeclareMandateComponent,
    GeneralAssemblyComponent,
    HomeComponent,
    HomeSubscriptionComponent,
    LoginNotificationsComponent,
    MandateCardComponent,
    PasswordSetComponent,
    PersonalDataComponent,
    PersonalDataFormComponent,
    SideBarComponent,
    UserInfoComponent,
    UserThreadsComponent,
    ContractSignatureComponent
  ],
  exports: [HomeComponent, HomeRoutingModule],
  entryComponents: [NewBeneficiaryComponent]
})
export class HomeModule {}
