import { Component, OnInit } from '@angular/core';
import { Feature } from '@core/models/feature.model';
import { HomeNavigationService, features } from '../../index';
import { SessionService } from '../../session/session.service';

@Component({
  selector: 'n9-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent implements OnInit {
  public displayBurgerMenu: boolean;
  public features: Feature[];

  constructor(public readonly navigation: HomeNavigationService, public readonly sessionService: SessionService) {
    this.displayBurgerMenu = false;
    this.features = features;
  }

  public ngOnInit(): void {}

  public displayOrHideMenu(event: any): void {
    event.stopPropagation();
    this.displayBurgerMenu = !this.displayBurgerMenu;
  }

  public navigateToContracts(): void {
    if (this.sessionService.getSession()) {
      this.navigation.navigate('contracts');
    } else {
      window.location.href = 'http://www.carelmutuelle.fr/';
    }
  }

  public clickOutSideOfBurger(): void {
    if (!this.displayBurgerMenu) return;

    this.displayBurgerMenu = false;
  }
}
