import { Component, OnInit } from '@angular/core';
import { SignatureService } from '../../../subscription/signature/signature.service';
import { SubscriptionService } from '../../../subscription/subscription.service';
import { Observable } from 'rxjs';
import { HomeNavigationService } from '@core/services/navigation.service';
import { User } from '@core/models';
import { SessionService } from '@core/session/session.service';

@Component({
  selector: 'n9-contract-signature',
  templateUrl: 'contract-signature.component.html'
})
export class ContractSignatureComponent implements OnInit {
  public user: User;

  constructor(
    private navigation: HomeNavigationService,
    public signatureService: SignatureService,
    private sessionService: SessionService,
    public subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.user = this.sessionService.getUser();
    const route: string[] = this.navigation.getRoute().split('/');
    const contractNumber: number = parseInt(route[route.length - 3], 10);
    const requestType: string = route[route.length - 1];
    this.signatureService
      .startUserRequestSignature(contractNumber, requestType, this.user.eluNumber)
      .take(1)
      .subscribe(
        () => this.signatureService.checkSignatureComplete(true),
        (error) => Observable.throwError(error)
      );
  }
}
