import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

import { Mandate } from '@core/models';

@Component({
  selector: 'n9-mandate-card',
  templateUrl: 'mandate-card.component.html'
})
export class MandateCardComponent implements OnInit {
  @Input() public mandate: Mandate;
  public startDate: string;
  public endDate: string;

  public ngOnInit(): void {
    this.startDate = moment(this.mandate.electionDate).format('DD/MM/YYYY');
    this.endDate = moment(this.mandate.mandateEndDate).format('DD/MM/YYYY');
  }
}
