import { Component, TemplateRef, ViewChild } from '@angular/core';
import { saveAs } from 'file-saver';

import { Contract, Mandate } from '@core/models';
import { ContractAction, MandateService } from '../shared';
import { HomeNavigationService } from '@core/services/navigation.service';
import { SessionService } from '@core/session/session.service';
import { User } from '@core/models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

enum ContractStatus {
  PENDING,
  TERMINATED,
  LIQUIDATED
}

// TODO : Replace Stub
// region Stubs
const pendingContractActions: ContractAction[] = [
  {
    actionName: 'Mon taux de cotisation',
    action: 'contribution',
    contract: null
  },
  {
    actionName: 'Ma clause bénéficiaire',
    action: 'beneficiary',
    contract: null
  },
  {
    actionName: "Ma date d'effet de rétroactivité",
    action: 'retroactivity',
    contract: null
  },
  /*{
		actionName: 'Mes périodes de cotisation',
		action: 'none',
		contract: null
	},*/
  {
    actionName: 'Ma situation de compte',
    action: 'accountSituation',
    contract: null,
    isDownload: true
  },
  {
    actionName: "Télécharger mon certificat d'adhésion",
    action: 'membershipCertificate',
    contract: null,
    isDownload: true
  }
];

const terminatedContractActions: ContractAction[] = [
  {
    actionName: 'Ma clause bénéficiaire',
    action: 'beneficiary',
    contract: null
  } /*,
	{
		actionName: 'Mes périodes de cotisation',
		action: 'none',
		contract: null
	}*/,
  {
    actionName: 'Ma situation de compte',
    action: 'accountSituation',
    contract: null,
    isDownload: true
  }
];

const liquidatedContractActions: ContractAction[] = [
  /*{
		actionName: 'Mes avis de virement',
		action: 'none',
		contract: null
	},*/
  {
    actionName: 'Télécharger mon attestation fiscale',
    action: 'taxCertificate',
    contract: null,
    isDownload: true
  }
];
// endregion

@Component({
  selector: 'n9-contract-list',
  templateUrl: 'contract-list.component.html',
  styleUrls: ['./contract-list.component.scss']
})
export class ContractListComponent {
  @ViewChild('error') errorTemplateref: TemplateRef<any>;
  public user: User;
  public currentContracts: Contract[];
  public terminatedContracts: Contract[];
  public liquidatedContracts: Contract[];
  public mandates: Mandate[];
  modalRef: BsModalRef;
  public errorMessage: string;

  public currentContractActions: ContractAction[];
  public terminatedContractActions: ContractAction[];
  public liquidatedContractActions: ContractAction[];

  public selectedContractTab: number;
  public selectedListTab: number;

  constructor(
    private mandateService: MandateService,
    private sessionService: SessionService,
    private navigationService: HomeNavigationService,
    private modalService: BsModalService
  ) {
    this.user = this.sessionService.getUser();
    this.selectedContractTab = 1;
    this.selectedListTab = 1;
    this.currentContractActions = pendingContractActions;
    this.terminatedContractActions = terminatedContractActions;
    this.liquidatedContractActions = liquidatedContractActions;

    if (this.user.contracts) {
      this.currentContracts = this.user.contracts.filter((contract) => {
        return contract.status === ContractStatus[ContractStatus.PENDING];
      });
      this.terminatedContracts = this.user.contracts.filter((contract) => {
        return contract.status === ContractStatus[ContractStatus.TERMINATED];
      });
      this.liquidatedContracts = this.user.contracts.filter((contract) => {
        return contract.status === ContractStatus[ContractStatus.LIQUIDATED];
      });
    }

    /*this.mandateService.getUserMandates(this.user.eluNumber).subscribe(
				(res) => this.mandates = res
		);*/
  }

  selectContractTab(tabNumber: number): void {
    this.selectedContractTab = tabNumber;
  }

  selectListTab(tabNumber: number): void {
    this.selectedListTab = tabNumber;
  }

  executeAction(action: ContractAction): void {
    switch (action.action) {
      case 'beneficiary':
        this.modifyClause(action.contract);
        break;
      case 'contribution':
        this.modifyContribution(action.contract);
        break;
      case 'retroactivity':
        this.modifyRetroactivity(action.contract);
        break;
      case 'membershipCertificate':
        this.downloadMembershipCertificate(action.contract);
        break;
      case 'accountSituation':
        this.downloadAccountSituation(action.contract);
        break;
      case 'taxCertificate':
        this.downloadTaxCertificate(action.contract);
        break;
    }
  }

  declareMandate(): void {
    this.navigationService.navigateToRoute('/home/contracts/new/mandate', null);
  }

  modifyContribution(contract: Contract): void {
    this.navigationService.navigateToRoute(`/home/contracts/${contract.number}/contribution`, null);
  }

  modifyClause(contract: Contract): void {
    this.navigationService.navigateToRoute(`/home/contracts/${contract.number}/beneficiary`, null);
  }

  modifyRetroactivity(contract: Contract): void {
    this.navigationService.navigateToRoute(`/home/contracts/${contract.number}/retroactivity`, null);
  }

  downloadMembershipCertificate(contract: Contract): void {
    this.mandateService.downloadMembershipCertificate(contract.number.toString()).subscribe(
      (res) => {
        saveAs(res.body, res.headers.get('x-filename'));
      },
      (error) => {
        if (error.status === 401 || error.status === 403)
          this.openModal("Vous n'êtes plus autorisé à effectuer cette action, veuillez vous reconnecter");
        if (error.status === 404)
          this.openModal(
            "Il n'existe pas d'attestation fiscale pour ce contrat, veuillez contacter votre gestionnaire."
          );
        if (error.status === 500) this.openModal('Une erreur est survenue, veuillez réessayer plus tard');
      }
    );
  }

  downloadAccountSituation(contract: Contract): void {
    // window.open(`/api/contracts/${contract.number}/situation`, '_blank');
    const currentYear: string = (new Date().getFullYear() - 1).toString();
    this.mandateService.downloadAccountSituation(contract.number.toString(), currentYear).subscribe(
      (res) => {
        saveAs(res.body, res.headers.get('x-filename'));
      },
      (error) => {
        if (error.status === 401 || error.status === 403)
          this.openModal("Vous n'êtes plus autorisé à effectuer cette action, veuillez vous reconnecter");

        // year N-2 ceertificate
        if (error.status === 404) {
          const lastYear: string = (new Date().getFullYear() - 2).toString();
          this.mandateService.downloadAccountSituation(contract.number.toString(), lastYear).subscribe(
            (res) => {
              saveAs(res.body);
            },
            (err) => {
              if (err.status === 401 || err.status === 403)
                this.openModal("Vous n'êtes plus autorisé à effectuer cette action, veuillez vous reconnecter");
              if (err.status === 404)
                this.openModal('Votre situation de compte au 31/12/' + lastYear + ' sera disponible prochainement.');
              if (err.status === 500 || err.status === 400)
                this.openModal('Une erreur est survenue, veuillez réessayer plus tard');
            }
          );
        }

        if (error.status === 500 || error.status === 400)
          this.openModal('Une erreur est survenue, veuillez réessayer plus tard');
      }
    );
  }

  downloadTaxCertificate(contract: Contract): void {
    this.mandateService.downloadTaxCertificate(contract.number.toString()).subscribe(
      (res) => {
        saveAs(res.body, res.headers.get('x-filename'));
      },
      (error) => {
        if (error.status === 401 || error.status === 403)
          this.openModal("Vous n'êtes plus autorisé à effectuer cette action, veuillez vous reconnecter");
        if (error.status === 404)
          this.openModal(
            "Il n'existe pas d'attestation fiscale pour ce contrat, veuillez contacter votre gestionnaire."
          );
        if (error.status === 500) this.openModal('Une erreur est survenue, veuillez réessayer plus tard');
      }
    );
  }

  public toBase64(file: File): void {
    const fileReader: FileReader = new FileReader();
    fileReader.readAsDataURL(file);
  }

  public downloadUserMembershipCertificate(): void {
    if (!this.user.membershipDocumentId) return;
    this.mandateService.downloadFilewithDamId(this.user.membershipDocumentId).subscribe(
      (res) => {
        saveAs(res.body, res.headers.get('x-filename'));
      },
      (error) => {
        if (error.status === 401 || error.status === 403)
          this.openModal("Vous n'êtes plus autorisé à effectuer cette action, veuillez vous reconnecter");
        if (error.status === 404)
          this.openModal(
            "Votre bulletin d'adhésion est momentanément indisponible, veuillez réessayer plus tard ou contacter votre gestionnaire"
          );
        if (error.status === 500) this.openModal('Une erreur est survenue, veuillez réessayer plus tard');
      }
    );
  }

  public openModal(errorMessage: string): void {
    this.errorMessage = errorMessage;
    this.modalRef = this.modalService.show(this.errorTemplateref);
  }
}
