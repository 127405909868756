import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROLE } from '@core/models/role.model';
import { RoleGuard } from '@core/session/role.guard';
import { MessageDetailComponent } from '@shared/components/threads/messages/messages-detail/message-detail.component';
import { MessageListComponent } from '@shared/components/threads/messages/messages-list/message-list.component';
import { MessageNewComponent } from '@shared/components/threads/messages/messages-new/message-new.component';
import { MessagesComponent } from '@shared/components/threads/messages/messages.component';
import { ContactComponent } from './contact/contact.component';
import { BeneficiaryFormComponent } from './contracts/beneficiary/beneficiary-form/beneficiary-form.component';
import { BeneficiarySelectComponent } from './contracts/beneficiary/beneficiary-select/beneficiary-select.component';
import { BeneficiaryComponent } from './contracts/beneficiary/beneficiary.component';
import { ContractListComponent } from './contracts/contract-list/contract-list.component';
import { ContractsComponent } from './contracts/contracts.component';
import { ContributionFormComponent } from './contracts/contribution/contribution-form/contribution-form.component';
import { ContributionSelectComponent } from './contracts/contribution/contribution-select/contribution-select.component';
import { ContributionComponent } from './contracts/contribution/contribution.component';
import { DeclareMandateComponent } from './contracts/declare-mandate/declare-mandate.component';
import { RetroactivityFormComponent } from './contracts/retroactivity/retroactivity-form/retroactivity-form.component';
import { RetroactivitySelectComponent } from './contracts/retroactivity/retroactivity-select/retroactivity-select.component';
import { RetroactivityComponent } from './contracts/retroactivity/retroactivity.component';
import { HomeSubscriptionComponent } from './home-subscription/home-subscription.component';
import { HomeComponent } from './home.component';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { PasswordSetComponent } from './password-change/password-set.component';
import { ContractSignatureComponent } from './contracts/signature/contract-signature.component';

const homeRoutes: Routes = [
  {
    path: 'set-password',
    component: PasswordSetComponent,
    canActivate: [RoleGuard],
    data: {
      authorizedRoles: [ROLE.ROLE_USER]
    }
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [RoleGuard],
    data: {
      authorizedRoles: [ROLE.ROLE_USER]
    },
    children: [
      {
        path: 'contact',
        component: ContactComponent
      },
      {
        path: 'contracts',
        component: ContractsComponent,
        children: [
          {
            path: '',
            component: ContractListComponent
          },
          {
            path: ':number/beneficiary',
            component: BeneficiaryComponent,
            children: [
              {
                path: '',
                component: BeneficiarySelectComponent
              },
              {
                path: 'form',
                component: BeneficiaryFormComponent
              }
            ]
          },
          {
            path: ':number/contribution',
            component: ContributionComponent,
            children: [
              {
                path: '',
                component: ContributionSelectComponent
              },
              {
                path: 'form',
                component: ContributionFormComponent
              }
            ]
          },
          {
            path: ':number/retroactivity',
            component: RetroactivityComponent,
            children: [
              {
                path: '',
                component: RetroactivitySelectComponent
              },
              {
                path: 'form',
                component: RetroactivityFormComponent
              }
            ]
          },
          {
            path: ':number/signature/:string',
            component: ContractSignatureComponent
          },
          {
            path: 'new/mandate',
            component: DeclareMandateComponent
          }
        ]
      },
      /*{
				path: 'assemblies',
				component: GeneralAssemblyComponent
			},*/
      {
        path: 'profile',
        component: PersonalDataComponent
      },
      {
        path: 'threads',
        component: MessagesComponent,
        children: [
          {
            path: '',
            component: MessageListComponent
          },
          {
            path: 'new',
            component: MessageNewComponent
          },
          {
            path: ':id',
            component: MessageDetailComponent
          }
        ]
      },
      {
        path: 'subscribe',
        component: HomeSubscriptionComponent
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(homeRoutes)],
  exports: [RouterModule]
})
export class HomeRoutingModule {}
