<div class="container-fluid padding-bottom-md-xs padding-left-xs-xs padding-left-md-sm">
  <p class="margin-top-md-xs margin-bottom-sm-xs dosis-bold text-left text-bold text-18-xs text-24-sm text-blue3-xs">
    {{ 'admin.users.list.title' | translate }}
  </p>

  <table class="col-xs-12 border-grey3-xs-xs">
    <tbody *ngIf="users$ | async">
      <tr class="dosis-bold text-white-xs background-blue3-xs">
        <td>{{ 'admin.users.list.info.eluNumber' | translate }}</td>
        <td>{{ 'admin.users.list.info.lastname' | translate }}</td>
        <td>{{ 'admin.users.list.info.firstnames' | translate }}</td>
        <td>{{ 'admin.users.list.info.email' | translate }}</td>
        <td>
          {{ 'admin.users.list.info.createdDateTime' | translate }}
        </td>
        <td>{{ 'admin.users.list.info.interact' | translate }}</td>
      </tr>

      <tr *ngFor="let user of users$ | async as User" class="background-white-xs openSans-regular">
        <td>{{ user.eluNumber }}</td>
        <td>{{ user.lastname }}</td>
        <td>{{ user.firstname }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user['createdDateTime'] | date: 'dd/MM/yyyy' }}</td>
        <td></td>
      </tr>
    </tbody>
  </table>

  <n9-multi-contact [users$]="this.users$"></n9-multi-contact>
</div>
