<div class="col-xs-12 padding-none-xs margin-top-lg-xs">
  <p class="col-lg-5 col-xs-9 dosis-bold text-18-sm text-16-xs text-uppercase text-blue3-xs padding-left-none-xs">
    {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.new.title' | translate }}{{ infos.index }}
  </p>
  <span id="delete" class="text-red1-xs" (click)="this.selfDelete()">{{
    'subscription.mandates.form.inCaseOfDeath.beneficiary.new.delete' | translate
  }}</span>
</div>

<form novalidate [formGroup]="newBeneficiaryForm">
  <div class="row">
    <div class="col-lg-6 col-xs-12">
      <label class="padding-none-xs text-left openSans-bold text-bold text-black-xs">
        {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.new.companyOrPerson' | translate }}
        <span class="text-red1-xs"> *</span>
        :
      </label>

      <div id="company" class="radio-group">
        <input
          type="radio"
          name="company"
          [id]="'company-no-' + infos.index"
          [value]="false"
          formControlName="company"
          required
        />
        <label class="radio-label text-center" [for]="'company-no-' + infos.index">
          {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.new.person' | translate }}
        </label>

        <input
          type="radio"
          name="company"
          [id]="'company-yes-' + infos.index"
          [value]="true"
          formControlName="company"
          required
        />
        <label class="radio-label text-center" [for]="'company-yes-' + infos.index">
          {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.new.company' | translate }}
        </label>
      </div>
      <p
        *ngIf="newBeneficiaryForm.controls.company.errors && newBeneficiaryForm.controls.company.dirty"
        class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
      >
        {{ 'subscription.common.form.errors.required' | translate }}
      </p>
    </div>
  </div>

  <div class="row" *ngIf="this.displayCivility">
    <div class="col-sm-4 col-xs-12">
      <label class="padding-none-xs text-left openSans-bold text-bold text-black-xs">
        {{ 'subscription.subscriber.form.civility.label' | translate }}
        <span class="text-red1-xs"> *</span>
        :
      </label>
      <div class="radio-group">
        <input
          type="radio"
          name="civility"
          [id]="'miss-no-' + infos.index"
          value="MRS"
          formControlName="civility"
          required
        />
        <label class="radio-label sticky col-xs-6 text-center border-blue3-xs-xs" [for]="'miss-no-' + infos.index">{{
          'subscription.subscriber.form.civility.MRS' | translate
        }}</label>

        <input
          type="radio"
          name="civility"
          [id]="'mister-no-' + infos.index"
          value="MR"
          formControlName="civility"
          required
        />
        <label class="radio-label sticky col-xs-6 text-center border-blue3-xs-xs" [for]="'mister-no-' + infos.index">{{
          'subscription.subscriber.form.civility.MR' | translate
        }}</label>
      </div>
      <p
        *ngIf="newBeneficiaryForm.controls.civility.errors && newBeneficiaryForm.controls.civility.dirty"
        class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
      >
        {{ 'subscription.subscriber.form.errors.required' | translate }}
      </p>
    </div>
  </div>

  <div *ngIf="newBeneficiaryForm.get('company')?.value === false">
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <label for="lastname" class="padding-none-xs text-left openSans-bold text-bold text-black-xs">
          {{ 'subscription.subscriber.form.lastname' | translate }}
          <span class="text-red1-xs"> *</span>
          :
        </label>
        <input
          type="text"
          (input)="formatControlValue('lastname')"
          class="form-control input-lg text-14-xs"
          id="lastname"
          formControlName="lastname"
          required
        />
        <p
          *ngIf="newBeneficiaryForm.controls.lastname.errors && newBeneficiaryForm.controls.lastname.dirty"
          class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
        >
          {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.errors.lettersOnly' | translate }}
        </p>
      </div>

      <div class="col-sm-6 col-xs-12">
        <label for="firstname" class="padding-none-xs text-left openSans-bold text-bold text-black-xs">
          {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.new.firstname' | translate }}
          <span class="text-red1-xs"> *</span>
          :
        </label>
        <input
          type="text"
          (input)="formatControlValue('firstname')"
          class="form-control input-lg text-14-xs"
          id="firstname"
          formControlName="firstname"
          required
        />
        <p
          *ngIf="newBeneficiaryForm.controls.firstname.errors && newBeneficiaryForm.controls.firstname.dirty"
          class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
        >
          {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.errors.lettersOnly' | translate }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3 col-xs-12">
        <label for="birthDate" class="text-left openSans-bold text-bold text-black-xs" style="display: block">
          {{ 'subscription.subscriber.form.birthDate' | translate }}
          <span class="text-red1-xs"> *</span>
          :
        </label>
        <n9-form-datepicker
          [parentForm]="newBeneficiaryForm"
          [controlName]="'birthDate'"
          [minDate]="minDate"
          [maxDate]="maxDate"
        >
        </n9-form-datepicker>
        <p
          *ngIf="newBeneficiaryForm.controls.birthDate.errors && newBeneficiaryForm.controls.birthDate.dirty"
          class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
        >
          {{ 'subscription.common.form.errors.required' | translate }}
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="newBeneficiaryForm.get('company')?.value === true">
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <label for="companyName" class="padding-none-xs text-left openSans-bold text-bold text-black-xs">
          {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.new.companyName' | translate }}
          <span class="text-red1-xs"> *</span>
          :
        </label>
        <input
          type="text"
          (input)="formatControlValue('companyName')"
          class="form-control input-lg text-14-xs"
          id="companyName"
          formControlName="companyName"
          required
        />
        <p
          *ngIf="newBeneficiaryForm.controls.companyName.errors && newBeneficiaryForm.controls.companyName.dirty"
          class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
        >
          {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.errors.lettersOnly' | translate }}
        </p>
      </div>

      <div class="col-sm-6 col-xs-12">
        <label for="siret" class="padding-none-xs text-left openSans-bold text-bold text-black-xs">
          {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.new.siret' | translate }}
          :
        </label>
        <input
          type="text"
          (input)="formatControlValue('siret')"
          class="form-control input-lg text-14-xs"
          id="siret"
          formControlName="siret"
        />
        <p
          *ngIf="newBeneficiaryForm.controls.siret.errors && newBeneficiaryForm.controls.siret.dirty"
          class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
        >
          {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.errors.siret' | translate }}
        </p>
      </div>
    </div>
  </div>

  <div formGroupName="address" class="border-none-xs">
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <label for="address" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
          {{ 'subscription.subscriber.form.address' | translate
          }}{{ newBeneficiaryForm.get('company')?.value === true ? ' du siège' : '' }}
          <span class="text-red1-xs"> *</span>
          :
        </label>
        <input
          type="text"
          (input)="formatControlValue('address.address')"
          class="form-control input-lg text-14-xs"
          id="address"
          formControlName="address"
          required
        />
        <p
          *ngIf="
            newBeneficiaryForm.get('address')['controls'].address.errors &&
            newBeneficiaryForm.get('address')['controls'].address.dirty
          "
          class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
        >
          {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.errors.lettersAndNumbersOnly' | translate }}
        </p>
      </div>

      <div class="col-sm-6 col-xs-12">
        <label for="complement" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
          {{ 'subscription.subscriber.form.complement' | translate }}
        </label>
        <input
          type="text"
          (input)="formatControlValue('address.complement')"
          class="form-control input-lg text-14-xs"
          id="complement"
          formControlName="complement"
        />
        <p
          *ngIf="
            newBeneficiaryForm.get('address')['controls'].complement.errors &&
            newBeneficiaryForm.get('address')['controls'].complement.dirty
          "
          class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
        >
          {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.errors.lettersAndNumbersOnly' | translate }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 col-xs-12">
        <label for="postalCode" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
          {{ 'subscription.subscriber.form.postalCode' | translate }}
          <span class="text-red1-xs"> *</span>
          :
        </label>
        <input
          type="text"
          class="form-control input-lg text-14-xs"
          id="postalCode"
          formControlName="postalCode"
          required
        />
        <p
          *ngIf="
            newBeneficiaryForm.get('address')['controls'].postalCode.errors &&
            newBeneficiaryForm.get('address')['controls'].postalCode.dirty
          "
          class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
        >
          {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.errors.numbersOnly' | translate }}
        </p>
      </div>

      <div class="col-sm-6 col-sm-offset-3 col-xs-12">
        <label for="city" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
          {{ 'subscription.subscriber.form.city' | translate }}
          <span class="text-red1-xs"> *</span>
          :
        </label>
        <input
          type="text"
          (input)="formatControlValue('address.city')"
          class="form-control input-lg text-14-xs col-xs-6"
          id="city"
          formControlName="city"
          required
        />
        <p
          *ngIf="
            newBeneficiaryForm.get('address')['controls'].city.errors &&
            newBeneficiaryForm.get('address')['controls'].city.dirty
          "
          class="text-red1-xs openSans-bold margin-none-xs margin-top-sm-xs"
        >
          {{ 'subscription.mandates.form.inCaseOfDeath.beneficiary.errors.lettersAndNumbersOnly' | translate }}
        </p>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="newBeneficiaryForm.get('company')?.value === true">
    <div class="col-sm-6 col-xs-12">
      <label for="phone" class="padding-none-xs text-left openSans-bold text-black-xs text-bold">
        {{ 'subscription.subscriber.form.phone' | translate }}
        :
      </label>
      <input type="text" class="form-control input-lg text-14-xs col-xs-6" id="phone" formControlName="phone" />
    </div>
  </div>
</form>
