/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./admin-sidebar/admin-sidebar.component.ngfactory";
import * as i3 from "./admin-sidebar/admin-sidebar.component";
import * as i4 from "@angular/router";
import * as i5 from "./admin-home.component";
import * as i6 from "./shared/admin-navigation.service";
import * as i7 from "../../../core/session/session.service";
var styles_AdminHomeComponent = [i0.styles];
var RenderType_AdminHomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminHomeComponent, data: {} });
export { RenderType_AdminHomeComponent as RenderType_AdminHomeComponent };
export function View_AdminHomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["id", "admin-home"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "n9-sidebar", [["class", "background-blue4-xs"]], null, null, null, i2.View_AdminSidebarComponent_0, i2.RenderType_AdminSidebarComponent)), i1.ɵdid(2, 114688, null, 0, i3.AdminSidebarComponent, [i4.Router], { features: [0, "features"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-xs-10 background-grey1-xs padding-md-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.features; _ck(_v, 2, 0, currVal_0); _ck(_v, 5, 0); }, null); }
export function View_AdminHomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-admin-home", [], null, null, null, View_AdminHomeComponent_0, RenderType_AdminHomeComponent)), i1.ɵdid(1, 114688, null, 0, i5.AdminHomeComponent, [i6.AdminNavigationService, i7.SessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminHomeComponentNgFactory = i1.ɵccf("n9-admin-home", i5.AdminHomeComponent, View_AdminHomeComponent_Host_0, {}, {}, []);
export { AdminHomeComponentNgFactory as AdminHomeComponentNgFactory };
