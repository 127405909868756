import environmentBase from './base';
import { PublicConf } from './public-conf.models';

export const environment = Object.assign(environmentBase.public, {
  production: true,
  maintenance: true,
  name: 'valid',
  api: {
    baseHref: 'https://recette.espaceadherent-carelmutuelle.fr/api',
    serverHost: '/api',
    ignoredRoutes: [
      // '^/dist/assets/.*$',
      '^/assets/.*$',
      '^http://.*',
      '^https://.*'
    ]
  }
} as PublicConf);
