/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "ngx-bootstrap/dropdown";
import * as i5 from "ngx-bootstrap/component-loader";
import * as i6 from "@angular/animations";
import * as i7 from "./header.component";
import * as i8 from "../../services/navigation.service";
import * as i9 from "../../session/session.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["role", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigation.selectFeature(_v.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.context.$implicit.label)); _ck(_v, 2, 0, currVal_0); }); }
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["aria-labelledby", "button-basic"], ["class", "dropdown-menu dropdown-menu-right"], ["id", "dropdown-basic"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.features; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "header", [["class", "container-fluid padding-none-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "section", [["class", "col-xs-12 xs-header height-50-xs hidden-sm hidden-md hidden-lg padding-none-xs background-white-xs border-bottom-grey3-xs-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "logo col-xs-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "margin-top-sm-xs cursor-hand"], ["src", "../../../../assets/img/logo.png"], ["style", "height: 80%"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToContracts() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 6, "div", [["class", "col-xs-2 col-xs-offset-6 height-100p-xs padding-none-xs text-center"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], null, null, null, null)), i1.ɵprd(512, null, i4.BsDropdownState, i4.BsDropdownState, []), i1.ɵdid(6, 212992, null, 0, i4.BsDropdownDirective, [i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i5.ComponentLoaderFactory, i4.BsDropdownState, i4.BsDropdownConfig, i6.AnimationBuilder], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "i", [["aria-controls", "dropdown-basic"], ["aria-hidden", "true"], ["class", "fa fa-bars text-blue3-xs text-20-xs burger-button"], ["dropdownToggle", ""], ["id", "button-basic"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.displayOrHideMenu($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 147456, null, 0, i4.BsDropdownToggleDirective, [i1.ChangeDetectorRef, i4.BsDropdownDirective, i1.ElementRef, i1.Renderer2, i4.BsDropdownState], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(10, 16384, null, 0, i4.BsDropdownMenuDirective, [i4.BsDropdownState, i1.ViewContainerRef, i1.TemplateRef], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "section", [["class", "col-xs-12 height-100-sm hidden-xs padding-none-xs background-white-xs border-bottom-grey3-xs-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "logo col-xs-3 padding-left-sm-xs padding-right-sm-xs text-center cursor-hand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToContracts() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["class", "margin-top-md-xs"], ["height", "80%"], ["src", "../../../../assets/img/logo.png"], ["width", "80%"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 6, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).dropup; var currVal_1 = i1.ɵnov(_v, 6).isOpen; var currVal_2 = (i1.ɵnov(_v, 6).isOpen && i1.ɵnov(_v, 6).isBs4); _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = true; var currVal_4 = i1.ɵnov(_v, 8).isDisabled; var currVal_5 = i1.ɵnov(_v, 8).isOpen; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "n9-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i7.HeaderComponent, [i8.HomeNavigationService, i9.SessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("n9-header", i7.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
