import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validators } from '@angular/forms';

@Directive({
  selector: '[n9-firstnames][ngModel],[n9-firstnames][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FirstnamesValidatorDirective),
      multi: true
    }
  ]
})
export class FirstnamesValidatorDirective implements Validators {
  public static validInput(control: FormControl): object {
    const value: string = control.value;
    const regex: RegExp = /^[a-zà-ÿ]+ ?[a-zà-ÿ]*(, ?[a-zà-ÿ]+ ?[a-zà-ÿ]*)*$/i;

    if (!value || value === '') return null;

    return regex.test(value) ? null : { n9Firstnames: { valid: false } };
  }
}
