import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { BaseService } from '@core/services/base.service';
import { User } from '@core/models';

@Injectable()
export class UserService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public updateUser(user: User): Observable<any> {
    return this.http
      .post(`/users`, JSON.stringify(user), this.getOptions())
      .map((res) => res)
      .catch((err) => Observable.throwError(err));
  }

  public getAllUsers(): Observable<any> {
    return this.http
      .get('/users/all', this.getOptions())
      .map((res) => res)
      .catch((err) => Observable.throwError(err));
  }

  public getUsers(page: number, size: number): Observable<any> {
    return this.http
      .get('/users?page=' + page + '&size=' + size)
      .map((res) => res)
      .catch((err) => Observable.throwError(err));
  }

  public getUser(userId: string): Observable<any> {
    return this.http
      .get(`/users/${userId}`, this.getOptions())
      .map((res) => res)
      .catch((err) => Observable.throwError(err));
  }
}
