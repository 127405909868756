import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Action, ActionType } from '@core/models';
import { ApiHttpClient } from '@core/interceptors/api-http-client.service';
import { BaseService } from '@core/services/base.service';

@Injectable()
export class ActionService extends BaseService {
  constructor(private http: ApiHttpClient) {
    super();
  }

  public getAction(actionId: string): Observable<any> {
    return this.http
      .get(`/actions/${actionId}`, this.getOptions())
      .map((res) => res.json())
      .catch((error) => Observable.throwError(error.json() || { status: 500, error: 'Server error' }));
  }

  public getUserActions(userId: string, actionType: ActionType): Observable<any> {
    let route: string;

    if (actionType === null) route = `/actions/user/${userId}`;
    else route = `/actions/user/${userId}?type=${ActionType[actionType]}`;

    return this.http
      .get(route, this.getOptions())
      .map((res) => res.json())
      .catch((error) => Observable.throwError(error.json() || { status: 500, error: 'Server error' }));
  }

  public addAction(action: Action): Observable<any> {
    return this.http
      .post('/actions', JSON.stringify(action), this.getOptions())
      .map((res) => res.json())
      .catch((error) => Observable.throwError(error.json() || { status: 500, error: 'Server error' }));
  }

  public validateAction(actionId: string): Observable<any> {
    return this.http
      .put(`/actions/${actionId}`, '', this.getOptions())
      .map((res) => res.json())
      .catch((error) => Observable.throwError(error.json() || { status: 500, error: 'Server error' }));
  }

  public refuseAction(actionId: string): Observable<any> {
    return this.http
      .delete(`/actions/${actionId}`, this.getOptions())
      .map((res) => res.json())
      .catch((error) => Observable.throwError(error.json() || { status: 500, error: 'Server error' }));
  }
}
