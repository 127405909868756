import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Action, ActionType, User } from '@core/models';
import { HomeNavigationService } from '@core/services/navigation.service';
import { SessionService } from '@core/session/session.service';
import { Constants } from '@core/utils/constants';
import { DateAfterNowValidatorDirective } from '@core/validators/date-after-now-validator.directive';
import { DateBeforeNowValidatorDirective } from '@core/validators/date-before-now-validator.directive';
import { ActionService } from '@shared/services/action.service';
import { MandateService } from '../shared/mandate.service';

const collectivityTypes: string[] = ['Commune', 'Canton', 'Departement', 'Region'];

@Component({
  selector: 'n9-declare-mandate',
  templateUrl: 'declare-mandate.component.html'
})
export class DeclareMandateComponent implements OnInit {
  public user: User;
  public submitError: string;
  public mandateForm: FormGroup;

  public hasDeclared: boolean;
  public collectivityTypes: string[];
  public mandateTypes: string[];

  constructor(
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private actionService: ActionService,
    private navigation: HomeNavigationService,
    private mandateService: MandateService
  ) {
    this.user = this.sessionService.getUser();
    this.collectivityTypes = collectivityTypes;
    this.hasDeclared = false;

    this.mandateForm = this.formBuilder.group({
      collectivityType: [''],
      collectivityName: ['', [Validators.required]],
      mandateType: [''],
      electionDate: ['', [Validators.required, DateBeforeNowValidatorDirective.validDate]],
      subscribeDate: ['', [Validators.required, DateAfterNowValidatorDirective.validDate]],
      salary: ['', [Validators.required]],
      retroActiveMandates: [''],
      startContributionDate: ['', [DateAfterNowValidatorDirective.validDate]]
    });
  }

  public setRetroActiveMandates(value: boolean): void {
    this.mandateForm.controls['retroActiveMandates'].setValue(value);
  }

  public ngOnInit(): void {
    this.mandateService.listMandates().subscribe((res) => {
      this.mandateTypes = res
        .map((m) => m.collectivityName)
        .filter((elem, index, self) => {
          return index === self.indexOf(elem);
        });
      this.mandateForm.controls['mandateType'].setValue(this.mandateTypes[0]);
    });
    this.mandateForm.controls['collectivityType'].setValue(this.collectivityTypes[0]);
    this.setRetroActiveMandates(false);
  }

  public declareANew(): void {
    this.mandateForm.reset();
    this.hasDeclared = false;
  }

  public onSubmit(event: any): void {
    this.submitError = null;

    if (this.mandateForm.valid) {
      const action: Action = {
        id: null,
        contractNumber: null,
        oldContributionRate: null,
        newContributionRate: null,
        userId: this.user.id,
        type: ActionType[ActionType.MANDATE_DECLARE],
        createdDateTime: null,
        newUserData: null,
        mandateDeclaration: this.mandateForm.getRawValue()
      };

      if (action.mandateDeclaration.startContributionDate === '')
        action.mandateDeclaration.startContributionDate = null;

      this.actionService.addAction(action).subscribe(
        (res) => (this.hasDeclared = true),
        (error) => this.handleError(error)
      );
    }
  }

  private handleError(error: any): void {
    if (error.status) {
      if (error.status !== 401 && error.status !== 403) {
        if (error.status === 400) {
          if (error.errors && error.errors.length) this.submitError = Constants.errorsMessages.MANDATE_INVALID;
          else {
            if (error.error && error.error === 'MANDATE_ALREADY_EXISTS')
              this.submitError = Constants.errorsMessages.MANDATE_ALREADY_EXISTS;
            else this.submitError = Constants.errorsMessages.SERVER_ERROR;
          }
        } else this.submitError = Constants.errorsMessages.SERVER_ERROR;
      } else this.navigation.navigateToLogin();
    } else this.submitError = Constants.errorsMessages.SERVER_ERROR;
  }
}
