import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationType } from '@core/models/notification.model';

const baseUrl: string = '/notifications';

@Injectable()
export class NotificationService {
  constructor(private http: HttpClient) {}

  // data: recipients: string[], content: string, type: NotificationType
  public postNotificationForUsers(data: object): Observable<object> {
    return this.http
      .post(baseUrl, data)
      .map((res) => res)
      .catch((err) => Observable.throwError(err));
  }

  public getNotificationsByType(type: NotificationType, page: number = 0, size: number = 10): Observable<object> {
    return this.http
      .get(`${baseUrl}?type=${type}&page=${page}&size=${size}`)
      .map((notifications: object) => notifications)
      .catch((err) => Observable.throwError(err));
  }

  public removeRecipientFromNotification(id: string): Observable<object> {
    return this.http
      .put(`${baseUrl}?id=${id}`, {})
      .map((res) => res)
      .catch((err) => Observable.throwError(err));
  }

  public deleteNotificationById(id: string): Observable<object> {
    return this.http
      .delete(`${baseUrl}?id=${id}`, {})
      .map((res) => res)
      .catch((err) => Observable.throwError(err));
  }

  public getSavedNotifications(): Observable<object> {
    return this.http
      .get(baseUrl + '/templates')
      .map((res) => res)
      .catch((err) => Observable.throwError(err));
  }
}
