import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import swal from 'sweetalert2';
import _assignIn from 'lodash/assignIn';

/**
 *
 * For swal.noop visit : https://github.com/limonte/sweetalert2#handling-dismissals
 *
 */
@Injectable()
export class SwalService {
  static empty: string = 'empty';

  static success(title: string, description: string = '', timer: number = 1000): void {
    swal({
      title,
      text: description,
      type: 'success',
      timer,
      showConfirmButton: false
    }).catch(swal.noop);
  }

  static error(title: string, description: string = ''): void {
    swal(title, description, 'error').catch(swal.noop);
  }

  static info(
    title: string,
    description: string = '',
    allowOutsideClick: boolean = true,
    allowEscapeKey: boolean = true
  ): void {
    swal({
      title,
      text: description,
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick,
      allowEscapeKey
    }).catch(swal.noop);
  }

  static loading(
    title: string,
    description: string = '',
    timer: number = 500,
    allowOutsideClick: boolean = true,
    allowEscapeKey: boolean = true
  ): void {
    swal({
      title,
      text: description,
      timer,
      onOpen: () => {
        swal.showLoading();
      },
      allowOutsideClick,
      allowEscapeKey
    }).catch(swal.noop);
  }

  static confirm(title: string, description: string = '', options: object = {}): Promise<any> {
    return swal(
      _assignIn(
        {
          title,
          text: description,
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes'
        },
        options
      )
    );
  }

  static input(title: string, description: string = '', options: object = {}): Promise<any> {
    return swal(
      _assignIn(
        {
          title,
          text: description,
          input: 'text',
          showCancelButton: true,
          cancelButtonText: 'Annuler',
          confirmButtonText: 'Valider'
        },
        options
      )
    );
  }

  private translateService: TranslateService;

  constructor(private injector: Injector) {
    setTimeout(() => {
      this.translateService = this.injector.get(TranslateService);
    });
  }

  translateSuccess(
    title: string,
    description: string = SwalService.empty,
    titleParams?: object,
    descriptionParams?: object,
    timer: number = 1000
  ): void {
    Observable.forkJoin(
      this.translateService.get(title, titleParams),
      this.translateService.get(description, descriptionParams)
    ).subscribe(([titleTranslated, descriptionTranslated]) => {
      SwalService.success(titleTranslated, descriptionTranslated, timer);
    });
  }

  translateError(title: string, description: string = SwalService.empty): void {
    Observable.forkJoin(this.translateService.get(title), this.translateService.get(description)).subscribe(
      ([titleTranslated, descriptionTranslated]) => {
        SwalService.error(titleTranslated, descriptionTranslated);
      }
    );
  }

  translateInfo(
    title: string,
    description: string = SwalService.empty,
    allowOutsideClick: boolean = true,
    allowEscapeKey: boolean = true
  ): void {
    Observable.forkJoin(this.translateService.get(title), this.translateService.get(description)).subscribe(
      ([titleTranslated, descriptionTranslated]) => {
        SwalService.info(titleTranslated, descriptionTranslated, allowOutsideClick, allowEscapeKey);
      }
    );
  }

  translateLoading(
    title: string,
    description: string = SwalService.empty,
    timer: number = 500,
    allowOutsideClick: boolean = true,
    allowEscapeKey: boolean = true
  ): void {
    Observable.forkJoin(this.translateService.get(title), this.translateService.get(description)).subscribe(
      ([titleTranslated, descriptionTranslated]) => {
        SwalService.loading(titleTranslated, descriptionTranslated, timer, allowOutsideClick, allowEscapeKey);
      }
    );
  }

  translateConfirm(
    title: string,
    description: string = SwalService.empty,
    titleParams?: object,
    descriptionParams?: object,
    options: object = {}
  ): Observable<any> {
    return Observable.forkJoin(
      this.translateService.get(title, titleParams),
      this.translateService.get(description, descriptionParams)
    ).flatMap(([titleTranslated, descriptionTranslated]) => {
      return Observable.fromPromise(SwalService.confirm(titleTranslated, descriptionTranslated, options));
    });
  }

  translateInput(title: string, description: string = SwalService.empty, options: object = {}): Observable<any> {
    return Observable.forkJoin(this.translateService.get(title), this.translateService.get(description)).flatMap(
      ([titleTranslated, descriptionTranslated]) => {
        return Observable.fromPromise(SwalService.input(titleTranslated, descriptionTranslated, options));
      }
    );
  }

  checkbox(
    title: string,
    description: string = '',
    checkBoxText: string = '',
    allowOutsideClick: boolean = true,
    allowEscapeKey: boolean = true
  ): void {
    swal({
      title,
      text: description,
      input: 'checkbox',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick,
      allowEscapeKey,
      inputPlaceholder: checkBoxText,
      inputValidator: (result) => {
        return new Promise<string>((resolve, reject) => {
          if (result) {
            resolve(result);
          } else {
            this.translateService.get('errors.required').subscribe((errorMsg) => {
              reject(errorMsg);
            });
          }
        });
      }
    }).catch(swal.noop);
  }

  translateCheckbox(
    title: string,
    description: string = SwalService.empty,
    checkBoxText: string = SwalService.empty,
    allowOutsideClick: boolean = true,
    allowEscapeKey: boolean = true
  ): void {
    Observable.forkJoin(
      this.translateService.get(title),
      this.translateService.get(description),
      this.translateService.get(checkBoxText)
    ).subscribe(([titleTranslated, descriptionTranslated, checkBoxTextTranslated]) => {
      this.checkbox(titleTranslated, descriptionTranslated, checkBoxTextTranslated, allowOutsideClick, allowEscapeKey);
    });
  }

  select(
    title: string,
    description: string = '',
    selectOptions: { [inputValue: string]: string },
    allowOutsideClick: boolean = true,
    allowEscapeKey: boolean = true
  ): Observable<any> {
    return Observable.fromPromise(
      swal({
        title,
        input: 'select',
        inputOptions: selectOptions,
        inputPlaceholder: description,
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick,
        allowEscapeKey,
        inputValidator: (result) => {
          return new Promise<string>((resolve, reject) => {
            if (result) {
              resolve(result);
            } else {
              this.translateService.get('commons.errors.required').subscribe((errorMsg) => {
                reject(errorMsg);
              });
            }
          });
        }
      })
        .then((result) => {
          return result;
        })
        .catch(swal.noop)
    );
  }

  public translateSelect(
    title: string,
    description: string = SwalService.empty,
    selectOptions: { [inputValue: string]: string } = {},
    allowOutsideClick: boolean = true,
    allowEscapeKey: boolean = true
  ): Observable<any> {
    return Observable.forkJoin(this.translateService.get(title), this.translateService.get(description))
      .map(([titleTranslated, descriptionTranslated]) => {
        return this.select(titleTranslated, descriptionTranslated, selectOptions, allowOutsideClick, allowEscapeKey);
      })
      .flatMap((selectObservable) => {
        return selectObservable;
      });
  }
}
