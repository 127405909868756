<div class="row" *ngIf="dataSent !== ds.SUCCESS && dataSent !== ds.FORBIDDEN">
  <div class="form-group form-group-lg col-xs-5">
    <label for="contribution-select" class="openSans-bold"
      >{{ 'home.contracts.contribution.selected' | translate }}
    </label>

    <div class="col-xs-12 padding-sm-xs background-blue2-xs margin-bottom-lg-xs">
      <div class="media">
        <div class="media-left padding-sm-xs">
          <img src="../../../../../../assets/img/avatar.png" width="40px" />
        </div>
        <div class="media-body padding-left-sm-xs">
          <p class="margin-bottom-xs-xs">
            <span class="openSans-bold text-blue4-xs"
              >{{ 'home.contracts.contribution.mandate' | translate }}
              :
            </span>
            <span class="openSans-semibold text-blue4-xs">
              {{ contract?.mandate }}
            </span>
          </p>
          <p class="margin-bottom-xs-xs">
            <span class="openSans-bold text-blue4-xs"
              >{{ 'home.contracts.contribution.subscribeDate' | translate }}
              :
            </span>
            <span class="openSans-semibold text-blue4-xs">
              {{ contract?.subscriptionDate | date: 'dd/MM/yyyy' }}
            </span>
          </p>
          <p class="margin-none-xs">
            <span class="openSans-bold text-blue4-xs"
              >{{ 'home.contracts.contribution.contributionRate' | translate }}
              :
            </span>
            <span class="openSans-semibold text-blue4-xs">
              {{ contract?.contributionRate }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <form novalidate [formGroup]="retroactivityForm" (ngSubmit)="onSubmit()" class="form">
    <div class="form-group col-xs-12">
      <label for="retroactivityDate" class="openSans-bold"
        >{{ 'home.contracts.retroactivity.select-date' | translate }}
      </label>
      <div class="margin-top-xs-xs">
        <n9-form-datepicker
          id="retroactivityDate"
          [parentForm]="retroactivityForm"
          [controlName]="'retroactivityDate'"
          [minDate]="minDate"
          [maxDate]="maxDate"
        >
        </n9-form-datepicker>
        <div *ngIf="this.retroactivityForm.get('retroactivityDate').errors">
          <div
            *ngIf="
              this.retroactivityForm.get('retroactivityDate').errors.bsDate &&
              this.retroactivityForm.get('retroactivityDate').errors.bsDate.minDate
            "
            class="openSans-bold margin-bottom-sm-xs text-red1-xs text-14-xs"
          >
            La date de rétroactivité ne peut être antérieur à la date du
            {{ minDate | date: 'longDate' }} !
          </div>
          <div
            *ngIf="
              this.retroactivityForm.get('retroactivityDate').errors.bsDate &&
              this.retroactivityForm.get('retroactivityDate').errors.bsDate.maxDate
            "
            class="openSans-bold margin-bottom-sm-xs text-red1-xs text-14-xs"
          >
            La date de rétroactivité ne peut être postérieur à la date du
            {{ maxDate | date: 'longDate' }} !
          </div>
          <div
            *ngIf="
              this.retroactivityForm.get('retroactivityDate').errors.required &&
              this.retroactivityForm.get('retroactivityDate').touched
            "
            class="openSans-bold margin-bottom-sm-xs text-red1-xs text-14-xs"
          >
            Veuillez renseigner la date de rétroactivité !
          </div>
        </div>
      </div>
    </div>

    <div class="padding-lg-xs col-xs-12 text-center">
      <button
        type="submit"
        class="btn btn-default dosis-bold padding-sm-xs text-uppercase text-white-xs background-red1-xs border-radius-none-xs padding-left-md-xs padding-right-md-xs"
        [disabled]="!retroactivityForm.valid || retroactivityForm.pristine || dataSent === ds.PENDING"
      >
        {{ 'home.contracts.retroactivity.validate' | translate }}
      </button>
    </div>

    <div class="col-xs-12">
      <p class="openSans-bold margin-bottom-sm-xs text-center text-12-xs">
        <span *ngIf="dataSent === ds.PENDING" class="text-grey4-xs">{{
          'home.contracts.contribution.form.pending' | translate
        }}</span>
        <span *ngIf="dataSent === ds.ERROR" class="text-red1-xs">{{
          'home.contracts.contribution.form.error' | translate
        }}</span>
      </p>
    </div>
  </form>
</div>

<div class="row" *ngIf="dataSent === ds.SUCCESS">
  <div class="col-md-6 col-md-offset-3 margin-bottom-lg-xs margin-top-lg-xs text-center">
    <p>
      <i class="glyphicon glyphicon-ok text-green2-xs margin-lg-xs" style="font-size: 130px" aria-hidden="true"></i>
    </p>
    <p
      class="text-18-xs openSans-regular padding-bottom-lg-xs padding-left-lg-xs padding-right-lg-xs margin-bottom-lg-xs"
    >
      {{ 'home.contracts.retroactivity.form.success' | translate }}
    </p>
    <a
      [routerLink]="['../../../']"
      class="btn btn-default dosis-bold padding-sm-xs text-uppercase text-white-xs background-blue3-xs border-radius-none-xs padding-left-md-xs padding-right-md-xs"
      >{{ 'home.contracts.contribution.back' | translate }}</a
    >
  </div>
</div>

<div class="row" *ngIf="dataSent === ds.FORBIDDEN">
  <div class="col-md-6 col-md-offset-3 margin-bottom-lg-xs margin-top-lg-xs text-center">
    <p>
      <i class="glyphicon glyphicon-remove text-red2-xs margin-lg-xs" style="font-size: 130px" aria-hidden="true"></i>
    </p>
    <p
      class="text-18-xs openSans-regular padding-bottom-lg-xs padding-left-lg-xs padding-right-lg-xs margin-bottom-lg-xs"
    >
      {{ 'home.contracts.retroactivity.form.errorDuplicate' | translate }}
    </p>
    <a
      [routerLink]="['../../../']"
      class="btn btn-default dosis-bold padding-sm-xs text-uppercase text-white-xs background-blue3-xs border-radius-none-xs padding-left-md-xs padding-right-md-xs"
      >{{ 'home.contracts.contribution.back' | translate }}</a
    >
  </div>
</div>
