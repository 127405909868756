import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface Referential {
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class MandatesService {
  referentials: object[];

  constructor(private http: HttpClient) {}

  public getReferential(type: string): void {
    this.http.get('/referentials/' + type + '?page=0&size=200').subscribe(
      (response) => {
        this.referentials = response['content'];

        switch (type) {
          case 'departements':
            this.referentials.sort(this.codeAscOrder);

            const index = this.referentials.findIndex((d: Referential) => d.code === '2A');

            for (let i = index; i <= index + 1; i++) {
              this.referentials.splice(
                this.referentials.findIndex((d: Referential) => d.code === '21'),
                0,
                this.referentials[i]
              );
              this.referentials.splice(i + 1, 1);
            }

            break;
          case 'regions':
            this.referentials.sort(this.alphabeticAscOrder);
            break;
          case 'epci':
            this.referentials.sort(this.codeAscOrder);
            break;
        }
      },
      (error) => Observable.throwError(error)
    );
  }

  public uploadImageFile(file: File): Observable<any> {
    return this.http
      .post(
        `/membership/document/create/as-byte-array/BASE?name=${file.name}&label=${file.name}&content-type=${file.type}`,
        file
      )
      .map((res) => res)
      .catch((err) => Observable.throwError(err));
  }
  public downloadImageFile(id: string, type: string = 'BASE'): Observable<any> {
    return this.http.get(`/membership/document/download/${id}/type/${type}`, {
      responseType: 'blob',
      observe: 'response'
    });
  }
  public deleteImageFile(membershipId: string, documentId: string): Observable<any> {
    return this.http.delete(`/membership/${membershipId}/document/delete/${documentId}`);
  }

  public codeAscOrder(a: Referential, b: Referential): number {
    return a.code < b.code ? -1 : a.code > b.code ? 1 : 0;
  }

  public alphabeticAscOrder(a: Referential, b: Referential): number {
    return a.name.localeCompare(b.name);
  }
}
