import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ValueProvider } from '@angular/core';
import { Routes } from '@angular/router';
import { ProfileResolver } from '@core/resolvers/profile.resolver';
import { LoggedInGuard } from '@core/session/logged-in.guard';
import { MaintenanceGuard } from '@core/session/maintenance.guard';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
registerLocaleData(localeFr);
export class WindowWrapper extends Window {
}
export function getWindow() {
    return window;
}
const WINDOW_PROVIDER = {
    provide: WindowWrapper,
    useValue: getWindow
};
const routes = [
    {
        path: '',
        loadChildren: '@modules/login/login.module#LoginModule',
        canActivate: [MaintenanceGuard]
    },
    {
        path: 'maintenance',
        loadChildren: '@modules/maintenance/maintenance.module#MaintenanceModule'
    },
    {
        path: 'home',
        loadChildren: '@modules/home/home.module#HomeModule',
        canActivate: [LoggedInGuard, MaintenanceGuard],
        resolve: [ProfileResolver]
    },
    {
        path: 'souscription',
        loadChildren: '@modules/subscription/subscription.module#SubscriptionModule'
    }
];
const ɵ0 = (http) => new TranslateHttpLoader(http, '/assets/i18n/', '.json');
export class AppModule {
}
export { ɵ0 };
