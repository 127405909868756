import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AdminNavigationService } from '@modules/admin/home/shared';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationConfig, PaginationModule } from 'ngx-bootstrap/pagination';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ProfileResolver } from '@core/resolvers/profile.resolver';
import { CreateCarelAccountComponent } from '@shared/components/create-carel-account/create-carel-account.component';
import { DataSummaryComponent } from '@shared/components/data-summary/data-summary.component';
import { MultiSelectComponent } from '@shared/components/form-inputs/multi-select/multi-select.component';
import { MultiContactComponent } from '@shared/components/multi-contact/multi-contact.component';
import { AccountsService } from '@shared/services/accounts.service';
import { GeneralAssemblyService } from '@shared/services/general-assembly.service';
import { HistoryService } from '@shared/services/history.service';
import { NotificationService } from '@shared/services/notification.service';
import { UserService } from '@shared/services/user.service';
import { SharedModule } from '@shared/shared.module';
import { HomeModule } from '../../home/home.module';
import { SubscriptionModule } from '../../subscription/subscription.module';
import { AccountDetailsComponent } from './accounts/account-details/account-details.component';
import { AccountsListComponent } from './accounts/accounts-list/accounts-list.component';
import { AccountsComponent } from './accounts/accounts.component';
import { AdminHomeRoutingModule } from './admin-home-routing.module';
import { AdminHomeComponent } from './admin-home.component';
import { AdminSidebarComponent } from './admin-sidebar/admin-sidebar.component';
import { AssemblyCardComponent } from './assembly/assembly-card/assembly-card.component';
import { AssemblyListComponent } from './assembly/assembly-list.component';
import { AssemblyNewComponent } from './assembly/assembly-new/assembly-new.component';
import { AdminDiscussionsService } from './discussions/admin-discussions.service';
import { ThreadsDetailComponent } from './discussions/threads/threads-detail/threads-detail.component';
import { ThreadsListComponent } from './discussions/threads/threads-list/threads-list.component';
import { ThreadsComponent } from './discussions/threads/threads.component';
import { HistoryComponent } from './history/history.component';
import { MembershipDetailComponent } from './membership/membership-detail/membership-detail.component';
import { MembershipListComponent } from './membership/membership-list/membership-list.component';
import { MembershipComponent } from './membership/membership.component';
import { MembershipService } from './membership/membership.service';
import { ReceptionComponent } from './reception/reception.component';
import { ContractDetailComponent } from './users/user-detail/contract-detail/contract-detail.component';
import { UserActionsComponent } from './users/user-detail/user-actions/user-actions.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { UserInfoComponent } from './users/user-detail/user-info/user-info.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UsersComponent } from './users/users.component';

@NgModule({
  providers: [
    AccountsService,
    AdminDiscussionsService,
    AdminNavigationService,
    GeneralAssemblyService,
    MembershipService,
    NotificationService,
    PaginationConfig,
    ProfileResolver,
    UserService,
    HistoryService
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule.forRoot(),
    AdminHomeRoutingModule,
    PaginationModule.forRoot(),
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot(),
    HomeModule,
    SubscriptionModule
  ],
  declarations: [
    AdminHomeComponent,
    AdminSidebarComponent,
    AssemblyCardComponent,
    AssemblyListComponent,
    AssemblyNewComponent,
    ContractDetailComponent,
    DataSummaryComponent,
    ReceptionComponent,
    UserActionsComponent,
    UserDetailComponent,
    UserInfoComponent,
    UserListComponent,
    UsersComponent,
    MembershipComponent,
    MembershipListComponent,
    MembershipDetailComponent,
    ThreadsComponent,
    ThreadsListComponent,
    ThreadsDetailComponent,
    AccountsComponent,
    AccountDetailsComponent,
    AccountsListComponent,
    CreateCarelAccountComponent,
    MultiContactComponent,
    MultiSelectComponent,
    HistoryComponent
  ],
  exports: [AdminHomeComponent, AdminHomeRoutingModule]
})
export class AdminHomeModule {}
