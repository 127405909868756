import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[n9-alphabetic][ngModel],[n9-alphabetic][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AlphabeticValidatorDirective),
      multi: true
    }
  ]
})
export class AlphabeticValidatorDirective implements Validator {
  public static validInput(control: FormControl): object {
    const value: string = control.value;
    const regex: RegExp = /^[a-z\s\-]*$/i;

    if (!value || value === '') return null;

    return regex.test(value) ? null : { n9Alphabetic: { valid: false } };
  }

  public validate(control: FormControl): object {
    return AlphabeticValidatorDirective.validInput(control);
  }
}
