import { Component } from '@angular/core';
import * as moment from 'moment';

import { Contract, User } from '@core/models';
import { SessionService } from '@core/session/session.service';
import { Feature } from '@core/models/feature.model';
import { GestionnaireInfos } from '@core/models/gestionnaire-infos.model';
import { FeatureUtils } from '@core/utils/feature.utils';
import { HomeNavigationService, features } from '@core/services/navigation.service';

@Component({
  selector: 'n9-home-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss']
})
export class SideBarComponent {
  public user: User;
  public contractHandler: GestionnaireInfos;
  public selectedTab: number;
  public features: Feature[];

  constructor(public readonly navigation: HomeNavigationService, private readonly sessionService: SessionService) {
    this.user = this.sessionService.getUser();
    this.contractHandler = this.user.gestionnaire;
    this.selectedTab = 1;

    this.navigation.feature$.subscribe((feature) => this.setFeatureTab(feature));

    this.features = features;
    this.selectFeatureTabByRoute(this.navigation.getRoute());
  }

  public determineUserMandate(): string {
    if (this.user.contracts) {
      const pendingContracts: Contract[] = this.user.contracts.filter((contract) => contract.status === 'PENDING');

      if (!pendingContracts || pendingContracts.length === 0) return 'Rentier';
      else {
        let minContract: Contract = pendingContracts[0];
        let minDate: Date = moment(minContract.subscriptionDate).toDate();
        let challengerDate: Date;

        for (let index: number = 1; index < pendingContracts.length; index++) {
          challengerDate = moment(pendingContracts[index].subscriptionDate).toDate();

          if (challengerDate < minDate) {
            minContract = pendingContracts[index];
            minDate = challengerDate;
          }
        }

        return minContract.mandate;
      }
    }
  }

  public setFeatureTab(featureName: string): void {
    this.selectedTab = FeatureUtils.getFeatureTabNumber(featureName);
  }

  private selectFeatureTabByRoute(route: string): void {
    let feature: string = '';

    if (route.search(/profile/) >= 0) feature = 'personal-data';
    else if (route.search(/contracts/) >= 0) feature = 'contracts';
    else if (route.search(/contact/) >= 0) feature = 'contact';
    else if (route.search(/messages/) >= 0) feature = 'messages';

    this.setFeatureTab(feature);
  }
}
