/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../threads.component.ngfactory";
import * as i2 from "../../threads.component";
import * as i3 from "@angular/forms";
import * as i4 from "../../message-threads.service";
import * as i5 from "../../../../../core/session/session.service";
import * as i6 from "@angular/common";
import * as i7 from "./message-detail.component";
import * as i8 from "@angular/router";
var styles_MessageDetailComponent = [];
var RenderType_MessageDetailComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MessageDetailComponent, data: {} });
export { RenderType_MessageDetailComponent as RenderType_MessageDetailComponent };
function View_MessageDetailComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "n9-threads", [], null, null, null, i1.View_ThreadsComponent_0, i1.RenderType_ThreadsComponent)), i0.ɵdid(2, 114688, null, 0, i2.ThreadsComponent, [i3.FormBuilder, i4.MessageThreadsService, i5.SessionService], { from: [0, "from"], to: [1, "to"], threadId: [2, "threadId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.from; var currVal_1 = _co.to; var currVal_2 = _co.threadId; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_MessageDetailComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["style", "flex: 1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [["class", "col-xs-12 padding-left-xs-xs padding-left-md-sm margin-top-md-xs margin-bottom-md-xs dosis-bold text-left text-bold text-18-xs text-24-sm text-blue3-xs"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Fil de discussion "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MessageDetailComponent_1)), i0.ɵdid(4, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.to; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_MessageDetailComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "n9-message-detail", [], null, null, null, View_MessageDetailComponent_0, RenderType_MessageDetailComponent)), i0.ɵdid(1, 114688, null, 0, i7.MessageDetailComponent, [i8.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageDetailComponentNgFactory = i0.ɵccf("n9-message-detail", i7.MessageDetailComponent, View_MessageDetailComponent_Host_0, {}, {}, []);
export { MessageDetailComponentNgFactory as MessageDetailComponentNgFactory };
