import { Component } from '@angular/core';

@Component({
  selector: 'n9-maintenance',
  templateUrl: 'maintenance.component.html',
  styleUrls: ['maintenance.component.scss']
})
export class MaintenanceComponent {
  constructor() {}
}
