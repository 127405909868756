/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./messages.component";
var styles_MessagesComponent = [];
var RenderType_MessagesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MessagesComponent, data: {} });
export { RenderType_MessagesComponent as RenderType_MessagesComponent };
export function View_MessagesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-xs-12 padding-md-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(2, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_MessagesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "n9-admin-messages", [], null, null, null, View_MessagesComponent_0, RenderType_MessagesComponent)), i0.ɵdid(1, 49152, null, 0, i2.MessagesComponent, [], null, null)], null, null); }
var MessagesComponentNgFactory = i0.ɵccf("n9-admin-messages", i2.MessagesComponent, View_MessagesComponent_Host_0, {}, {}, []);
export { MessagesComponentNgFactory as MessagesComponentNgFactory };
