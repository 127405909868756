import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ConfigurationService } from '../services/conf/configuration.service';
import { LogService } from '../services/log/log.service';
import { SessionService } from '../session/session.service';

@Injectable()
export class DefaultHeadersApiPreInterceptor implements HttpInterceptor {
  private readonly CONTENT_TYPE_HEADER: string = 'Content-Type';
  private readonly AUTHORIZATION_HEADER: string = 'X-CAREL-JWT';
  private readonly CONTENT_TYPE_APPLICATION_JSON: string = 'application/json';

  constructor(
    private logService: LogService,
    private conf: ConfigurationService,
    private sessionService: SessionService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(this.conf.getAsString('api.baseHref'))) {
      let newHeaders = req.headers.append(this.CONTENT_TYPE_HEADER, this.CONTENT_TYPE_APPLICATION_JSON);

      const currentSession = this.sessionService.getSession();
      if (currentSession) {
        newHeaders = newHeaders.append(this.AUTHORIZATION_HEADER, currentSession.token);
      }

      const duplicate = req.clone({
        headers: newHeaders
      });
      return next.handle(duplicate);
    } else {
      return next.handle(req);
    }
  }
}
