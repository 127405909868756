import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ThreadService } from '@shared/services/thread.service';
import { SessionService } from '@core/session/session.service';
import moment from 'moment';
import { ThreadMessage } from '@shared/components/threads/thread-message.models';
export class ContactFormComponent {
    constructor(formBuilder, threadService, router, sessionService) {
        this.formBuilder = formBuilder;
        this.threadService = threadService;
        this.router = router;
        this.sessionService = sessionService;
        this.objectKeys = Object.keys;
        this.subjects = ['Mon contrat', 'Mes cotisations', "Demande d'ouverture des droits", 'Autres'];
        this.MAX_CHAR = 3000;
        this.uploadedFilesIds = [];
        this.uploadedFilesLabels = [];
        this.uploadedFiles = [];
        this.images = [];
        this.user = this.sessionService.getUser();
        this.contactForm = this.formBuilder.group({
            mandateId: ['', [Validators.required]],
            subject: ['', Validators.required],
            content: ['', [Validators.required]],
            firstFile: ['', []]
        });
        this.isSubmitted = null;
        this.mandates = [];
        this.previousMessageState = '';
    }
    ngOnInit() {
        if (this.user.contracts)
            this.user.contracts.map((m) => this.mandates.push(m));
    }
    onSubmit(event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const subject = this.contactForm.get('subject');
            subject.setValue('[' + subject.value + '] ' + this.contactForm.get('subject').value);
            if (this.contactForm.valid) {
                if (this.uploadedFiles.length > 0)
                    yield this.uploadFiles();
                const thread = this.contactForm.getRawValue();
                const threadMessage = new ThreadMessage({});
                threadMessage.mandateId = thread['mandateId'];
                threadMessage.objectId = thread['subject'];
                threadMessage.discussions = [
                    {
                        content: thread['content'],
                        attachedFileIds: this.uploadedFilesIds,
                        attachedFileNames: this.uploadedFilesLabels
                    }
                ];
                this.threadService.createThread(threadMessage).subscribe(() => {
                    this.isSubmitted = true;
                    this.resetForm();
                }, (error) => this.handleError(error));
            }
        });
    }
    uploadFiles() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const file of this.uploadedFiles) {
                yield this.uploadOneFile(file);
            }
        });
    }
    uploadOneFile(file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                this.threadService.uploadImageFile(file).subscribe((res) => {
                    this.uploadedFilesIds.push(res.id);
                    this.uploadedFilesLabels.push(res.label);
                    resolve(res);
                });
            });
        });
    }
    formatDate(date) {
        return moment(date).format('DD/MM/YYYY');
    }
    getFile(files) {
        const newFile = Array.from(files).map((file, index) => {
            if (file.type.match('image/.+'))
                this.toBase64(file, index);
            return file;
        });
        newFile.forEach((file) => this.uploadedFiles.push(file));
    }
    toBase64(file, index) {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.images[index] = e.target.result;
        };
        fileReader.readAsDataURL(file);
    }
    deleteFile(index) {
        if (this.uploadedFiles && this.uploadedFiles[index]) {
            this.uploadedFiles = this.uploadedFiles.filter((file, i) => index !== i);
            this.images = this.images.filter((image, i) => i !== index);
        }
    }
    resetForm() {
        this.contactForm.reset();
        this.uploadedFilesLabels = [];
        this.uploadedFilesIds = [];
        this.uploadedFiles = [];
        this.images = [];
    }
    handleError(error) {
        this.isSubmitted = false;
        if (error.status) {
            if (error.status === 401 || error.status === 403) {
                this.router.navigate(['/']);
                return;
            }
        }
    }
}
