import { LocalStorageStoreService } from './local-storage-store.service';
import { StoreService } from './store.service';
const storeFactory = () => {
    return new LocalStorageStoreService();
};
const ɵ0 = storeFactory;
// tslint:disable-next-line:variable-name
export const StoreProvider = {
    provide: StoreService,
    useFactory: storeFactory
};
export { ɵ0 };
