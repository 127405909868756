import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Message } from '@core/models';
import { ThreadMessage } from '../components/threads/thread-message.models';

import { BaseService } from '@core/services/base.service';

@Injectable()
export class ThreadService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public createThread(thread: ThreadMessage): Observable<any> {
    return this.http
      .post(`/threads`, thread, this.getOptions())
      .map((res) => res)
      .catch((error) => Observable.throwError(error));
  }

  public createThreadAndAddMessage(message: Message): Observable<any> {
    return this.http
      .post(`/threads`, JSON.stringify(message), this.getOptions())
      .map((res) => res)
      .catch((error) => Observable.throwError(error));
  }

  public addMessage(threadId: string, message: Message): Observable<any> {
    return this.http
      .post(`/threads/${threadId}/message`, JSON.stringify(message), this.getOptions())
      .map((res) => res)
      .catch((error) => Observable.throwError(error));
  }

  public updateMessage(threadId: string, message: Message): Observable<any> {
    return this.http
      .put(`/threads/${threadId}/message`, JSON.stringify(message), this.getOptions())
      .map((res) => res)
      .catch((error) => Observable.throwError(error));
  }

  public getThread(threadId: string): Observable<any> {
    return this.http
      .get(`/threads/${threadId}`, this.getOptions())
      .map((res) => res)
      .catch((error) => Observable.throwError(error));
  }

  public getThreads(): Observable<any> {
    return this.http
      .get(`/threads`, this.getOptions())
      .map((res) => res)
      .catch((error) => Observable.throwError(error));
  }

  public getMessages(threadId: string): Observable<any> {
    return this.http
      .get(`/threads/${threadId}/messages`, this.getOptions())
      .map((res) => res)
      .catch((error) => Observable.throwError(error));
  }

  public getThreadSubjects(): Observable<object[]> {
    return this.http
      .get('/referentials/subjects?page=0&size=200')
      .map((res: object[]) => res['content'])
      .catch((err) => Observable.throwError(err));
  }

  public uploadImageFile(file: File): Observable<any> {
    return this.http.post(
      `/documents/create/as-byte-array/BASE?name=${file.name}&label=${file.name}&content-type=${file.type}`,
      file
    );
  }
}
