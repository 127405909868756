import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ApiHttpClient } from '@core/interceptors/api-http-client.service';
import { BaseService } from '@core/services/base.service';
import { Clause } from '@core/models';

@Injectable()
export class ContractService extends BaseService {
  constructor(private http: ApiHttpClient) {
    super();
  }

  public updateClause(userId: string, contractNumber: number, clause: Clause): Observable<any> {
    return this.http
      .put(`/users/${userId}/contract/${contractNumber}/clause`, JSON.stringify(clause), this.getOptions())
      .map((res) => res.json())
      .catch((error) => Observable.throw(error.json() || { status: 500, error: 'Server error' }));
  }

  public updateContributionRate(userId: string, contractNumber: number, rate: number): Observable<any> {
    return this.http
      .put(`/users/${userId}/contract/${contractNumber}/contribution?rate=${rate}`, this.getOptions())
      .map((res) => res.json())
      .catch((error) => Observable.throw(error.json() || { status: 500, error: 'Server error' }));
  }
}
